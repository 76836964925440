.scrollToTop {
  position: fixed;
  right: 40px;
  bottom: 10vh;
  background: rgba(252, 252, 253, 0.9);
  border: 1px solid #dddfe4;
  box-sizing: border-box;
  backdrop-filter: blur(32px);
  cursor: pointer;
  z-index: 5;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .scrollToTop {
    right: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .scrollToTop {
    right: 20px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .scrollToTop {
    right: 30px;
  }
}

.buttonContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 44px;
  height: 143px;
  margin: 5px 5px 0;
}

.btnImg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  max-width: 30px;
  max-height: 30px;
  margin: 5px auto 0;
}

.buttonContent span {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  line-height: 138%;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  margin-top: 65px;
  margin-right: 1px;

  color: #111517;

  transform: rotate(-90deg);
}

@media (hover: hover) {
  .scrollToTop:hover {
    background: #f4f4f6;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .scrollToTop {
    bottom: 5vh;
  }

  .buttonContent {
    width: 38px;
    height: 119px;
  }

  .buttonContent span {
    font-size: 12.8px;
    margin-top: 53px;
  }
}
