.cases {
    overflow: hidden;
}

.title p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;

    margin: 5% 0;
    color: #111517;
}

.dropdowns {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropdown {
    width: 32%;
    position: relative;
    /* z-index: 3000; */
}

.project {
    display: flex;
    flex-direction: column;
}

.projectContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5% 0;
}

.projectInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .projectInfo {
        margin-right: 30px;
    }
}

.projectInfo a {
    text-decoration: none;
}

.projectTitle {
    padding-top: 4%;
    width: 75%;

    font-family: Manrope;
    font-style: normal;
    font-size: 22.5px;
    line-height: 142%;

    color: #111517;
}

.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.filterWrap {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    margin: 5% 5% 0 0;
}

.filter {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 146%;
    white-space: nowrap;
    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 6px 10px;

    color: #494e5b;
}

.viewButton {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding-bottom: 2%;
    align-items: center;
}

.viewButton p {
    padding-right: 4%;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.projectImage {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

.line {
    background: #dddfe4;
    height: 1px;
    border: none;
    width: 98%;
    margin-right: 2%;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
    .title {
        display: flex;
        justify-content: center;
        padding: 0;
    }

    .title p {
        font-size: 52.2px;
        line-height: 110%;
    }

    .dropdowns {
        flex-direction: column;
        width: 100%;
    }

    .dropdown {
        width: 100%;
        margin-bottom: 3%;
    }

    .projectContainer {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .projectTitle {
        font-size: 20px;
        width: 100%;
        padding: 5% 0;
    }

    .projectImage {
        margin-top: 5%;
    }

    .viewButton {
        display: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 1023px) {
    .title {
        display: flex;
        justify-content: flex-start;
        padding: 0;
    }

    .title p {
        font-size: 66.2px;
        line-height: 110%;
    }

    .dropdowns {
        flex-direction: column;
        width: 100%;
    }

    .dropdown {
        width: 100%;
        margin-bottom: 3%;
    }

    .projectContainer {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .projectTitle {
        font-size: 20px;
        width: 100%;
        padding: 5% 0;
    }

    .projectImage {
        margin: 5% auto 0 auto;
    }

    .viewButton {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1800px) {
    .projectTitle {
        width: 85%;
    }

    .projectImage {
        max-width: 70%;
        margin: 0;
    }
}
