.container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.title {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 43.95px;
  line-height: 118%;
  color: #111517;
}

.leftside {
  padding: 100px 60px 100px 0;
  border-right: 1px solid rgba(17, 21, 23, 0.2);
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.rightsideBottom img {
  pointer-events: none;
}

.buttons {
  gap: 20px;
}
.buttons button,
.buttons div {
  width: 100%;
}

.rightsideTop,
.rightsideBottom {
  padding-left: 60px;
  grid-column-start: 2;
  grid-column-end: 3;
}

.rightsideTop {
  padding-top: 100px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(17, 21, 23, 0.2);
  width: 100%;
}

.rightsideBottom {
  padding-top: 60px;
  padding-bottom: 100px;
}

.text {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #494e5b;
  width: 90%;
}

.wrap {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
}

.fieldCouple {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 48px;
}

.field {
  width: 100%;
}

.error {
  background-image: url(../../images/Careers/Warning.svg);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: bottom 0 right 16px;
  transition: 0.3s;
}

textarea {
  background-color: #f4f4f6;
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  resize: none;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

textarea::placeholder {
  text-align: left;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

input[type='text'] {
  background-color: #f4f4f6;
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.underMessage {
  -webkit-user-select: none;
  user-select: none;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 146%;
  color: #828a9b;
}

.contactTitle {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 22.5px;
  color: #111517;
}

.contactInfos {
  padding-top: 3%;
}

@media (hover: hover) {
  .link:hover {
    transform: scale(1.02);
    transition-duration: 150ms;
  }

  .link:hover {
    transform: scale(1.02);
    transition-duration: 150ms;
  }
}

.repBlock {
  display: flex;
}

.repPhoto {
  width: 128px;
  height: 128px;
  border-radius: 256px;
  margin-right: 32px;
}

.repName,
.repPosition,
.repContact {
  font-family: Manrope;
  padding: 0;
  margin: 0;
}

.repName {
  font-weight: 600;
  font-size: 22.5px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #111517;
}

.repPosition {
  font-weight: 500;
  font-size: 14.4px;
  letter-spacing: 0.02em;
  color: #494e5b;
  padding-bottom: 22px;
}

.repContact {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14.4px;
  letter-spacing: 0.02em;
  color: #494e5b;
}

.repContact:first-of-type {
  margin-bottom: 8px;
}

.repContact a {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #111517;
  margin-left: 8px;
}

.repContact img {
  -webkit-user-select: none;
  user-select: none;
}

.hrGray {
  background: #dddfe4;
  height: 1px;
  border: none;
  width: 100%;
}

.hrGrayMobile {
  display: none;
}

.hrBlack {
  height: 2px;
  left: 650px;
  top: 526px;
  border: none;
  background: #111517;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #111517;
  -webkit-user-select: none;
  user-select: none;
}

input:-moz-placeholder,
input::-moz-placeholder {
  color: #111517;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #111517;
}

.checkBoxes {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
}

.checkBox {
  display: flex;
  align-items: center;
  height: 24px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #494e5b;
}

.checkBox:last-child {
  margin-top: 18px;
}

.errorCheckBox .checkBoxContainer .checkmark {
  border: 2px solid #ff655c;
  /* filter: drop-shadow(0px 0px 12px rgba(255, 101, 92, 0.5)); */
}

.errorCheckBox .checkBoxContainer {
  color: #ff655c;
  /* text-shadow: 0px 0px 12px rgba(255, 101, 92, 0.5); */
}

.vl {
  border-left: 1px solid #dddfe4;
  margin-left: -10px;
}

.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f4f4f6;
  border: 2px solid #111517;
}

/* On mouse-over, add a grey background color */

.checkBoxContainer:hover input ~ .checkmark {
  background-color: #f4f4f6;
}

/* When the checkbox is checked, add a blue background */

.checkBoxContainer input:checked ~ .checkmark {
  background-color: #111517;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkBoxContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #f4f4f6;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.files {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 30px 0;
}

.fileWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c6cad2;
  margin: 0 8px 8px 0;
  align-items: center;
  padding: 8px 8px 8px 12px;
  max-width: 150px;
}

.fileWrap img {
  cursor: pointer;
}

.file {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  margin-right: 8px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .files {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .fileWrap {
    width: 36%;
    max-width: 80%;
    margin: 0 0 8px 0;
    margin-right: 16px;
  }
  .file {
    max-width: 80%;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineStyle {
  height: 1px;
  width: 100%;
  border: none;
  background: #dddfe4;
  margin-bottom: 10%;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
  }

  .leftside {
    border-right: none;
    border-bottom: 1px solid rgba(17, 21, 23, 0.2);
    width: 100%;
    padding: 0;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 20px;
  }

  .rightsideTop,
  .rightsideBottom {
    padding: 0;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .rightsideBottom {
    padding-top: 20px;
  }

  .repBlock {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  .repPhoto {
    width: 100px;
    height: 100px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .lineStyle {
    width: 100%;
    margin-bottom: 10%;
  }
  .title {
    width: 100%;
    line-height: 110%;
    font-size: 48.83px;
    margin-bottom: 0;
  }
  .text {
    width: 100%;
    margin-top: 10%;
  }
  .wrap {
    flex-direction: column;
  }
  .form {
    width: 100%;
  }
  .left {
    width: 100%;
  }
  .fieldCouple {
    flex-direction: column;
    padding-bottom: 0;
  }
  .field {
    padding-bottom: 5%;
  }
  .vl {
    display: none;
  }
  .checkBoxes {
    width: 100%;
    flex-direction: column;
  }
  .checkBox {
    width: 100%;
    margin-bottom: 3%;
    font-size: 12.8px;
  }
  .buttons {
    flex-direction: column;
    gap: 0;
  }
  .buttons button,
  .buttons div {
    width: auto;
  }
  .contactTitle {
    width: 100%;
  }
  .contactInfos {
    width: 100%;
    margin-bottom: 3%;
  }
  .repContact img {
    margin-right: 2%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .container {
    grid-template-columns: 40% 60%;
  }

  .leftside {
    padding: 40px 0;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    border-right: none;
    border-bottom: 1px solid rgba(17, 21, 23, 0.2);
  }

  .rightsideTop {
    padding: 44px 20px 44px 0;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 3;
    border-right: 1px solid rgba(17, 21, 23, 0.2);
    border-bottom: none;
  }

  .rightsideBottom {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 44px 0 44px 44px;
  }

  .repBlock {
    padding-bottom: 20px;
    flex-direction: column;
  }

  .repName {
    margin: 15px 0 10px;
  }

  .lineStyle {
    width: 100%;
    margin-bottom: 10%;
  }
  .title {
    line-height: 110%;
    font-size: 48.83px;
    margin: 0;
  }
  .text {
    width: 100%;
    margin-top: 40px;
  }
  .wrap {
    flex-direction: column;
  }
  .form {
    width: 100%;
  }
  .left {
    width: 100%;
  }
  .fieldCouple {
    flex-direction: row;
    padding-bottom: 0;
  }
  .field {
    padding-bottom: 5%;
  }
  .vl {
    display: none;
  }
  .checkBoxes {
    width: 100%;
    flex-direction: column;
  }
  .checkBox {
    width: 100%;
    margin-bottom: 28px;
    font-size: 12.8px;
  }
  .buttons {
    flex-direction: column;
    gap: 20px;
  }
  .buttons button,
  .buttons div {
    width: auto !important;
  }
  .contactTitle {
    width: 100%;
  }
  .contactInfos {
    width: 100%;
    margin-bottom: 54px;
  }
  .contactInfos span {
    margin-left: 0;
  }
  .repContact {
    padding: 2% 0;
  }
  .repContact img {
    margin-right: 2%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .container {
    padding: 0 5%;
  }

  .leftside {
    padding: 100px 60px 100px 0;
  }

  .rightsideTop,
  .rightsideBottom {
    padding-bottom: 60px;
    padding-left: 60px;
  }

  .rightsideTop {
    padding-top: 100px;
  }

  .rightsideBottom {
    padding-top: 60px;
  }

  .repBlock {
    padding-bottom: 20px;
  }

  .checkBox {
    font-size: 14.4px;
    line-height: 105%;
  }
  .vl {
    display: none;
  }
}
