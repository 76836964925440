.job {
    display: flex;
    flex-direction: column;
}

.infoBlock {
    margin-top: -15%;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 43.95px;
    line-height: 118%;

    color: #000000;
    margin-bottom: 24px;
}

.text {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
    margin-bottom: 68px;
}

.subtitle {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;
    margin-bottom: 22px;

    color: #111517;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 4px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #111517;
}

.column:nth-child(2) .row {
    margin-bottom: 50px;
}

.row:last-child {
    margin-bottom: 50px;
}

.row img {
    margin: 6px 16px 0 0;
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
    .row img {
        margin-top: 4px;
    }
}

.actionWrap {
    top: 15%;
    position: sticky;
    background: white;
    width: 25%;
    border: 1px solid #dddfe4;
    padding: 32px;
    margin-left: auto;
}

.actionBlock {
    display: flex;
    flex-direction: column;
}

.buttonWrap {
    padding: 28px;
    background: #ff655c;
}

.buttonWrap {
    text-decoration: none;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.buttonWrap {
    color: #111517;
    background-image: linear-gradient(45deg, #111517 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 900ms ease-in-out;
}

@media (hover: hover) {
    .buttonWrap:hover {
        background-position: 0;
    }
}

.buttonWrap span {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #fdfdfd;
}

.hrGray {
    margin: 32px 0;
    height: 1px;
    width: 100%;
    border: none;

    background: #dddfe4;
}

.shareRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.shareRow span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    color: #111517;
}

.links .img {
    margin-right: 15px;
    cursor: pointer;
}

@media (hover: hover) {
    .links .img:hover {
        transform: scale(1.3);
        transition-duration: 150ms;
    }
}

.links .img:last-child {
    margin-right: 0;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .infoBlock {
        width: 100%;
        margin-top: 12px;
    }

    .title {
        font-size: 36.06px;
        line-height: 118%;
        margin-bottom: 12px;
    }

    .text {
        font-size: 16px;
        margin-bottom: 32px;
    }

    .subtitle {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .column:nth-child(2) .row {
        margin-bottom: 32px;
    }

    .row:last-child {
        margin-bottom: 32px;
    }

    .row {
        font-size: 16px;
    }

    .actionWrap {
        position: relative;
        width: 100%;
        right: 0;
        top: 0;
        padding: 0;
        margin-bottom: 48px;
    }

    .actionBlock {
        margin: 12px;
    }

    .buttonWrap {
        padding: 16px;
    }

    .button span {
        font-size: 12.8px;
    }

    .hrGray {
        margin: 16px 0;
    }

    .shareRow span {
        font-size: 16px;
    }

    .links img {
        margin-right: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .infoBlock {
        width: 100%;
        margin-top: 24px;
    }

    .title {
        font-size: 39.06px;
        line-height: 118%;
        margin-bottom: 24px;
    }

    .text {
        font-size: 16px;
        margin-bottom: 48px;
    }

    .subtitle {
        margin-bottom: 16px;
    }

    .row {
        font-size: 16px;
    }

    .actionWrap {
        position: relative;
        width: 100%;
        right: 0;
        top: 0;
        padding: 0;
        margin-bottom: 48px;
    }

    .actionBlock {
        margin: 32px;
    }

    .buttonWrap {
        padding: 28px;
    }

    .button span {
        font-size: 12.8px;
    }

    .hrGray {
        margin: 35px 0;
    }

    .shareRow span {
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1350px) {
    .actionWrap {
        right: 5%;
        width: 30%;
    }
}
