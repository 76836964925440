.joinTheTeam {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    width: 60%;
}

.btnCircle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #111517;
}

.btnCircle img {
    transform: rotate(180deg);
}

@media (hover: hover) {
    .btnCircle:hover {
        background: #ff655c;
        transition-duration: 150ms;
    }
}

.title {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 43.95px;
    line-height: 118%;

    color: #000000;
}

.points {
    display: flex;
    flex-direction: row;
}

.point {
    width: 28px;
    height: 28px;
    background: #111517;
    border-radius: 50%;
    margin-right: 8px;
}

.point:last-child {
    background: #ff655c;
    margin-right: 12px;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.field {
    width: 49%;
    margin-bottom: 40px;
}

.error {
    background-image: url(../../../../images/Careers/Warning.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: bottom 0 right 16px;
    transition:0.3s;
}

.form .field:last-child {
    width: 100%;
    margin-bottom: 0;
}

.fieldCouple .field:last-child {
    width: 49%;
}

.fieldCouple {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form input[type='text'] {
    background-color: white;
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
}

.hrBlack {
    height: 2px;
    left: 650px;
    top: 526px;
    border: none;

    background: #111517;
}

.underMessage {
    -webkit-user-select: none;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 146%;

    color: #828a9b;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #111517;
    -webkit-user-select: none;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #111517;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #111517;
}
:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #111517;
}

.files {
    -webkit-user-select: none;

    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin: 30px 0;
}

.fileWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c6cad2;
    margin: 0 8px 8px 0;
    align-items: center;
    padding: 8px 8px 8px 12px;
    max-width: 150px;
}

.fileWrap img {
    cursor: pointer;
}

.file {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    margin-right: 8px;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.buttons {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonBorder {
    -webkit-user-select: none;

    cursor: pointer;
    border: 2px solid #111517;
    width: 49%;
}

@media (hover: hover) {
    .buttonBorder:hover {
        background: rgba(17, 21, 23, 0.04);
        transition: all 100ms;
    }
}

.button {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 30px; */

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    white-space: nowrap;

    color: #111517;
}

.buttonBorder input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .joinTheTeam {
        width: 100%;
        margin-top: 20px;
    }

    .btnCircle {
        display: none;
    }

    .title {
        margin-top: 0;
    }

    .title span {
        font-size: 39.06px;
    }

    .points {
        flex-direction: column;
        margin-right: 9px;
        height: 100%;
    }

    .point {
        width: 16px;
        height: 16px;
        margin: 0;
    }

    .point:first-child {
        margin-bottom: 4px;
    }

    .point:last-child {
        margin: 0;
    }

    .form {
        margin-top: 20px;
    }

    .field {
        width: 100%;
    }

    .fieldCouple {
        flex-direction: column;
    }

    .fieldCouple .field:last-child {
        width: 100%;
        margin-bottom: 40px;
    }

    .buttons {
        flex-direction: column;
    }

    .buttonBorder {
        width: 100%;
    }

    .button {
        padding: 19px 16px;
        font-size: 12.8px;
    }

    .buttonBorder:first-child {
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .joinTheTeam {
        margin-top: 112px;
        width: 100%;
    }

    .btnCircle {
        display: none;
    }

    .title {
        margin-top: 0;
    }

    .title span {
        font-size: 39.06px;
    }

    .form {
        margin-top: 60px;
    }
}
