html {
    font-size: 16px;
}

.caseStudies {
    position: relative;
    width: 100%;
    margin-top: 50px;
}

.topWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;

    color: #111517;
}

.navItem {
    text-decoration: none;
}

.allCases {
    display: flex;
    align-items: center;
}

@media (hover: hover) {
    .allCases:hover .btnText {
        color: #ff655c;
        transition-duration: 150ms;
    }

    .allCases:hover .btnCircle {
        background: #ff655c;
        transition-duration: 150ms;
    }
}

.btnText {
    display: flex;
    align-items: center;
    padding-right: 16px;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.btnCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #111517;
}

.bottomWrapper {
    padding-top: 10%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10%;
    width: 100%;
    justify-content: space-between;
}

.bottomWrapper a {
    text-decoration: none;
}

.circleWrap {
    min-width: 21em;
    max-width: 21em;
    min-height: 21em;
    max-height: 21em;
    border-radius: 50%;
}

.circle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    border: 2px solid #111517;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
}

.circle span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #111517;

    width: 90%;
}

.circleWrap:nth-child(1) {
    background-image: url('./../../../images/Case1.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.circleWrap:nth-child(2) {
    background-image: url('./../../../images/Case2.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.circleWrap:nth-child(3) {
    background-image: url('./../../../images/Case3.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.circleWrap:nth-child(4) {
    background-image: url('./../../../images/Case4.svg');
    background-repeat: no-repeat;
    background-position: center;
}

@media (hover: hover) {
    .circle:hover {
        background: none;
        border: none;
        transition: all 0.3s;
    }

    .circle:hover span {
        display: none;
        transition: all 0.3ms;
    }
    .circle:hover img {
        display: none;
        transition: all 0.3s;
    }
}

/* @media screen and (max-width: 1400px) {

    .circleWrap:nth-child(1):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    .circleWrap:nth-child(2):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    .circleWrap:nth-child(3):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    .circleWrap:nth-child(4):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    

    .circle:hover span {
        display:inline-block;    
    }
    .circle:hover img {
        display:inline-block;    
    }
} */

@media screen and (min-width: 1px) and (max-width: 500px) {
    .bottomWrapper {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }

    .title {
        font-size: 47px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .bottomWrapper {
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .caseStudies {
        margin-top: 30px;
    }

    .topWrapper {
        flex-direction: column;
        width: 92%;
        margin: 0 4%;
    }

    .allCases {
        padding-top: 10%;
    }

    .circleWrap:nth-child(2),
    .circleWrap:nth-child(4) {
        margin-left: auto;
    }

    .circle {
        width: 15em;
        height: 15em;
    }

    .circleWrap {
        min-width: 15em;
        max-width: 15em;
        min-height: 15em;
        max-height: 15em;
    }

    .circle img {
        display: none;
    }

    .circle span {
        font-size: 16px;
        line-height: 156%;
        width: 85%;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    /* .circleWrap {
        width: 17em;
    } */

    .circle {
        width: 17em;
        height: 17em;
    }

    .circleWrap {
        min-width: 17em;
        max-width: 17em;
        min-height: 17em;
        max-height: 17em;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .bottomWrapper {
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 50px;
    }

    .topWrapper {
        width: 100%;
    }

    .circleWrap {
        display: flex;
        justify-content: center;
        width: 50%;
        margin-bottom: 3%;
    }
}

@media screen and (min-width: 830px) and (max-width: 940px) {
    .circleWrap {
        min-width: 23em;
        max-width: 23em;
        min-height: 23em;
        max-height: 23em;
    }
}

@media screen and (min-width: 941px) and (max-width: 1100px) {
    .circleWrap {
        min-width: 25em;
        max-width: 25em;
        min-height: 25em;
        max-height: 25em;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .circleWrap {
        min-width: 27em;
        max-width: 27em;
        min-height: 27em;
        max-height: 27em;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .bottomWrapper {
        width: 100%;
    }

    .topWrapper {
        width: 100%;
    }

    .circleWrap {
        min-width: 17em;
        max-width: 17em;
        min-height: 17em;
        max-height: 17em;
    }

    .circle span {
        font-size: 16px;
        width: 70%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .bottomWrapper {
        width: 100%;
    }

    .topWrapper {
        width: 100%;
    }

    .circleWrap {
        min-width: 18em;
        max-width: 18em;
        min-height: 18em;
        max-height: 18em;
    }

    .circle span {
        font-size: 16px;
        width: 70%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1550px) {
    .title {
        margin-left: 2%;
    }

    .navItem {
        margin-right: 2%;
    }

    .circleWrap {
        min-width: 18em;
        max-width: 18em;
        min-height: 18em;
        max-height: 18em;
    }
}
