.topWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 100px 0 50px;
}

.titleBox {
    width: 40%;
}

.topTitle {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.buttons {
    display: flex;
    flex-direction: row;
    -webkit-touch-callout: none; /* iOS Safari */
}

.button {
    cursor: pointer;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    border-radius: 50%;
    height: 60px;
    width: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media (hover: hover) {
    .button:hover {
        background-color: #f4f4f6;
        transition-property: background-color;
        transition-duration: 150ms;
    }
}

.content {
    display: flex;
    flex-direction: row;
    margin-bottom: 90px;
    transition: all 0.5s;
    /* max-width: 100%; */
    overflow: hidden;
}

.content::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    right: 100%;
    top: 0;
    background: #fff;
}

.commentCouple {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    transition: all 0.5s;
}

.comment {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    margin-right: 70px;
    margin-bottom: 70px;
}

.commentWrap:nth-child(n + 1) {
    border-bottom: 1px solid #dddfe4;
}

.commentWrap:nth-child(n + 2) {
    border-bottom: none;
}

@media screen and (min-width: 767px) and (max-width: 950px) {
    .commentWrap:nth-child(n + 2) {
        margin-top: 30px;
    }
}

.comment span:first-child {
    width: 73%;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 21.5px;

    color: #111517;
}
.comment span:nth-child(2) {
    width: 73%;
    margin-top: 5px;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #111517;
}
.comment span:last-child {
    width: 73%;
    margin-top: 15px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    color: #494e5b;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
    .topWrap {
        margin: 50px 0 25px;
    }

    .titleBox {
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .buttons {
        display: none;
    }

    .content {
        overflow-x: auto;
    }

    .commentCouple {
        min-width: 100%;
        flex-direction: row;
        margin-right: 87%;
        padding: 0;
    }

    .commentWrap {
        min-width: calc(100% / 1.05);
    }

    .commentWrap:nth-child(n + 1) {
        border-bottom: none;
    }

    .comment {
        padding-top: 3%;
    }

    .comment span {
        width: 90%;
        font-size: 18px;
    }

    .comment p {
        width: 95%;
        font-size: 14px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .topWrap {
        margin: 75px 0 50px;
    }

    .titleBox {
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .buttons {
        display: none;
    }

    .content {
        overflow-x: auto;
    }

    .commentCouple {
        min-width: 100%;
        flex-direction: row;
        margin-right: 50%;
        padding: 0;
    }

    .commentWrap {
        min-width: calc(94% / 1.25);
    }

    .commentWrap:nth-child(n + 1) {
        border-bottom: none;
    }

    .comment {
        padding-top: 3%;
        margin: 0;
    }

    .comment span {
        width: 90%;
        font-size: 20px;
    }

    .comment p {
        width: 80%;
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
    .topWrap {
        margin: 75px 0 50px;
    }

    .clientsThoughts {
        width: 100%;
    }

    .topWrap {
        align-items: flex-start;
    }

    .titleBox {
        width: 70%;
    }

    .commentWrap:nth-child(n + 1) {
        border-bottom: none;
    }

    .comment span {
        width: 90%;
        font-size: 20px;
    }

    .comment p {
        width: 80%;
        font-size: 16px;
    }
}

@media screen and (min-width: 921px) and (max-width: 1179px) {
    .topWrap {
        margin: 75px 0 50px;
    }

    .clientsThoughts {
        width: 100%;
    }

    .topWrap {
        align-items: flex-start;
    }

    .titleBox {
        width: 55%;
    }

    .commentWrap:nth-child(n + 1) {
        border-bottom: none;
    }

    .comment span {
        width: 90%;
        font-size: 20px;
    }

    .comment p {
        width: 80%;
        font-size: 16px;
    }
}

@media screen and (min-width: 1180px) and (max-width: 1500px) {
    .topWrap {
        margin: 75px 0 50px;
    }

    .clientsThoughts {
        width: 100%;
    }

    .topWrap {
        align-items: flex-start;
    }

    .titleBox {
        width: 42%;
    }

    .commentWrap:nth-child(n + 1) {
        border-bottom: none;
    }

    .comment span {
        width: 90%;
        font-size: 20px;
    }

    .comment p {
        width: 80%;
        font-size: 16px;
    }
}
