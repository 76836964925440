@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    max-width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.menuOpened {
    height: calc(100vh - 98px);
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .menuOpened {
        height: calc(100vh - 68px);
    }
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.grey-section-promo {
    background-color: #f4f4f6;
    border: 40px solid white;
    border-top: none;
    min-height: calc(100vh - 100px);
}

.grey-section {
    background-color: #f4f4f6;
    border: 40px solid white;
    border-top: none;
}

.grey-section-about-promo {
    background-color: #f4f4f6;
    border: 40px solid white;
    border-top: none;
    min-height: calc(100vh - 100px);
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .grey-section-promo {
        min-height: calc(100vh - 68px);
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .grey-section-promo {
        min-height: calc(100vh - 98px);
    }
}

.grey-section-bottom {
    background-color: #f4f4f6;
    border: 40px solid white;
}

.bg-red {
    background-color: #FF655C;
}

.no-border-top {
    border-top: none !important;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .container {
        max-width: 90%;
    }

    .grey-section-promo {
        border: 8px solid white;
        border-top: none;
    }

    .grey-section-about-promo {
        border: 8px solid white;
        border-top: none;
    }

    .grey-section {
        border: 8px solid white;
        border-top: none;
    }

    .grey-section-bottom {
        border: 8px solid white;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        max-width: 90%;
    }

    .grey-section-promo {
        border: 20px solid white;
        border-top: none;
    }

    .grey-section-about-promo {
        border: 20px solid white;
        border-top: none;
    }

    .grey-section {
        border: 20px solid white;
        border-top: none;
    }

    .grey-section-bottom {
        border: 20px solid white;
    }
}

@media screen and (min-height: 1024px) and (max-height: 1200px) {
    .grey-section-about-promo {
        min-height: calc(80vh - 100px);
        height: calc(80vh - 100px);
    }
}

@media screen and (min-height: 1201px) and (max-height: 1400px) {
    .grey-section-about-promo {
        min-height: calc(70vh - 100px);
        height: calc(70vh - 100px);
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .container {
        max-width: 1000px;
    }

    .grey-section-promo {
        border: 20px solid white;
        border-top: none;
    }

    .grey-section-about-promo {
        border: 20px solid white;
        border-top: none;
    }

    .grey-section {
        border: 20px solid white;
        border-top: none;
    }

    .grey-section-bottom {
        border: 20px solid white;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .container {
        max-width: 1100px;
    }

    .grey-section-promo {
        border: 30px solid white;
        border-top: none;
    }

    .grey-section-about-promo {
        border: 30px solid white;
        border-top: none;
    }

    .grey-section {
        border: 30px solid white;
        border-top: none;
    }

    .grey-section-bottom {
        border: 30px solid white;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
    .container {
        max-width: 1200px;
    }

    .grey-section-promo {
        border: 40px solid white;
        border-top: none;
    }

    .grey-section-about-promo {
        border: 40px solid white;
        border-top: none;
    }

    .grey-section {
        border: 40px solid white;
        border-top: none;
    }

    .grey-section-bottom {
        border: 40px solid white;
    }
}

.clutch-widget {
    position: relative;
    top: 50px;
    left: 50px;
}

.goodfirm-widget {
    position: relative;
    left: 50px;
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
    .clutch-widget {
        left: 20px;
    }

    .goodfirm-widget {
        left: 15px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
    .clutch-widget {
        left: 40px;
    }

    .goodfirm-widget {
        left: 40px;
    }
}

img {
    -webkit-user-select: none;
}

.Home_homeWrapper__1wso6 {
    overflow: hidden;
}

.Home_container__1ukFy {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    /* border-left: 1px solid black;
  border-right: 1px solid black;    */
}

.Home_greySection__1mJMW {
    background-color: #f4f4f6;
}

.Home_bottomContainer__2k44g {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

@media screen and (max-width: 1201px) {
    .Home_promoWrapper__VrGzr {
        padding: 0 2% 2%;
    }
}

.Promo_promo__F7cV9 {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 100px);
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Promo_promo__F7cV9 {
        min-height: calc(100vh - 68px);
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .Promo_promo__F7cV9 {
        min-height: calc(100vh - 98px);
    }
}

.Promo_contentWrap__F4d7e {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* margin-top: 5%; */
    margin-left: 20%;
}

.Promo_points__Txdyj {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 10%;
}

.Promo_point__1c9_3 {
    width: 20px;
    height: 20px;
    background: #111517;
    border-radius: 50%;
    margin-right: 12px;
}

.Promo_points__Txdyj div:nth-child(3) .Promo_point__1c9_3 {
    background: #ff655c;
}

.Promo_text__1QHeA span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    letter-spacing: -0.02em;
    position: relative;
    line-height: 100%;
    z-index: 6;

    color: #111517;
}

.Promo_text__1QHeA p {
    width: 40%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    position: relative;
    z-index: 6;

    color: #111517;
}

.Promo_text2__2N-5G {
    margin-left: 5%;
}

.Promo_promoImg1__6TM-d {
    position: absolute;
    bottom: -45%;
    left: -15%;
    overflow: hidden;
    -webkit-animation-duration: 1.3s;
            animation-duration: 1.3s;
    -webkit-animation-name: Promo_picture1__3WTa1;
            animation-name: Promo_picture1__3WTa1;
    z-index: 5;
}

.Promo_promoImg2__VgbFz {
    position: absolute;
    left: 35%;
    bottom: 0%;
    -webkit-animation-duration: 1.3s;
            animation-duration: 1.3s;
    -webkit-animation-name: Promo_picture2__38RJ_;
            animation-name: Promo_picture2__38RJ_;
    z-index: 5;
}

.Promo_promoImg3__3VW4R {
    right: 0%;
    position: absolute;
    bottom: 0%;
    -webkit-animation-duration: 1.3s;
            animation-duration: 1.3s;
    -webkit-animation-name: Promo_picture3__50NwZ;
            animation-name: Promo_picture3__50NwZ;
    z-index: 5;
}

@media (hover: hover) {
    .Promo_promoImg1__6TM-d:hover {
        background-image: url(/static/media/SmartphoneLeftHover.08f0da3b.svg);
    }
    .Promo_promoImg2__VgbFz:hover {
        background-image: url(/static/media/SmartphoneCenterHover.8517ebad.svg);
    }
    .Promo_promoImg3__3VW4R:hover {
        background-image: url(/static/media/LaptopRightHover.475f67a1.svg);
    }
}

@media screen and (min-width: 1731px) {
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -60%;
            left: -25%;
        }

        to {
            bottom: -45%;
            left: -15%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -60%;
            left: -25%;
        }

        to {
            bottom: -45%;
            left: -15%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            left: 35%;
            bottom: 0%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            left: 35%;
            bottom: 0%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -9%;
        }

        to {
            bottom: 0%;
            right: 0%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -9%;
        }

        to {
            bottom: 0%;
            right: 0%;
        }
    }
}

@media screen and (min-width: 1px) and (max-width: 374px) {
    .Promo_contentWrap__F4d7e {
        margin: 0 4%;
    }

    .Promo_text__1QHeA {
        margin-top: 5%;
    }

    .Promo_text__1QHeA span {
        line-height: 100%;
        font-size: 48px;
    }

    .Promo_text__1QHeA p {
        width: 100%;
        font-size: 24.96px;
    }

    .Promo_text2__2N-5G {
        margin-left: 0%;
    }
}

@media screen and (min-width: 1px) and (max-width: 454px) {
    .Promo_promoImg1__6TM-d {
        max-width: 190px;
        bottom: -12%;
    }
    .Promo_promoImg2__VgbFz {
        left: 55%;
        max-width: 300px;
    }
    .Promo_promoImg3__3VW4R {
        display: none;
    }
}

@media screen and (min-width: 455px) and (max-width: 530px) {
    .Promo_promoImg1__6TM-d {
        max-width: 222px;
        bottom: -12%;
    }
    .Promo_promoImg2__VgbFz {
        left: 50%;
        max-width: 390px;
    }
    .Promo_promoImg3__3VW4R {
        display: none;
    }
}

@media screen and (min-width: 531px) and (max-width: 640px) {
    .Promo_promoImg1__6TM-d {
        max-width: 250px;
        bottom: -12%;
        left: -5%;
    }
    .Promo_promoImg2__VgbFz {
        left: 58%;
    }
    .Promo_promoImg3__3VW4R {
        display: none;
    }
}

@media screen and (min-width: 641px) and (max-width: 767px) {
    .Promo_promoImg1__6TM-d {
        bottom: -12%;
        left: 0;
    }
    .Promo_promoImg2__VgbFz {
        left: 62%;
    }
    .Promo_promoImg3__3VW4R {
        display: none;
    }
}

/* @media (max-width: 815px) and (orientation: landscape ) {
    .promoImg1 {
        display: none;
    }
    .promoImg2 {
        display: none;
    }
    .promoImg3 {
        display: none;
    }
} */

@media (max-height: 750px) {
    .Promo_promoImg1__6TM-d {
        display: none;
    }
    .Promo_promoImg2__VgbFz {
        display: none;
    }
    .Promo_promoImg3__3VW4R {
        display: none;
    }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
    .Promo_contentWrap__F4d7e {
        margin: 0 4%;
    }

    .Promo_text__1QHeA {
        margin-top: 5%;
    }

    .Promo_text__1QHeA span {
        line-height: 100%;
        font-size: 52.2px;
    }

    .Promo_text__1QHeA p {
        width: 100%;
        font-size: 24.96px;
    }

    .Promo_text2__2N-5G {
        margin-left: 0%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .Promo_text__1QHeA {
        width: 80%;
    }

    .Promo_text2__2N-5G {
        margin-left: 10%;
    }

    .Promo_text__1QHeA span {
        font-size: 66.2px;
        line-height: 105%;
    }

    .Promo_text__1QHeA p {
        font-size: 16px;
        width: 100%;
    }

    .Promo_promoImg1__6TM-d {
        bottom: -7%;
        left: -2%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: -9%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: -4%;
        right: -5%;
    }

    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -20%;
            left: -20%;
        }

        to {
            bottom: -7%;
            left: -2%;
        }
    }

    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -20%;
            left: -20%;
        }

        to {
            bottom: -7%;
            left: -2%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            left: 35%;
            bottom: -9%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            left: 35%;
            bottom: -9%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -9%;
        }

        to {
            bottom: -4%;
            right: -5%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -9%;
        }

        to {
            bottom: -4%;
            right: -5%;
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .Promo_promoImg1__6TM-d {
        bottom: 0%;
        left: 0%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: -9%;
        left: 35%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: -4%;
        right: 0%;
    }
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -20%;
            left: -35%;
        }

        to {
            bottom: 0%;
            left: 0%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -20%;
            left: -35%;
        }

        to {
            bottom: 0%;
            left: 0%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -30%;
            left: 45%;
        }

        to {
            bottom: -9%;
            left: 35%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -30%;
            left: 45%;
        }

        to {
            bottom: -9%;
            left: 35%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -20%;
        }

        to {
            bottom: -4%;
            right: 0%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -20%;
        }

        to {
            bottom: -4%;
            right: 0%;
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) and (min-height: 1100px) {
    .Promo_promoImg1__6TM-d {
        bottom: -20%;
        left: -30%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: 0%;
        left: 30%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: 15%;
        right: -18%;
    }
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -40%;
            left: -55%;
        }

        to {
            bottom: -20%;
            left: -30%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -40%;
            left: -55%;
        }

        to {
            bottom: -20%;
            left: -30%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 50%;
        }

        to {
            bottom: 0%;
            left: 30%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 50%;
        }

        to {
            bottom: 0%;
            left: 30%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -5%;
            right: -38%;
        }

        to {
            bottom: 15%;
            right: -18%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -5%;
            right: -38%;
        }

        to {
            bottom: 15%;
            right: -18%;
        }
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .Promo_text2__2N-5G p {
        width: 65%;
    }

    .Promo_promoImg1__6TM-d {
        bottom: -55%;
        left: -40%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: 0%;
        left: 28%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: -4%;
        right: -27%;
    }
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -55%;
            left: -40%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -55%;
            left: -40%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: 0%;
            left: 28%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: 0%;
            left: 28%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -50%;
        }

        to {
            bottom: -4%;
            right: -23%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -50%;
        }

        to {
            bottom: -4%;
            right: -23%;
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1380px) {
    .Promo_text2__2N-5G p {
        width: 60%;
    }

    .Promo_promoImg1__6TM-d {
        bottom: -55%;
        left: -35%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: 0%;
        left: 28%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: -5%;
        right: -19%;
    }
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -55%;
            left: -35%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -55%;
            left: -35%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: 0%;
            left: 28%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: 0%;
            left: 28%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -50%;
        }

        to {
            bottom: -5%;
            right: -19%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -20%;
            right: -50%;
        }

        to {
            bottom: -5%;
            right: -19%;
        }
    }
}

@media screen and (min-width: 1381px) and (max-width: 1500px) {
    .Promo_text2__2N-5G p {
        width: 55%;
    }

    .Promo_promoImg1__6TM-d {
        bottom: -40%;
        left: -30%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: -3%;
        left: 32%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: -8%;
        right: -16%;
    }
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -40%;
            left: -30%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -40%;
            left: -30%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: -3%;
            left: 32%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: -3%;
            left: 32%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -28%;
            right: -20%;
        }

        to {
            bottom: -8%;
            right: -16%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -28%;
            right: -20%;
        }

        to {
            bottom: -8%;
            right: -16%;
        }
    }
}

@media screen and (min-width: 1501px) and (max-width: 1730px) {
    .Promo_text2__2N-5G p {
        width: 55%;
    }

    .Promo_promoImg1__6TM-d {
        bottom: -40%;
        left: -20%;
    }
    .Promo_promoImg2__VgbFz {
        bottom: -3%;
        left: 35%;
    }
    .Promo_promoImg3__3VW4R {
        bottom: 0%;
        right: -5%;
    }
    @-webkit-keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -40%;
        }

        to {
            bottom: -40%;
            left: -20%;
        }
    }
    @keyframes Promo_picture1__3WTa1 {
        from {
            bottom: -70%;
            left: -40%;
        }

        to {
            bottom: -40%;
            left: -20%;
        }
    }

    @-webkit-keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: -3%;
            left: 35%;
        }
    }

    @keyframes Promo_picture2__38RJ_ {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: -3%;
            left: 35%;
        }
    }

    @-webkit-keyframes Promo_picture3__50NwZ {
        from {
            bottom: -28%;
            right: -20%;
        }

        to {
            bottom: 0%;
            right: -5%;
        }
    }

    @keyframes Promo_picture3__50NwZ {
        from {
            bottom: -28%;
            right: -20%;
        }

        to {
            bottom: 0%;
            right: -5%;
        }
    }
}

.FromConcept_fromConcept__1b4qS {
    position: relative;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 110px;
    padding-bottom: 10%;
}

.FromConcept_textWrapper__19fWo {
    display: -webkit-flex;
    display: flex;
}

.FromConcept_titleText__ZaSHI {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    display: -webkit-flex;
    display: flex;
    width: 66%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.FromConcept_paragraphs__3oudq {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 58%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: Manrope;
    font-style: normal;
    font-weight: 400;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.FromConcept_infographics__2iLOC {
    padding-top: 13%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.FromConcept_textWrap___udUT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 25%;
}

.FromConcept_block1__2fRYQ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
}
.FromConcept_block2__BGfzw {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
}
.FromConcept_block3__2Uf8f {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
}

.FromConcept_largeFont__1ayiF {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 10px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 300;
    font-size: 43.95px;

    color: #111517;
}

.FromConcept_smallFont__2CFT_ {
    padding-left: 44px;
    padding-top: 10px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    letter-spacing: 0.02em;

    color: #494e5b;
}

.FromConcept_circle__K21t5 {
    border-radius: 50%;
    margin-right: 16px;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;
    border: none;
}

.FromConcept_textBlock__2KSLR {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.FromConcept_vl__ASgc- {
    border-left: 1px solid #dddfe4;
    padding-right: 95px;
}

@media screen and (min-width: 1px) and (max-width: 400px) {
    .FromConcept_titleText__ZaSHI {
        font-size: 40px;
    }

    .FromConcept_paragraphs__3oudq {
        font-size: 16px;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .FromConcept_fromConcept__1b4qS {
        margin-top: 30px;
    }

    .FromConcept_textWrapper__19fWo {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .FromConcept_title__2EGpf {
        word-spacing: 9999999px;
    }

    .FromConcept_titleText__ZaSHI {
        width: 100%;
        text-align: center;
        line-height: 110%;
    }

    .FromConcept_paragraphs__3oudq {
        width: 100%;
        padding-top: 8%;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .FromConcept_paragraph__3aD0q:nth-child(2) {
        padding-top: 4%;
    }

    .FromConcept_infographics__2iLOC {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .FromConcept_vl__ASgc- {
        display: none;
    }

    .FromConcept_textWrap___udUT {
        margin-top: 2%;
        width: 100%;
        border-bottom: 1px solid #dddfe4;
    }

    .FromConcept_textWrap___udUT:last-child {
        border: none;
    }

    .FromConcept_block2__BGfzw {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding-right: 6%;
    }

    .FromConcept_textBlock__2KSLR {
        margin-bottom: 3%;
    }

    .FromConcept_largeFont__1ayiF {
        white-space: nowrap;
        font-size: 39.06px;
        line-height: 118%;
        font-weight: 300;
    }

    .FromConcept_smallFont__2CFT_ {
        width: 50%;
        font-size: 12.8px;
        color: #111517;
        padding-top: 0%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1201px) {
    .FromConcept_fromConcept__1b4qS {
        margin-top: 72px;
    }

    .FromConcept_textWrapper__19fWo {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .FromConcept_title__2EGpf {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        width: 90%;
    }

    .FromConcept_paragraphs__3oudq {
        padding-top: 8%;
        padding-left: 29%;
        width: 64%;
    }

    .FromConcept_paragraph__3aD0q:nth-child(2) {
        padding-top: 4%;
    }

    .FromConcept_vl__ASgc- {
        display: none;
    }

    .FromConcept_infographics__2iLOC {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .FromConcept_textWrap___udUT {
        width: 31%;
    }

    .FromConcept_block__17T7w {
        -webkit-justify-content: center;
                justify-content: center;
    }

    .FromConcept_textBlock__2KSLR {
        padding: 0;
    }

    .FromConcept_largeFont__1ayiF {
        font-size: 39.06px;
    }

    .FromConcept_smallFont__2CFT_ {
        color: #111517;
    }
}

@media screen and (min-width: 1202px) and (max-width: 1500px) {
    .FromConcept_titleText__ZaSHI {
        padding-left: 10%;
    }

    .FromConcept_paragraphs__3oudq {
        padding-right: 7%;
    }

    .FromConcept_paragraph__3aD0q:nth-child(2) {
        padding-top: 4%;
    }
}

@media screen and (min-width: 1501px) {
    .FromConcept_paragraph__3aD0q:nth-child(2) {
        padding-top: 4%;
    }
}

html {
    font-size: 16px;
}

.CaseStudies_caseStudies__1m57Z {
    position: relative;
    width: 100%;
    margin-top: 50px;
}

.CaseStudies_topWrapper__2AFo9 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.CaseStudies_title__2EBxY {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;

    color: #111517;
}

.CaseStudies_navItem__GJVVo {
    text-decoration: none;
}

.CaseStudies_allCases__3fFbN {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

@media (hover: hover) {
    .CaseStudies_allCases__3fFbN:hover .CaseStudies_btnText__rPgHq {
        color: #ff655c;
        transition-duration: 150ms;
    }

    .CaseStudies_allCases__3fFbN:hover .CaseStudies_btnCircle__2_w2T {
        background: #ff655c;
        transition-duration: 150ms;
    }
}

.CaseStudies_btnText__rPgHq {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 16px;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.CaseStudies_btnCircle__2_w2T {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #111517;
}

.CaseStudies_bottomWrapper__3D1oM {
    padding-top: 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-bottom: 10%;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.CaseStudies_bottomWrapper__3D1oM a {
    text-decoration: none;
}

.CaseStudies_circleWrap__38S6L {
    min-width: 21em;
    max-width: 21em;
    min-height: 21em;
    max-height: 21em;
    border-radius: 50%;
}

.CaseStudies_circle__15bYu {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 10%;
    border: 2px solid #111517;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
}

.CaseStudies_circle__15bYu span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #111517;

    width: 90%;
}

.CaseStudies_circleWrap__38S6L:nth-child(1) {
    background-image: url(/static/media/Case1.9572a991.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.CaseStudies_circleWrap__38S6L:nth-child(2) {
    background-image: url(/static/media/Case2.188e79e4.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.CaseStudies_circleWrap__38S6L:nth-child(3) {
    background-image: url(/static/media/Case3.f8dcfacf.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.CaseStudies_circleWrap__38S6L:nth-child(4) {
    background-image: url(/static/media/Case4.73f538a4.svg);
    background-repeat: no-repeat;
    background-position: center;
}

@media (hover: hover) {
    .CaseStudies_circle__15bYu:hover {
        background: none;
        border: none;
        transition: all 0.3s;
    }

    .CaseStudies_circle__15bYu:hover span {
        display: none;
        transition: all 0.3ms;
    }
    .CaseStudies_circle__15bYu:hover img {
        display: none;
        transition: all 0.3s;
    }
}

/* @media screen and (max-width: 1400px) {

    .circleWrap:nth-child(1):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    .circleWrap:nth-child(2):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    .circleWrap:nth-child(3):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    .circleWrap:nth-child(4):hover .circle {
        background-image: none;
        border: 2px solid #111517;
    }
    

    .circle:hover span {
        display:inline-block;    
    }
    .circle:hover img {
        display:inline-block;    
    }
} */

@media screen and (min-width: 1px) and (max-width: 500px) {
    .CaseStudies_bottomWrapper__3D1oM {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }

    .CaseStudies_title__2EBxY {
        font-size: 47px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .CaseStudies_bottomWrapper__3D1oM {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .CaseStudies_caseStudies__1m57Z {
        margin-top: 30px;
    }

    .CaseStudies_topWrapper__2AFo9 {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 92%;
        margin: 0 4%;
    }

    .CaseStudies_allCases__3fFbN {
        padding-top: 10%;
    }

    .CaseStudies_circleWrap__38S6L:nth-child(2),
    .CaseStudies_circleWrap__38S6L:nth-child(4) {
        margin-left: auto;
    }

    .CaseStudies_circle__15bYu {
        width: 15em;
        height: 15em;
    }

    .CaseStudies_circleWrap__38S6L {
        min-width: 15em;
        max-width: 15em;
        min-height: 15em;
        max-height: 15em;
    }

    .CaseStudies_circle__15bYu img {
        display: none;
    }

    .CaseStudies_circle__15bYu span {
        font-size: 16px;
        line-height: 156%;
        width: 85%;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    /* .circleWrap {
        width: 17em;
    } */

    .CaseStudies_circle__15bYu {
        width: 17em;
        height: 17em;
    }

    .CaseStudies_circleWrap__38S6L {
        min-width: 17em;
        max-width: 17em;
        min-height: 17em;
        max-height: 17em;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .CaseStudies_bottomWrapper__3D1oM {
        width: 100%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        padding-bottom: 50px;
    }

    .CaseStudies_topWrapper__2AFo9 {
        width: 100%;
    }

    .CaseStudies_circleWrap__38S6L {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        width: 50%;
        margin-bottom: 3%;
    }
}

@media screen and (min-width: 830px) and (max-width: 940px) {
    .CaseStudies_circleWrap__38S6L {
        min-width: 23em;
        max-width: 23em;
        min-height: 23em;
        max-height: 23em;
    }
}

@media screen and (min-width: 941px) and (max-width: 1100px) {
    .CaseStudies_circleWrap__38S6L {
        min-width: 25em;
        max-width: 25em;
        min-height: 25em;
        max-height: 25em;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .CaseStudies_circleWrap__38S6L {
        min-width: 27em;
        max-width: 27em;
        min-height: 27em;
        max-height: 27em;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .CaseStudies_bottomWrapper__3D1oM {
        width: 100%;
    }

    .CaseStudies_topWrapper__2AFo9 {
        width: 100%;
    }

    .CaseStudies_circleWrap__38S6L {
        min-width: 17em;
        max-width: 17em;
        min-height: 17em;
        max-height: 17em;
    }

    .CaseStudies_circle__15bYu span {
        font-size: 16px;
        width: 70%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .CaseStudies_bottomWrapper__3D1oM {
        width: 100%;
    }

    .CaseStudies_topWrapper__2AFo9 {
        width: 100%;
    }

    .CaseStudies_circleWrap__38S6L {
        min-width: 18em;
        max-width: 18em;
        min-height: 18em;
        max-height: 18em;
    }

    .CaseStudies_circle__15bYu span {
        font-size: 16px;
        width: 70%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1550px) {
    .CaseStudies_title__2EBxY {
        margin-left: 2%;
    }

    .CaseStudies_navItem__GJVVo {
        margin-right: 2%;
    }

    .CaseStudies_circleWrap__38S6L {
        min-width: 18em;
        max-width: 18em;
        min-height: 18em;
        max-height: 18em;
    }
}

.OurApproach_ourApproach__25MpP {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 10%;
    margin-top: 50px;
}

.OurApproach_title__zcME6 {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
    padding-bottom: 80px;
}

.OurApproach_infoBlocks__2Emc_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.OurApproach_infoBlockCouple__1uS7z {
    margin: 1% 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.OurApproach_block__1NCMV {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 87%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 7%;
}

.OurApproach_infoBlock1__3uRwP {
    background: #ff655c;
    width: 49%;
}
.OurApproach_infoBlock2__2oFHv {
    background: #f4f4f6;
    width: 49%;
}
.OurApproach_infoBlock3__2czAM {
    background: #f4f4f6;
    width: 49%;
}
.OurApproach_infoBlock4__1ppxs {
    background: #6d7588;
    width: 49%;
}
.OurApproach_infoBlock5__2TeWW {
    background: #ffeceb;
    width: 49%;
}
.OurApproach_infoBlock6__1zXSM {
    background: #f4f4f6;
    width: 49%;
}

.OurApproach_imgBlock__1Vnio {
    width: 100px;
    height: 100px;
    margin-bottom: 4%;
}

.OurApproach_titleBlockBlack__YW69- {
    margin-bottom: 24px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}
.OurApproach_titleBlockWhite__1AfPz {
    margin-bottom: 24px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #fcfcfd;
}

.OurApproach_textBlockGray__2BmPM {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}
.OurApproach_textBlockWhite__1JAQr {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #fcfcfd;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .OurApproach_ourApproach__25MpP {
        margin-top: 30px;
    }

    .OurApproach_title__zcME6 {
        text-align: center;
        font-size: 48px;
    }

    .OurApproach_infoBlockCouple__1uS7z {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        margin-bottom: 3%;
    }

    .OurApproach_infoBlock1__3uRwP {
        background: #ff655c;
        width: 100%;
        margin-bottom: 3%;
    }
    .OurApproach_infoBlock2__2oFHv {
        background: #f4f4f6;
        width: 100%;
    }
    .OurApproach_infoBlock3__2czAM {
        background: #f4f4f6;
        width: 100%;
        margin-bottom: 3%;
    }
    .OurApproach_infoBlock4__1ppxs {
        background: #6d7588;
        width: 100%;
    }
    .OurApproach_infoBlock5__2TeWW {
        background: #ffeceb;
        width: 100%;
        margin-bottom: 3%;
    }
    .OurApproach_infoBlock6__1zXSM {
        background: #f4f4f6;
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .OurApproach_titleBlockWhite__1AfPz {
        font-size: 20px;
    }
    .OurApproach_titleBlockBlack__YW69- {
        font-size: 20px;
    }

    .OurApproach_textBlockWhite__1JAQr {
        font-size: 16px;
    }
    .OurApproach_textBlockGray__2BmPM {
        font-size: 16px;
    }
}

.Reviews_container__3ebLs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Reviews_topWrapper__2P9FQ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Reviews_title__3zmRK {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.Reviews_buttons__158RC {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.Reviews_button__1AAW2 {
    cursor: pointer;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    border-radius: 50%;
    height: 60px;
    width: 60px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
@media (hover: hover) {
    .Reviews_button__1AAW2:hover {
        background-color: #f4f4f6;
        transition-property: background-color;
        transition-duration: 150ms;
    }
}

.Reviews_bottomWrapper__3-J3v {
    margin-top: 7%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-bottom: 10%;
    transition: all 0.5s;
    max-width: 100%;
    position: relative;
}
.Reviews_bottomWrapper__3-J3v::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    right: 100%;
    top: 0;
    background: #fff;
}

.Reviews_comment__1zdml {
    transition: all 0.5s;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: calc(100% / 3);
    box-sizing: border-box;
    padding-right: 7%;
}

.Reviews_commentName__2zUAY {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.02em;

    color: #828a9b;
}

.Reviews_commentLocation__2RGYU {
    margin-top: 5px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    letter-spacing: 0.02em;

    color: #828a9b;
}

.Reviews_commentText__1SrMz {
    margin-top: 15px;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 434px) {
    .Reviews_topWrapper__2P9FQ {
        width: 80%;
        margin: 0 10%;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Reviews_title__3zmRK {
        text-align: center;
    }

    .Reviews_buttons__158RC {
        display: none;
    }

    .Reviews_bottomWrapper__3-J3v {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .Reviews_comment__1zdml {
        min-width: calc(100% / 1.1);
    }
}

@media screen and (min-width: 435px) and (max-width: 550px) {
    .Reviews_topWrapper__2P9FQ {
        width: 70%;
        margin: 0 15%;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Reviews_title__3zmRK {
        text-align: center;
    }

    .Reviews_buttons__158RC {
        display: none;
    }

    .Reviews_bottomWrapper__3-J3v {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .Reviews_comment__1zdml {
        min-width: calc(100% / 1.6);
    }
}

@media screen and (min-width: 551px) and (max-width: 700px) {
    .Reviews_topWrapper__2P9FQ {
        width: 70%;
        margin: 0 15%;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Reviews_title__3zmRK {
        text-align: center;
    }

    .Reviews_buttons__158RC {
        display: none;
    }

    .Reviews_bottomWrapper__3-J3v {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .Reviews_comment__1zdml {
        min-width: calc(100% / 2.1);
    }
}

@media screen and (min-width: 701px) and (max-width: 767px) {
    .Reviews_topWrapper__2P9FQ {
        width: 70%;
        margin: 0 15%;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Reviews_title__3zmRK {
        text-align: center;
    }

    .Reviews_buttons__158RC {
        display: none;
    }

    .Reviews_bottomWrapper__3-J3v {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .Reviews_comment__1zdml {
        min-width: calc(100% / 2.4);
    }
}

.Widgets_wrapper__1Vel1 {
    padding-bottom: 3%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Widgets_widgetWrap__2B-fG {
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    width: 340px;
    height: 150px;
    max-width: 340px;
    max-height: 150px;
    border: 1px solid #dddfe4;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px;
}

.Widgets_widget__1f3O- {
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
}
@media (hover: hover) {
    .Widgets_widgetWrap__2B-fG:hover {
        border-bottom: 1px solid #ff655c;
    }
}

@media screen and (min-width: 1px) and (max-width: 700px) {
    .Widgets_wrapper__1Vel1 {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .Widgets_widgetWrap__2B-fG {
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Widgets_wrapper__1Vel1 {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
    .Widgets_wrapper__1Vel1 {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .Widgets_widgetWrap__2B-fG {
        width: 300px;
    }

    .Widgets_widgetWrap__2B-fG:nth-child(1),
    .Widgets_widgetWrap__2B-fG:nth-child(3) {
        margin-right: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1100px) {
    .Widgets_wrapper__1Vel1 {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: 70%;
        margin: 0 auto;
        -webkit-align-items: center;
                align-items: center;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
    .Widgets_widgetWrap__2B-fG {
        max-width: 230px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
    .Widgets_widgetWrap__2B-fG {
        max-width: 280px;
    }
}

.Competences_competences__1y2VP {
    overflow: hidden;
}

.WhatWeDo_textContainer__Qnv-a {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 10%;
}

.WhatWeDo_title__TirAE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.WhatWeDo_textContainer__Qnv-a p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    line-height: 0%;
    letter-spacing: -0.02em;

    color: #111517;
}

.WhatWeDo_points__2iTQ_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.WhatWeDo_point__1xzLR {
    width: 20px;
    height: 20px;
    background: #111517;
    border-radius: 50%;
    margin-right: 12px;
}

.WhatWeDo_point__1xzLR:nth-child(3) {
    background: #ff655c;
}

.WhatWeDo_textContainer__Qnv-a span {
    width: 47%;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}

.WhatWeDo_cards__1Wb7T {
    margin-top: 7%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 240px;
}

.WhatWeDo_cards__1Wb7T a {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    width: 24%;
    height: 150px;
}

@media (hover: hover) {
    .WhatWeDo_card__27Atu:hover {
        background: rgba(17, 21, 23, 0.04);
        transition: all 100ms;
    }
}

.WhatWeDo_card__27Atu {
    position: relative;
    width: 100%;
    border: 1px solid #c6cad2;
    box-sizing: border-box;
}

.WhatWeDo_cardText__1XwA0 {
    position: absolute;
    bottom: 10px;
    left: 10px;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;
    color: #111517;
}

.WhatWeDo_img__2sinp {
    position: absolute;
    top: 10px;
    left: 10px;
}

.WhatWeDo_arrowImg__2C7Qs {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    /* .whatWeDo {
        width: 98%;
        margin: auto;
    } */

    .WhatWeDo_textContainer__Qnv-a {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        -webkit-align-items: baseline;
                align-items: baseline;
    }

    .WhatWeDo_textContainer__Qnv-a p {
        font-size: 45.2px;
        white-space: normal;
    }

    .WhatWeDo_textContainer__Qnv-a span {
        font-size: 16px;
        width: 100%;
        -webkit-align-items: unset;
                align-items: unset;
    }

    .WhatWeDo_cards__1Wb7T {
        margin-top: 20%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        margin-bottom: 40px;
    }

    .WhatWeDo_cards__1Wb7T a {
        width: 48%;
        height: 100px;
    }

    .WhatWeDo_cards__1Wb7T a:nth-child(1),
    .WhatWeDo_cards__1Wb7T a:nth-child(2) {
        margin-bottom: 3%;
    }

    .WhatWeDo_cardText__1XwA0 {
        position: absolute;
        bottom: 5px;
        left: 5px;
    }

    .WhatWeDo_img__2sinp {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .WhatWeDo_arrowImg__2C7Qs {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .WhatWeDo_textContainer__Qnv-a {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        -webkit-align-items: baseline;
                align-items: baseline;
    }

    .WhatWeDo_textContainer__Qnv-a p {
        font-size: 45.2px;
        white-space: normal;
    }

    .WhatWeDo_textContainer__Qnv-a span {
        font-size: 16px;
        width: 100%;
        -webkit-align-items: unset;
                align-items: unset;
    }

    .WhatWeDo_cards__1Wb7T {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        margin-bottom: 135px;
    }

    .WhatWeDo_cardWrap__3All3 {
        width: 48%;
        height: 150px;
    }

    .WhatWeDo_cardWrap__3All3:nth-child(1) {
        margin-bottom: 3%;
    }
    .WhatWeDo_cardWrap__3All3:nth-child(2) {
        margin-bottom: 3%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .WhatWeDo_cards__1Wb7T {
        margin-bottom: 135px;
    }

    .WhatWeDo_textContainer__Qnv-a p {
        font-size: 70px;
    }
    .WhatWeDo_textContainer__Qnv-a span {
        font-size: 16px;
    }
}

@media screen and (min-height: 1024px) and (max-height: 1400px) {
    .WhatWeDo_textContainer__Qnv-a span {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .WhatWeDo_cards__1Wb7T a {
        width: 49%;
        margin-bottom: 20px;
    }
}

.Expertise_expertise__1LtY8 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 60px;
    padding-bottom: 70px;
    width: 100%;
}

.Expertise_title__3zpk- {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.Expertise_content__sp2TD {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Expertise_column__28M_B {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 32%;
    padding-bottom: 5%;
    white-space: nowrap;
}

.Expertise_columnTitleWrap__1oSg9 {
    width: 100%;
    height: 50px;
    border-bottom: 2px solid #111517;
}

.Expertise_columnTitle__2nW7Y {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Expertise_columnTitle__2nW7Y span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;
    line-height: 142%;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;

    color: #111517;
}

.Expertise_columnTitle__2nW7Y p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    margin-right: 3%;

    color: #111517;
}

.Expertise_textWrap__26Mfy {
    height: 70px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #dddfe4;
}

.Expertise_textWrap__26Mfy span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.01em;

    color: #5b6271;
}

.Expertise_textWrap__26Mfy:nth-last-child(1) {
    border: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Expertise_expertise__1LtY8 {
        padding-top: 30px;
        padding-bottom: 20px;
        width: 100%;
    }

    .Expertise_title__3zpk- {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        font-size: 48.83px;
        line-height: 0%;
    }

    .Expertise_content__sp2TD {
        overflow-x: auto;
    }

    .Expertise_column__28M_B {
        width: calc(100vw / 1.2);
        margin-right: 5%;
    }

    .Expertise_content__sp2TD::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }

    .Expertise_content__sp2TD::-webkit-scrollbar-track {
        background: white; /* color of the tracking area */
    }

    .Expertise_content__sp2TD::-webkit-scrollbar-thumb {
        background-color: #111517; /* color of the scroll thumb */
        height: 5px;
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 6px solid white; /* creates padding around scroll thumb */
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Expertise_expertise__1LtY8 {
        padding-top: 48px;
        padding-bottom: 52px;
        width: 100%;
    }

    .Expertise_title__3zpk- {
        display: block;
        font-size: 48.83px;
        line-height: 0%;
    }

    .Expertise_content__sp2TD {
        overflow-x: auto;
    }

    .Expertise_column__28M_B {
        width: calc(100vw / 2.2);
        margin-right: 3%;
    }

    .Expertise_content__sp2TD::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }

    .Expertise_content__sp2TD::-webkit-scrollbar-track {
        background: white; /* color of the tracking area */
    }

    .Expertise_content__sp2TD::-webkit-scrollbar-thumb {
        background-color: #111517; /* color of the scroll thumb */
        height: 5px;
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 6px solid white; /* creates padding around scroll thumb */
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .Expertise_expertise__1LtY8 {
        padding-top: 40px;
    }
}

.Services_services__1DXh1 {
    padding: 100px 0;
}

.Services_title__XK7AY {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;

    padding-bottom: 80px;
}

.Services_cards__24LvR {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Services_card__3liwg {
    padding-bottom: 16px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Services_services__1DXh1 {
        padding: 40px 0;
    }

    .Services_card__3liwg {
        width: 100%;
    }

    .Services_title__XK7AY {
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Services_services__1DXh1 {
        padding: 60px 0;
    }

    .Services_title__XK7AY {
        width: 100%;
        text-align: center;
        padding-bottom: 40px;
    }

    .Services_cards__24LvR {
        width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Services_card__3liwg {
        width: 100%;
        padding-bottom: 2%;
    }
}

@media screen and (min-width: 680px) and (max-width: 1400px) {
    .Services_title__XK7AY {
        text-align: start;
    }

    .Services_cards__24LvR {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    /* .cards .card {
        width: 70%;
    } */

    .Services_cards__24LvR .Services_card__3liwg:nth-child(2),
    .Services_cards__24LvR .Services_card__3liwg:nth-child(4),
    .Services_cards__24LvR .Services_card__3liwg:nth-child(6) {
        margin-left: auto;
    }
}

/* @media screen and (min-width: 1025px) and (max-width: 1400px) {
    .services {
        padding: 1px 0;
    }
    
    .title {
        width: 100%;
        text-align: center;
        padding-bottom: 1px;
    }

    .cards {
        width: 100%;
        flex-direction: row;
    }

    .card {
        padding-bottom: 2%;
    }
} */

.Card_card__ZYJp6 {
    border: 1px solid #c6cad2;
    /* box-sizing: border-box; */
    width: calc(100vw / 4.1);
    height: 380px;
}

.Card_cardContent__2dZ0W {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    overflow: hidden;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.Card_content1__1cmzv,
.Card_content2__13mQb {
    min-width: 100%;
    transition: all 0.3s;
}

.Card_content1__1cmzv {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Card_active__McLf3 .Card_content1__1cmzv {
    margin-left: -100%;
}

.Card_cardImage__18ug4 {
    display: -webkit-flex;
    display: flex;
    margin: 24px 0 0 24px;
    -webkit-flex: 1 1;
            flex: 1 1;
    width: 156px;
    height: 156px;
}

.Card_bottomContent__3ttfn {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Card_title__176EI {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    margin: 0 0 40px 40px;

    color: #111517;
}

.Card_buttonWrap__2Bb_S {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    background-color: #f4f4f6;
    right: 24px;
    bottom: 24px;
}

@media (hover: hover) {
    .Card_button__2UWew:hover {
        background-color: rgba(17, 21, 23, 0.04);
    }

    .Card_button__2UWew:hover img {
        -webkit-transform: scale(1.15);
                transform: scale(1.15);
        transition-duration: 150ms;
    }
}

.Card_button__2UWew {
    width: 72px;
    height: 72px;
    border: 1px solid #c6cad2;
    border-radius: 50%;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Card_button__2UWew img {
    width: 24px;
    height: 24px;
}

.Card_button__2UWew.Card_active__McLf3 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.Card_content2__13mQb .Card_title__176EI {
    margin: 24px 0 0 24px;
    margin-bottom: 36px;
}

.Card_activeContent__27CQ9 {
    position: relative;
    z-index: 1;
    width: 70%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 40px;
}

.Card_contentRow__3QHkD {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 16px;
}

.Card_contentRow__3QHkD:last-child {
    margin-bottom: 0;
}

.Card_contentRow__3QHkD img {
    margin-right: 16px;
}

.Card_contentRow__3QHkD span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 15.4px;
    line-height: 156%;
    letter-spacing: 0.01em;

    color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
    .Card_card__ZYJp6 {
        height: 280px;
    }

    .Card_card__ZYJp6.Card_active__McLf3 {
        height: 280px;
    }

    .Card_card__ZYJp6.Card_active__McLf3:nth-child(1) {
        height: 370px;
    }

    .Card_card__ZYJp6.Card_active__McLf3:nth-child(2),
    .Card_card__ZYJp6.Card_active__McLf3:nth-child(3),
    .Card_card__ZYJp6.Card_active__McLf3:nth-child(4) {
        height: 330px;
    }

    .Card_cardImage__18ug4 {
        margin: 14px 0 0 14px;
        width: 80px;
        height: 80px;
    }

    .Card_title__176EI {
        font-size: 16px;
        margin: 0 0 14px 14px;
        white-space: normal;
    }

    .Card_buttonWrap__2Bb_S {
        right: 14px;
        bottom: 14px;
    }

    .Card_title__176EI.Card_active__McLf3 {
        font-size: 16px;
    }

    .Card_activeContent__27CQ9 {
        margin-left: 14px;
    }

    .Card_button__2UWew {
        width: 60px;
        height: 60px;
    }

    .Card_contentRow__3QHkD span {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 1px) and (max-width: 680px) {
    .Card_card__ZYJp6 {
        max-width: 96%;
        width: 96%;
        margin: 0 auto;
        height: 200px;
    }
}

@media screen and (min-width: 681px) and (max-width: 1024px) {
    .Card_card__ZYJp6 {
        max-width: 100%;
        width: 100%;
    }

    .Card_cardImage__18ug4 {
        margin-left: 30px;
    }

    .Card_title__176EI {
        margin: 0 0 30px 30px;
        font-size: 20px;
    }

    .Card_title__176EI.Card_active__McLf3 {
        font-size: 20px;
        margin: 30px 0 36px 30px;
    }

    .Card_activeContent__27CQ9 {
        margin-left: 30px;
    }

    .Card_buttonWrap__2Bb_S {
        right: 30px;
        bottom: 30px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
    .Card_card__ZYJp6 {
        width: calc(100vw / 2.38);
        height: 320px;
    }

    .Card_cardImage__18ug4 {
        margin: 24px 0 0 24px;
        width: 112px;
        height: 112px;
    }

    .Card_title__176EI {
        font-size: 16px;
    }

    .Card_title__176EI.Card_active__McLf3 {
        margin-bottom: 28px;
    }

    .Card_contentRow__3QHkD span {
        font-size: 14px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .Card_card__ZYJp6 {
        width: 385px;
        height: 380px;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1700px) {
    .Card_card__ZYJp6 {
        width: 450px;
        height: 380px;
    }
}

@media screen and (min-width: 1850px) {
    .Card_card__ZYJp6 {
        width: 450px;
        height: 380px;
    }
}

.Technologies_technologies__3bd-M {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Technologies_technologiesTitle__1dG-3 {
    padding-top: 70px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.Technologies_content__2sPzn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: auto;
    padding-top: 80px;
}

.Technologies_row__1mULP {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-bottom: 70px;
}

.Technologies_area__2Pxcs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-right: 10px;
}

.Technologies_wrap__2ci1G {
    padding-right: 10px;
}

.Technologies_imgBorderWrap__1a01X {
    padding: 12px;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    height: 48px;
    width: 48px;
}

.Technologies_titleBorderWrap__14QK_ {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    text-align: left;
    padding: 10px 12px;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    width: 300px;
    height: 48px;

    color: #111517;
}

.Technologies_technology__1LRig {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 40%;
}

.Technologies_borderWrap__1Q6dM {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    width: 160px;
    height: 48px;
}

.Technologies_technology__1LRig .Technologies_borderWrap__1Q6dM {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    padding: 10px 16px;

    color: #828a9b;
}

.Technologies_technology__1LRig .Technologies_wrap__2ci1G {
    padding-bottom: 10px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Technologies_technologiesTitle__1dG-3 {
        padding-top: 72px;
        text-align: center;
        font-size: 45.83px;
    }

    .Technologies_content__2sPzn {
        padding-top: 40px;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0 auto;
    }

    .Technologies_row__1mULP {
        padding-bottom: 40px;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Technologies_area__2Pxcs {
        padding-bottom: 10px;
        padding-right: 0;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        height: 48px;
    }

    .Technologies_wrap__2ci1G {
        padding-right: 0;
    }

    .Technologies_area__2Pxcs .Technologies_wrap__2ci1G {
        width: 48px;
        height: 48px;
    }

    .Technologies_technology__1LRig {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 100%;
    }

    .Technologies_technology__1LRig .Technologies_wrap__2ci1G {
        width: 49%;
    }

    .Technologies_technology__1LRig .Technologies_borderWrap__1Q6dM {
        font-size: 16px;
    }

    .Technologies_technology__1LRig .Technologies_wrap__2ci1G .Technologies_borderWrap__1Q6dM {
        width: 100%;
    }
}

@media screen and (min-width: 1px) and (max-width: 320px) {
    .Technologies_titleBorderWrap__14QK_ {
        width: 83%;
    }
}

@media screen and (min-width: 321px) and (max-width: 376px) {
    .Technologies_titleBorderWrap__14QK_ {
        width: 84%;
    }
}

@media screen and (min-width: 377px) and (max-width: 420px) {
    .Technologies_titleBorderWrap__14QK_ {
        width: 85%;
    }
}

@media screen and (min-width: 420px) and (max-width: 767px) {
    .Technologies_titleBorderWrap__14QK_ {
        width: 86%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Technologies_technologiesTitle__1dG-3 {
        padding-top: 5%;
    }

    .Technologies_row__1mULP {
        -webkit-justify-content: center;
                justify-content: center;
    }

    .Technologies_titleBorderWrap__14QK_ {
        width: 290px;
    }

    .Technologies_technology__1LRig {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .Technologies_technology__1LRig .Technologies_wrap__2ci1G {
        width: 48%;
        padding-right: 0;
    }

    .Technologies_borderWrap__1Q6dM {
        width: 100%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1063px) {
    .Technologies_technology__1LRig {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .Technologies_technology__1LRig .Technologies_wrap__2ci1G {
        width: 48%;
        padding-right: 0;
    }

    .Technologies_borderWrap__1Q6dM {
        width: 100%;
    }
}

.Industries_industries__1guqF {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 80px;
}

.Industries_industriesTitle__1_GXW {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    /* position: absolute; */
    top: 0;
    left: 0;

    color: #111517;
}

.Industries_content__2IA6Z {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 80px;
}

.Industries_industry__1Offh {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Industries_wrap__1hn6x {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 4%;
    border-bottom: 1px solid #dddfe4;
}

.Industries_row__2wyGt {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.Industries_textWrap__2xV3r {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 16px;
}

.Industries_industryTitle__36rX6 p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;
    line-height: 142%;

    color: #111517;
}

.Industries_text__2F7II {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.01em;

    white-space: nowrap;
    color: #5b6271;
}

.Industries_point__1FVHY {
    min-width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgba(91, 98, 113, 1);
    margin-left: 16px;
}

.Industries_textWrap__2xV3r:last-child .Industries_point__1FVHY:last-child {
    display: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Industries_industries__1guqF {
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .Industries_industriesTitle__1_GXW {
        font-size: 48.83px;
        text-align: center;
    }

    .Industries_content__2IA6Z {
        padding-top: 40px;
    }

    .Industries_textWrap__2xV3r {
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

    .Industries_title__2teBo {
        margin-top: 10px;
    }

    .Industries_industry__1Offh:nth-child(1) .Industries_wrap__1hn6x:nth-child(1) {
        border-top: 1px solid #dddfe4;
    }

    .Industries_wrap__1hn6x {
        padding-bottom: 28px;
    }

    .Industries_row__2wyGt {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Industries_point__1FVHY {
        margin-left: 0;
        margin-right: 16px;
    }

    .Industries_textWrap__2xV3r:last-child .Industries_point__1FVHY:last-child {
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .Industries_industries__1guqF {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Industries_content__2IA6Z {
        width: 80%;
        margin-left: auto;
    }

    .Industries_row__2wyGt {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .Industries_text__2F7II {
        line-height: 228%;
    }
}

.BurgerMenu_burgerMenu__3iFaj {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  bottom: 100%;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: all 1.4s;
  background: rgba(252, 252, 253);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                              supported by Chrome and Opera */
}

/* .burgerMenu.active {
    min-height: 800px;
    height: 800px;
} */

.BurgerMenu_imgCircle__1gQ-V {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 3%;
  border: 2px solid #111517;
}

.BurgerMenu_imgCircle__1gQ-V:last-child {
  margin-right: 0;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .BurgerMenu_navItems__18kQR {
    padding-top: 78px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .BurgerMenu_navItems__18kQR {
    padding-top: 98px;
  }
}

.BurgerMenu_burgerMenu__3iFaj.BurgerMenu_active__207vz {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.BurgerMenu_navItems__18kQR {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 85%;
  margin: 0 auto;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.BurgerMenu_navItemWrap__1Xi4Z {
  padding: 28px 0;
  border-bottom: 1px solid #f4f4f6;
}

.BurgerMenu_navItemWrap__1Xi4Z:first-child {
  padding-top: 0;
}

.BurgerMenu_navItem__2t5Uw {
  text-decoration: none;
}

.BurgerMenu_navItem__2t5Uw.BurgerMenu_disabled__1ziih {
  cursor: not-allowed;
}

.BurgerMenu_navItem__2t5Uw.BurgerMenu_disabled__1ziih .BurgerMenu_navLink__1UmVx {
  position: relative;
  color: lightslategray;
}

.BurgerMenu_navItem__2t5Uw.BurgerMenu_disabled__1ziih .BurgerMenu_navLink__1UmVx:after {
  content: 'SOON';
  position: absolute;
  color: #ff655c;
  top: -5px;
  left: 40px;
  font-size: 10px;
}

.BurgerMenu_navLinkWrap__21HLY {
  width: 100%;
}

.BurgerMenu_activeLink__1yExh div {
  background-color: #ff655c !important;
}

.BurgerMenu_activeLink__1yExh span {
  color: #fdfdfd !important;
}

.BurgerMenu_navItem__2t5Uw span {
  padding-left: 3%;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;

  color: rgba(17, 21, 23, 1);
}

.BurgerMenu_navLink__1UmVx {
  display: block;
  width: 100%;
  padding: 10px 0;
}

.BurgerMenu_imgContainer__R2yZH {
  position: relative;
  width: 85%;
  margin: 10% auto 0;
}

.BurgerMenu_imgLinks__3heOB {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 3%;
}

@media screen and (max-width: 500px) {
  .BurgerMenu_imgLinks__3heOB {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .BurgerMenu_imgCircle__1gQ-V {
    margin-right: 0;
  }

  .BurgerMenu_navItemWrap__1Xi4Z {
    padding: 3% 0;
  }

  .BurgerMenu_imgContainer__R2yZH {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BurgerMenu_imgContainer__R2yZH {
    margin-bottom: 70px;
  }
}

@media screen and (orientation: landscape) {
  .BurgerMenu_imgContainer__R2yZH {
    margin-top: 0;
  }
}

@media screen and (min-height: 1024px) and (max-height: 1400px) {
  .BurgerMenu_navItems__18kQR {
    padding-top: 130px;
  }
}

html {
  font-size: 16px;
}

.Header_headerWrapper__1oKyh {
  background-color: transparent;
  padding-bottom: 100px;
}

.Header_header__1VCKf {
  position: fixed;
  z-index: 10;
  height: 100px;
  width: 100vw;
}

.Header_container__1VC87 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(252, 252, 253, 0.9);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  z-index: 2;
}

.Header_logoWrap__Pe6QT {
  margin-left: 3%;
}

.Header_logos__1tof_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  width: 236px;
  height: 60px;
}

.Header_logo__3_SJs {
  width: 60px;
  height: 60px;
  margin: auto;
}

.Header_large__oRrkk {
  width: 172px;
  height: 44px;
  margin: auto;
}

.Header_navItems__ptFF4 {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 40px;
}

.Header_navItem__38LDp {
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  white-space: nowrap;
  padding: 0px 28px;
}

.Header_navItem__38LDp.Header_disabled__2zvKa {
  cursor: not-allowed;
}

.Header_navItem__38LDp.Header_disabled__2zvKa .Header_navLink__Sd-O5 {
  position: relative;
  color: lightslategray;
}

.Header_navItem__38LDp.Header_disabled__2zvKa .Header_navLink__Sd-O5:after {
  content: 'SOON';
  position: absolute;
  color: #ff655c;
  top: -20px;
  left: 20px;
  font-size: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .Header_navItems__ptFF4 {
    margin-right: 20px;
  }

  .Header_navItem__38LDp {
    padding: 0 18px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .Header_navItems__ptFF4 {
    margin-right: 30px;
  }

  .Header_navItem__38LDp {
    padding: 0 24px;
  }
}

@media (hover: hover) {
  .Header_navItem__38LDp:hover span {
    color: #ff655c;
    transition-property: color;
    transition-duration: 100ms;
  }
}

.Header_navLink__Sd-O5 {
  text-align: center;

  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 138%;
  letter-spacing: 0.24em;
  text-transform: uppercase;

  color: #111517;
}

.Header_active__vmCnS {
  background-color: #ff655c !important;
}

.Header_active__vmCnS span {
  color: #fdfdfd !important;
}

.Header_brgButton__2WW8w {
  padding-right: 4%;
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .Header_headerWrapper__1oKyh {
    padding-bottom: 68px;
  }

  .Header_header__1VCKf {
    height: 68px;
  }

  .Header_logo__3_SJs {
    height: 44px;
    width: 44px;
  }

  .Header_logo__3_SJs img {
    height: 44px;
    width: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .Header_headerWrapper__1oKyh {
    padding-bottom: 98px;
  }

  .Header_header__1VCKf {
    height: 98px;
  }

  .Header_logo__3_SJs {
    height: 56px;
    width: 56px;
  }

  .Header_logo__3_SJs img {
    height: 56px;
    width: 56px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
  .Header_logos__1tof_ {
    width: 60px;
  }

  .Header_large__oRrkk {
    display: none;
  }

  .Header_navItems__ptFF4 {
    display: none;
  }

  .Header_brgButton__2WW8w {
    display: inline-block;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1800px) {
  .Header_navItems__ptFF4 {
    width: 60%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .Header_navLink__Sd-O5 {
    font-size: 0.71rem;
  }
}

.About_about__NonCt {
    overflow: hidden;
}

.About_innerContainer__NVzIw {
    margin: 0 0 0 260px;
    /* border-left: 1px solid black; */
}

@media screen and (max-width: 1201px) {
    .About_innerContainer__NVzIw {
        margin: 0;
    }
}

.AboutPromo_container__3UDPc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.AboutPromo_topWrap__3lEJy {
    padding: 10% 0 0 20%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.AboutPromo_topInfo__2t7eN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.AboutPromo_titles__FyFj_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    line-height: 110%;
    letter-spacing: -0.02em;

    color: #111517;
}

.AboutPromo_title1__1Srca {
    position: relative;
    left: -80px;
}

.AboutPromo_bottomInfo__2mnYg {
    padding-top: 5%;
    width: 63%;
}

.AboutPromo_text__1HeeM {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}

.AboutPromo_orange__lX-kr {
    color: #ff655c;
}

.AboutPromo_bottomWrap__2wbPm {
    padding: 10% 0 3% 20%;
}

.AboutPromo_links__1FqQU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

/* .LinkWrap {
    width: 32%;
    min-height: 100px;
    display: flex;
} */

.AboutPromo_links__1FqQU a {
    cursor: pointer;
    width: 32%;
    min-height: 100px;
    display: -webkit-flex;
    display: flex;
}

.AboutPromo_link__poKqF {
    position: relative;
    width: 100%;
    border: 1px solid #c6cad2;
    box-sizing: border-box;
}

.AboutPromo_link__poKqF p {
    margin: 0;
}

.AboutPromo_linkText__2F8Uf {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.AboutPromo_img__3VDFh {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media (hover: hover) {
    .AboutPromo_link__poKqF:hover {
        background: rgba(17, 21, 23, 0.04);
        transition: all 100ms;
    }
}

.AboutPromo_link__poKqF p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    padding-left: 5%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.AboutPromo_link__poKqF img {
    width: 24px;
    height: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding-right: 8%;
    padding-top: 8%;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
    .AboutPromo_container__3UDPc {
        width: 100%;
    }

    .AboutPromo_titles__FyFj_ span {
        font-size: 52.2px;
        line-height: 110%;
    }

    .AboutPromo_topWrap__3lEJy {
        padding: 10% 3% 0 3%;
    }

    .AboutPromo_title1__1Srca {
        left: 0;
    }

    .AboutPromo_title2__2ox2W {
        width: 50%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .AboutPromo_bottomInfo__2mnYg {
        padding-top: 7%;
        width: 100%;
    }

    .AboutPromo_bottomInfo__2mnYg span {
        font-size: 16px;
        line-height: 156%;
    }

    .AboutPromo_bottomWrap__2wbPm {
        padding: 10% 3% 5% 3%;
    }

    .AboutPromo_linkText__2F8Uf {
        bottom: 5px;
        left: 5px;
    }

    .AboutPromo_img__3VDFh {
        top: 5px;
        right: 5px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .AboutPromo_container__3UDPc {
        width: 100%;
    }

    .AboutPromo_titles__FyFj_ span {
        font-size: 52.2px;
        line-height: 110%;
    }

    .AboutPromo_topWrap__3lEJy {
        padding: 10% 3% 0 3%;
    }

    .AboutPromo_title1__1Srca {
        left: 15%;
    }

    .AboutPromo_title2__2ox2W {
        padding-left: 20%;
        white-space: nowrap;
    }

    .AboutPromo_bottomInfo__2mnYg {
        padding-top: 7%;
        width: 80%;
        margin: auto;
    }

    .AboutPromo_bottomInfo__2mnYg span {
        font-size: 16px;
        line-height: 156%;
    }

    .AboutPromo_bottomWrap__2wbPm {
        padding: 10% 3% 5% 3%;
    }

    .AboutPromo_link__poKqF img {
        padding-right: 2%;
    }

    .AboutPromo_link__poKqF p {
        margin-left: 3%;
        margin-bottom: 3%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1201px) {
    .AboutPromo_container__3UDPc {
        width: 100%;
    }

    .AboutPromo_titles__FyFj_ span {
        font-size: 66.2px;
        line-height: 110%;
    }

    .AboutPromo_topWrap__3lEJy {
        padding: 10% 11% 0 25%;
    }

    .AboutPromo_title1__1Srca {
        left: -15%;
    }

    .AboutPromo_title2__2ox2W {
        width: 100%;
    }

    .AboutPromo_bottomWrap__2wbPm {
        padding-left: 25%;
    }

    .AboutPromo_bottomInfo__2mnYg {
        padding-top: 7%;
        width: 100%;
    }

    .AboutPromo_bottomInfo__2mnYg span {
        font-size: 16px;
        line-height: 156%;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }

    .AboutPromo_link__poKqF img {
        padding-right: 2%;
    }

    .AboutPromo_link__poKqF p {
        margin-left: 3%;
        margin-bottom: 3%;
    }
}

@media screen and (min-height: 1024px) and (max-height: 1400px) {
    .AboutPromo_titles__FyFj_ span {
        font-size: 64.2px;
    }

    .AboutPromo_topWrap__3lEJy {
        padding-left: 12%;
    }

    .AboutPromo_bottomWrap__2wbPm {
        padding-left: 0;
    }
}

.ClientsThoughts_topWrap__2J2sP {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 100px 0 50px;
}

.ClientsThoughts_titleBox__2sspI {
    width: 40%;
}

.ClientsThoughts_topTitle__dCKIN {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.ClientsThoughts_buttons__2J-u_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-touch-callout: none; /* iOS Safari */
}

.ClientsThoughts_button__2Zkte {
    cursor: pointer;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    border-radius: 50%;
    height: 60px;
    width: 60px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media (hover: hover) {
    .ClientsThoughts_button__2Zkte:hover {
        background-color: #f4f4f6;
        transition-property: background-color;
        transition-duration: 150ms;
    }
}

.ClientsThoughts_content__3uh1E {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 90px;
    transition: all 0.5s;
    /* max-width: 100%; */
    overflow: hidden;
}

.ClientsThoughts_content__3uh1E::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    right: 100%;
    top: 0;
    background: #fff;
}

.ClientsThoughts_commentCouple__zX8y- {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 50%;
    transition: all 0.5s;
}

.ClientsThoughts_comment__2XTkt {
    padding-top: 70px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 200px;
    margin-right: 70px;
    margin-bottom: 70px;
}

.ClientsThoughts_commentWrap__2orOq:nth-child(n + 1) {
    border-bottom: 1px solid #dddfe4;
}

.ClientsThoughts_commentWrap__2orOq:nth-child(n + 2) {
    border-bottom: none;
}

@media screen and (min-width: 767px) and (max-width: 950px) {
    .ClientsThoughts_commentWrap__2orOq:nth-child(n + 2) {
        margin-top: 30px;
    }
}

.ClientsThoughts_comment__2XTkt span:first-child {
    width: 73%;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 21.5px;

    color: #111517;
}
.ClientsThoughts_comment__2XTkt span:nth-child(2) {
    width: 73%;
    margin-top: 5px;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #111517;
}
.ClientsThoughts_comment__2XTkt span:last-child {
    width: 73%;
    margin-top: 15px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    color: #494e5b;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
    .ClientsThoughts_topWrap__2J2sP {
        margin: 50px 0 25px;
    }

    .ClientsThoughts_titleBox__2sspI {
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .ClientsThoughts_buttons__2J-u_ {
        display: none;
    }

    .ClientsThoughts_content__3uh1E {
        overflow-x: auto;
    }

    .ClientsThoughts_commentCouple__zX8y- {
        min-width: 100%;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-right: 87%;
        padding: 0;
    }

    .ClientsThoughts_commentWrap__2orOq {
        min-width: calc(100% / 1.05);
    }

    .ClientsThoughts_commentWrap__2orOq:nth-child(n + 1) {
        border-bottom: none;
    }

    .ClientsThoughts_comment__2XTkt {
        padding-top: 3%;
    }

    .ClientsThoughts_comment__2XTkt span {
        width: 90%;
        font-size: 18px;
    }

    .ClientsThoughts_comment__2XTkt p {
        width: 95%;
        font-size: 14px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .ClientsThoughts_topWrap__2J2sP {
        margin: 75px 0 50px;
    }

    .ClientsThoughts_titleBox__2sspI {
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .ClientsThoughts_buttons__2J-u_ {
        display: none;
    }

    .ClientsThoughts_content__3uh1E {
        overflow-x: auto;
    }

    .ClientsThoughts_commentCouple__zX8y- {
        min-width: 100%;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-right: 50%;
        padding: 0;
    }

    .ClientsThoughts_commentWrap__2orOq {
        min-width: calc(94% / 1.25);
    }

    .ClientsThoughts_commentWrap__2orOq:nth-child(n + 1) {
        border-bottom: none;
    }

    .ClientsThoughts_comment__2XTkt {
        padding-top: 3%;
        margin: 0;
    }

    .ClientsThoughts_comment__2XTkt span {
        width: 90%;
        font-size: 20px;
    }

    .ClientsThoughts_comment__2XTkt p {
        width: 80%;
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
    .ClientsThoughts_topWrap__2J2sP {
        margin: 75px 0 50px;
    }

    .ClientsThoughts_clientsThoughts__x5Lma {
        width: 100%;
    }

    .ClientsThoughts_topWrap__2J2sP {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .ClientsThoughts_titleBox__2sspI {
        width: 70%;
    }

    .ClientsThoughts_commentWrap__2orOq:nth-child(n + 1) {
        border-bottom: none;
    }

    .ClientsThoughts_comment__2XTkt span {
        width: 90%;
        font-size: 20px;
    }

    .ClientsThoughts_comment__2XTkt p {
        width: 80%;
        font-size: 16px;
    }
}

@media screen and (min-width: 921px) and (max-width: 1179px) {
    .ClientsThoughts_topWrap__2J2sP {
        margin: 75px 0 50px;
    }

    .ClientsThoughts_clientsThoughts__x5Lma {
        width: 100%;
    }

    .ClientsThoughts_topWrap__2J2sP {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .ClientsThoughts_titleBox__2sspI {
        width: 55%;
    }

    .ClientsThoughts_commentWrap__2orOq:nth-child(n + 1) {
        border-bottom: none;
    }

    .ClientsThoughts_comment__2XTkt span {
        width: 90%;
        font-size: 20px;
    }

    .ClientsThoughts_comment__2XTkt p {
        width: 80%;
        font-size: 16px;
    }
}

@media screen and (min-width: 1180px) and (max-width: 1500px) {
    .ClientsThoughts_topWrap__2J2sP {
        margin: 75px 0 50px;
    }

    .ClientsThoughts_clientsThoughts__x5Lma {
        width: 100%;
    }

    .ClientsThoughts_topWrap__2J2sP {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .ClientsThoughts_titleBox__2sspI {
        width: 42%;
    }

    .ClientsThoughts_commentWrap__2orOq:nth-child(n + 1) {
        border-bottom: none;
    }

    .ClientsThoughts_comment__2XTkt span {
        width: 90%;
        font-size: 20px;
    }

    .ClientsThoughts_comment__2XTkt p {
        width: 80%;
        font-size: 16px;
    }
}

.OurBenefits_tableWrapper__-I6VI {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: left;
    width: 100%;
    padding-top: 60px;
}

.OurBenefits_row__3hNBP {
    border-bottom: 1px solid #dddfe4;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.OurBenefits_row__3hNBP:nth-child(3) {
    border: hidden;
}

.OurBenefits_row__3hNBP > .OurBenefits_column__15GFt:nth-child(n + 2) {
    -webkit-align-items: center;
            align-items: center;
}

.OurBenefits_column__15GFt {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 52px;
}

.OurBenefits_middleLine__3CDWa {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: start;
            align-self: start;
}

.OurBenefits_middleLine__3CDWa img {
    width: 50px;
    height: 30px;
}

.OurBenefits_middleLine__3CDWa p {
    -webkit-margin-before: 0;
            margin-block-start: 0;
}

.OurBenefits_title__1eIdQ {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;

    color: #111517;
}

.OurBenefits_text__1MLYk {
    margin: 0 10px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.OurBenefits_blocks__2Y9Qj {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 110px;
}

.OurBenefits_block__2qMcR {
    width: 49%;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.OurBenefits_blockContent__3TZWj {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 3%;
}

.OurBenefits_blockContent__3TZWj p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}

.OurBenefits_blockContent__3TZWj span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    letter-spacing: 0.01em;

    color: #494e5b;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .OurBenefits_ourBenefits__3mIp3 {
        margin-top: 40px;
    }

    .OurBenefits_tableWrapper__-I6VI {
        width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 25px;
    }

    .OurBenefits_row__3hNBP {
        width: 94%;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: auto;
    }

    .OurBenefits_column__15GFt {
        width: 100%;
        padding-left: 3%;
    }

    .OurBenefits_blocks__2Y9Qj {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 65px;
    }

    .OurBenefits_block__2qMcR {
        width: 94%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .OurBenefits_ourBenefits__3mIp3 {
        margin-top: 80px;
    }

    .OurBenefits_tableWrapper__-I6VI {
        padding-top: 10px;
    }

    .OurBenefits_ourBenefits__3mIp3 {
        width: 80%;
        padding-left: 17%;
    }

    .OurBenefits_blocks__2Y9Qj {
        margin-bottom: 70px;
    }
}

.Pagination_pagination__2plzt {
    height: 46px;
}

.Pagination_buttons__rxdGb {
    display: -webkit-flex;
    display: flex;
    width: 40%;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    padding: 0;
}

.Pagination_pageItem__1pE1R {
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid transparent;
}

.Pagination_pageLink__16kaZ {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    color: #111517;

    border: none;
    background-color: #f4f4f6;
    padding: 18px 20px;
    transition: all 300ms;
}

.Pagination_pageLink__16kaZ.Pagination_active__1R0gI {
    background: #ff655c;
    color: #fdfdfd;
}

@media (hover: hover) {
    button:hover .Pagination_number__1RpZW {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        transition-duration: 100ms;
    }
}

@media screen and (min-width: 1px) and (max-width: 400px) {
    .Pagination_pagination__2plzt {
        width: 98%;
        overflow-x: auto;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.Team_container__5U75B {
    overflow-x: visible;
    padding-top: 100px;
}

.Team_title__1MaI3 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.Team_title__1MaI3 span:first-child {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;

    color: #111517;
}

.Team_title__1MaI3 span:last-child {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.4em;
    text-transform: uppercase;

    color: #494e5b;
}

.Team_buttonsLine__36EI0 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 60px;
}

.Team_buttons__tpiit {
    height: 46px;
    display: -webkit-flex;
    display: flex;
    width: 40%;
    height: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Team_btn__2QBeK {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;
    margin-left: 16px;

    background-color: #f4f4f6;
    color: #111517;

    white-space: nowrap;
    border: none;
    border: 1px solid transparent;
    padding: 10px;
    transition: all 300ms;
}

.Team_buttonWrap__3S1J9 {
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid transparent;
}

@media (hover: hover) {
    .Team_btn__2QBeK:hover {
        border: 1px solid #c6cad2;
    }

    .Team_active__24-5g:hover {
        border: 1px solid transparent;
    }

    .Team_pageLink__ezG1z:hover {
        border: 1px solid #c6cad2;
    }

    .Team_pageLink__ezG1z.Team_active__24-5g:hover {
        border: 1px solid transparent;
    }
}

.Team_active__24-5g {
    background: #ff655c;
    color: #fdfdfd;
}

.Team_pageLink__ezG1z.Team_active__24-5g {
    background: #ff655c;
    color: #fdfdfd;
}

.Team_pageLink__ezG1z {
    cursor: pointer;
    margin-left: 16px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    color: #111517;

    border: 1px solid transparent;
    background-color: #f4f4f6;
    padding: 18px 20px;
    transition: all 300ms;
}

.Team_teammates__2aJXH {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}

.Team_teammateWrap__2NMRG {
    border: 1px solid #c6cad2;
    box-sizing: border-box;
    width: 23%;
    margin-bottom: 40px;
    margin-right: 13px;
}

@media (hover: hover) {
    .Team_teammateWrap__2NMRG:hover {
        border-bottom: 1px solid #ff655c;
    }
}

.Team_teammate__3TOpx {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px;
}

.Team_teammateImg__3h4rP {
    width: 100%;
    height: 100%;
}

.Team_teammate__3TOpx img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.Team_teammate__3TOpx span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin: 0;

    color: #111517;
    padding-top: 50px;
}

.Team_teammate__3TOpx p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    letter-spacing: 0.01em;
    margin: 0;

    color: #828a9b;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
    .Team_container__5U75B {
        display: -webkit-flex;
        display: flex;
        position: relative;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 2%;
        padding-bottom: 8%;
    }

    .Team_title__1MaI3 span:first-child {
        margin-top: 40px;
        font-size: 48.83px;
        line-height: 98%;
    }

    .Team_title__1MaI3 span:last-child {
        display: none;
    }

    .Team_title__1MaI3 {
        -webkit-justify-content: center;
                justify-content: center;
    }

    .Team_buttonsLine__36EI0 {
        max-width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 30px 0 30px 0;
    }

    .Team_buttons__tpiit {
        width: 98%;
        overflow-x: auto;
        margin-bottom: 30px;
    }

    .Team_btn__2QBeK {
        white-space: nowrap;
    }

    .Team_teammates__2aJXH {
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .Team_teammateWrap__2NMRG {
        width: 48%;
        margin: 2% 0;
    }

    .Team_teammate__3TOpx span {
        font-size: 16px;
        padding-top: 10%;
    }

    .Team_teammate__3TOpx p {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 481px) and (max-width: 1000px) {
    .Team_container__5U75B {
        display: -webkit-flex;
        display: flex;
        position: relative;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 2%;
        padding-bottom: 8%;
    }

    .Team_title__1MaI3 span:first-child {
        font-size: 48.83px;
        line-height: 98%;
    }

    .Team_title__1MaI3 {
        margin-top: 60px;
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .Team_buttonsLine__36EI0 {
        max-width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 55px 0 30px 0;
    }

    .Team_buttons__tpiit {
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%;
        margin-bottom: 30px;
    }

    .Team_btn__2QBeK {
        white-space: nowrap;
    }

    .Team_teammates__2aJXH {
        width: 100%;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .Team_teammateWrap__2NMRG {
        width: 48%;
        margin: 2% 0;
    }

    .Team_teammate__3TOpx span {
        font-size: 16px;
        padding-top: 10%;
    }

    .Team_teammate__3TOpx p {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
    .Team_container__5U75B {
        padding-top: 60px;
    }
}

.Cases_cases__mp1Ab {
    overflow: hidden;
}

.Cases_title__17JcX p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;

    margin: 5% 0;
    color: #111517;
}

.Cases_dropdowns__6Xp-V {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Cases_dropdown__UBbVj {
    width: 32%;
    position: relative;
    /* z-index: 3000; */
}

.Cases_project__3BUaN {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Cases_projectContainer__2Wyml {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5% 0;
}

.Cases_projectInfo__keOjB {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .Cases_projectInfo__keOjB {
        margin-right: 30px;
    }
}

.Cases_projectInfo__keOjB a {
    text-decoration: none;
}

.Cases_projectTitle__1HLxt {
    padding-top: 4%;
    width: 75%;

    font-family: Manrope;
    font-style: normal;
    font-size: 22.5px;
    line-height: 142%;

    color: #111517;
}

.Cases_filters__3xHI5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Cases_filterWrap__3-Gnz {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    margin: 5% 5% 0 0;
}

.Cases_filter__1GC0r {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 146%;
    white-space: nowrap;
    text-align: center;

    -webkit-flex: none;

            flex: none;
    -webkit-order: 0;
            order: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    margin: 6px 10px;

    color: #494e5b;
}

.Cases_viewButton__2WkTm {
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-bottom: 2%;
    -webkit-align-items: center;
            align-items: center;
}

.Cases_viewButton__2WkTm p {
    padding-right: 4%;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.Cases_projectImage__20s8R {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

.Cases_line__3lDSQ {
    background: #dddfe4;
    height: 1px;
    border: none;
    width: 98%;
    margin-right: 2%;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
    .Cases_title__17JcX {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0;
    }

    .Cases_title__17JcX p {
        font-size: 52.2px;
        line-height: 110%;
    }

    .Cases_dropdowns__6Xp-V {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
    }

    .Cases_dropdown__UBbVj {
        width: 100%;
        margin-bottom: 3%;
    }

    .Cases_projectContainer__2Wyml {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .Cases_projectTitle__1HLxt {
        font-size: 20px;
        width: 100%;
        padding: 5% 0;
    }

    .Cases_projectImage__20s8R {
        margin-top: 5%;
    }

    .Cases_viewButton__2WkTm {
        display: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 1023px) {
    .Cases_title__17JcX {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding: 0;
    }

    .Cases_title__17JcX p {
        font-size: 66.2px;
        line-height: 110%;
    }

    .Cases_dropdowns__6Xp-V {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
    }

    .Cases_dropdown__UBbVj {
        width: 100%;
        margin-bottom: 3%;
    }

    .Cases_projectContainer__2Wyml {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .Cases_projectTitle__1HLxt {
        font-size: 20px;
        width: 100%;
        padding: 5% 0;
    }

    .Cases_projectImage__20s8R {
        margin: 5% auto 0 auto;
    }

    .Cases_viewButton__2WkTm {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1800px) {
    .Cases_projectTitle__1HLxt {
        width: 85%;
    }

    .Cases_projectImage__20s8R {
        max-width: 70%;
        margin: 0;
    }
}

.IndustryDropdown_dropdown__2Qgte {
    width: 100%;
    border: 1px solid #dddfe4;
    position: relative;
    z-index: 3;
}

.IndustryDropdown_dropdownHeader__hAiED {
    padding: 5%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.IndustryDropdown_dropdownHeader__hAiED::after {
    border-bottom: none;
}

.IndustryDropdown_dropdownBody__dLs1A {
    padding: 2%;
    border: 1px solid #e5e8ec;
    border-top: none;
    display: none;
    position: absolute;
    top: 71px;
    right: 0;
    left: -1px;
    width: 96%;
    max-height: 20vh;
    overflow-y: auto;
    background: white;
}

.IndustryDropdown_dropdownBody__dLs1A::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
}

.IndustryDropdown_dropdownBody__dLs1A::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

.IndustryDropdown_dropdownBody__dLs1A::-webkit-scrollbar-thumb {
    background-color: rgba(255, 101, 92, 1); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
}

.IndustryDropdown_dropdownBody__dLs1A.IndustryDropdown_open__1g1fd {
    display: block;
}

.IndustryDropdown_dropdownItem__qwnNF {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 4%;
    cursor: pointer;
}

@media (hover: hover) {
    .IndustryDropdown_dropdownItem__qwnNF:hover {
        background: #ff655c;
    }

    .IndustryDropdown_dropdownItem__qwnNF:hover span {
        color: #fdfdfd;
    }

    .IndustryDropdown_dropdownItem__qwnNF:hover .IndustryDropdown_checkmark__3Uxg0 {
        border: 3px solid #fdfdfd;
        background-color: #ff655c;
    }

    .IndustryDropdown_dropdownItem__qwnNF:hover .IndustryDropdown_checkBoxContainer__27-uQ input:checked ~ .IndustryDropdown_checkmark__3Uxg0 {
        background-color: #fdfdfd;
    }
    .IndustryDropdown_dropdownItem__qwnNF:hover .IndustryDropdown_checkBoxContainer__27-uQ input:checked ~ .IndustryDropdown_checkmark__3Uxg0::after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #ff655c;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.IndustryDropdown_dropdownItemDot__8-Ajc {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    color: #494e5b;
    transition: all 0.1s ease-in-out;
}

.IndustryDropdown_dropdownItemDot__8-Ajc.IndustryDropdown_selected__UoJr9 {
    opacity: 1;
}

.IndustryDropdown_dropdownButton__33J4T {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.IndustryDropdown_dropdownButton__33J4T.IndustryDropdown_open__1g1fd {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.IndustryDropdown_checkBox__3sHNS {
    position: relative;
    display: -webkit-flex;
    display: flex;
    padding-right: 4.6%;

    color: #fdfdfd;
}

.IndustryDropdown_checkBoxContainer__27-uQ {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.IndustryDropdown_checkBoxContainer__27-uQ input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.IndustryDropdown_checkmark__3Uxg0 {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: #fdfdfd;
    border: 3px solid #111517;
}

/* When the checkbox is checked, add a blue background */
.IndustryDropdown_checkBoxContainer__27-uQ input:checked ~ .IndustryDropdown_checkmark__3Uxg0 {
    background-color: #111517;
}

/* Create the checkmark/indicator (hidden when not checked) */
.IndustryDropdown_checkmark__3Uxg0:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.IndustryDropdown_checkBoxContainer__27-uQ input:checked ~ .IndustryDropdown_checkmark__3Uxg0:after {
    display: block;
}

/* Style the checkmark/indicator */
.IndustryDropdown_checkBoxContainer__27-uQ .IndustryDropdown_checkmark__3Uxg0:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #fdfdfd;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 767px) {
    .IndustryDropdown_dropdownHeader__hAiED {
        font-size: 12.8px;
    }

    .IndustryDropdown_dropdownItemDot__8-Ajc {
        font-size: 12.8px;
    }

    .IndustryDropdown_checkBox__3sHNS {
        padding-right: 8%;
    }

    .IndustryDropdown_dropdownBody__dLs1A {
        top: 53px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
    .IndustryDropdown_dropdownBody__dLs1A {
        top: 55px;
    }
}

.CoopModelDropdown_dropdown__34u76 {
    width: 100%;
    border: 1px solid #dddfe4;
    position: relative;
    z-index: 2;
}

.CoopModelDropdown_dropdownHeader__2qvFv {
    white-space: nowrap;
    padding: 5%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.CoopModelDropdown_dropdownBody__AcL0d {
    padding: 2%;
    border: 1px solid #e5e8ec;
    border-top: none;
    display: none;
    position: absolute;
    top: 71px;
    right: 0;
    left: -1px;
    width: 96%;
    max-height: 20vh;
    overflow-y: auto;
    background: white;
}

.CoopModelDropdown_dropdownBody__AcL0d::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
}

.CoopModelDropdown_dropdownBody__AcL0d::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

.CoopModelDropdown_dropdownBody__AcL0d::-webkit-scrollbar-thumb {
    background-color: rgba(255, 101, 92, 1); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
}

.CoopModelDropdown_dropdownBody__AcL0d.CoopModelDropdown_open__3eeTe {
    display: block;
}

.CoopModelDropdown_dropdownItem__2t6Bh {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 4%;
    cursor: pointer;
}

@media (hover: hover) {
    .CoopModelDropdown_dropdownItem__2t6Bh:hover {
        background: #ff655c;
    }

    .CoopModelDropdown_dropdownItem__2t6Bh:hover span {
        color: #fdfdfd;
    }

    .CoopModelDropdown_dropdownItem__2t6Bh:hover .CoopModelDropdown_checkmark__Gdmok {
        border: 3px solid #fdfdfd;
        background-color: #ff655c;
    }

    .CoopModelDropdown_dropdownItem__2t6Bh:hover .CoopModelDropdown_checkBoxContainer__1ls46 input:checked ~ .CoopModelDropdown_checkmark__Gdmok {
        background-color: #fdfdfd;
    }
    .CoopModelDropdown_dropdownItem__2t6Bh:hover .CoopModelDropdown_checkBoxContainer__1ls46 input:checked ~ .CoopModelDropdown_checkmark__Gdmok::after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #ff655c;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.CoopModelDropdown_dropdownItemDot__2gmSc {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    color: #494e5b;
    transition: all 0.1s ease-in-out;
}

.CoopModelDropdown_dropdownItemDot__2gmSc.CoopModelDropdown_selected__X1RFK {
    opacity: 1;
}

.CoopModelDropdown_dropdownButton__lXDH0 {
    font-family: Manrope;
    font-size: 27px;

    transition: -webkit-transform 0.3s;

    transition: transform 0.3s;

    transition: transform 0.3s, -webkit-transform 0.3s;
}

.CoopModelDropdown_dropdownButton__lXDH0.CoopModelDropdown_open__3eeTe {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.CoopModelDropdown_checkBox__1bwaT {
    position: relative;
    display: -webkit-flex;
    display: flex;
    padding-right: 4.6%;

    color: #fdfdfd;
}

.CoopModelDropdown_checkBoxContainer__1ls46 {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.CoopModelDropdown_checkBoxContainer__1ls46 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.CoopModelDropdown_checkmark__Gdmok {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: #fdfdfd;
    border: 3px solid #111517;
}

/* When the checkbox is checked, add a blue background */
.CoopModelDropdown_checkBoxContainer__1ls46 input:checked ~ .CoopModelDropdown_checkmark__Gdmok {
    background-color: #111517;
}

/* Create the checkmark/indicator (hidden when not checked) */
.CoopModelDropdown_checkmark__Gdmok:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.CoopModelDropdown_checkBoxContainer__1ls46 input:checked ~ .CoopModelDropdown_checkmark__Gdmok:after {
    display: block;
}

/* Style the checkmark/indicator */
.CoopModelDropdown_checkBoxContainer__1ls46 .CoopModelDropdown_checkmark__Gdmok:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #fdfdfd;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 767px) {
    .CoopModelDropdown_dropdownHeader__2qvFv {
        font-size: 12.8px;
    }

    .CoopModelDropdown_dropdownItemDot__2gmSc {
        font-size: 12.8px;
    }

    .CoopModelDropdown_checkBox__1bwaT {
        padding-right: 8%;
    }

    .CoopModelDropdown_dropdownBody__AcL0d {
        top: 53px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
    .CoopModelDropdown_dropdownBody__AcL0d {
        top: 55px;
    }
}

.YearDropdown_dropdown__2Zs2g {
    width: 100%;
    border: 1px solid #dddfe4;
    position: relative;
    z-index: 1;
}

.YearDropdown_dropdownHeader__2141p {
    padding: 5%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.YearDropdown_dropdownBody__3Mtxx {
    padding: 2%;
    border: 1px solid #e5e8ec;
    border-top: none;
    display: none;
    position: absolute;
    top: 71px;
    right: 0;
    left: -1px;
    width: 96%;
    max-height: 20vh;
    overflow-y: auto;
    background: white;
}

.YearDropdown_dropdownBody__3Mtxx::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
}

.YearDropdown_dropdownBody__3Mtxx::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

.YearDropdown_dropdownBody__3Mtxx::-webkit-scrollbar-thumb {
    background-color: rgba(255, 101, 92, 1); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
}

.YearDropdown_dropdownBody__3Mtxx.YearDropdown_open__10lpQ {
    display: block;
}

.YearDropdown_dropdownItem__2ncrA {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 4%;
}

@media (hover: hover) {
    .YearDropdown_dropdownItem__2ncrA:hover {
        background: #ff655c;
    }

    .YearDropdown_dropdownItem__2ncrA:hover span {
        color: #fdfdfd;
    }

    .YearDropdown_dropdownItem__2ncrA:hover .YearDropdown_checkmark__2G2-W {
        border: 3px solid #fdfdfd;
        background-color: #ff655c;
    }

    .YearDropdown_dropdownItem__2ncrA:hover .YearDropdown_checkBoxContainer__2Tr-c input:checked ~ .YearDropdown_checkmark__2G2-W {
        background-color: #fdfdfd;
    }
    .YearDropdown_dropdownItem__2ncrA:hover .YearDropdown_checkBoxContainer__2Tr-c input:checked ~ .YearDropdown_checkmark__2G2-W::after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #ff655c;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.YearDropdown_dropdownItemDot__2sFxy {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    color: #494e5b;
    transition: all 0.1s ease-in-out;
}

.YearDropdown_dropdownItemDot__2sFxy.YearDropdown_selected__x98TH {
    opacity: 1;
}

.YearDropdown_dropdownButton__pCmNQ {
    font-family: Manrope;
    font-size: 27px;

    transition: -webkit-transform 0.3s;

    transition: transform 0.3s;

    transition: transform 0.3s, -webkit-transform 0.3s;
}

.YearDropdown_dropdownButton__pCmNQ.YearDropdown_open__10lpQ {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.YearDropdown_checkBox__1lZ-d {
    position: relative;
    display: -webkit-flex;
    display: flex;
    padding-right: 4.6%;

    color: #fdfdfd;
}

.YearDropdown_checkBoxContainer__2Tr-c {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.YearDropdown_checkBoxContainer__2Tr-c input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.YearDropdown_checkmark__2G2-W {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: #fdfdfd;
    border: 3px solid #111517;
}

/* When the checkbox is checked, add a blue background */
.YearDropdown_checkBoxContainer__2Tr-c input:checked ~ .YearDropdown_checkmark__2G2-W {
    background-color: #111517;
}

/* Create the checkmark/indicator (hidden when not checked) */
.YearDropdown_checkmark__2G2-W:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.YearDropdown_checkBoxContainer__2Tr-c input:checked ~ .YearDropdown_checkmark__2G2-W:after {
    display: block;
}

/* Style the checkmark/indicator */
.YearDropdown_checkBoxContainer__2Tr-c .YearDropdown_checkmark__2G2-W:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #fdfdfd;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 767px) {
    .YearDropdown_dropdownHeader__2141p {
        font-size: 12.8px;
    }

    .YearDropdown_dropdownItemDot__2sFxy {
        font-size: 12.8px;
    }

    .YearDropdown_checkBox__1lZ-d {
        padding-right: 8%;
    }

    .YearDropdown_dropdownBody__3Mtxx {
        top: 53px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
    .YearDropdown_dropdownBody__3Mtxx {
        top: 55px;
    }
}

.NotFoundPage_notFoundPage__3lyKM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: calc(100vh - 100px);
}

.NotFoundPage_greySection__184gW {
    background-color: #f4f4f6;
}

.NotFoundPage_container__2TBYn {
    width: 96%;
    max-width: 1400px;
    margin: 0 auto;
    /* border-left: 1px solid black;
    border-right: 1px solid black;    */
}

.NotFoundPage_footerContainer__3w9jR {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.NotFoundPage_message__TaK-Z {
    width: 28%;
    margin: 10% auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.NotFoundPage_message__TaK-Z p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;

    text-align: center;
    white-space: nowrap;

    color: #111517;
}

.NotFoundPage_message__TaK-Z span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;

    text-align: center;
    letter-spacing: 0.02em;

    color: #5b6271;
}

.NotFoundPage_homeLink__3kOlM {
    margin-top: 3%;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.NotFoundPage_homeLink__3kOlM p {
    padding-right: 7%;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    white-space: nowrap;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .NotFoundPage_message__TaK-Z {
        width: 80%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .NotFoundPage_message__TaK-Z {
        width: 100%;
    }

    .NotFoundPage_message__TaK-Z span {
        width: 65%;
    }

    .NotFoundPage_homeLink__3kOlM {
        margin-top: 2%;
    }
}

.NavigationFooter_footerContainer__27aYX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 110px 11.5% 58px;
}

.NavigationFooter_title__3qyKj {
  padding-bottom: 16px;
}

.NavigationFooter_info__1QcMk {
  padding-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 100px;
}

.NavigationFooter_infoBoxes__3kHBA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 90%;
}

.NavigationFooter_infoBox__3xfj- {
  width: 24%;
  font-family: Manrope;
  font-style: normal;
  line-height: 156%;

  color: #111517;
}

.NavigationFooter_infoTitle__nU4Ep {
  font-weight: 500;
  font-size: 18px;
}

.NavigationFooter_infoBox__3xfj- ul {
  margin-left: 0;
  padding-left: 0;
}

.NavigationFooter_infoBox__3xfj- li {
  list-style-type: none;
  font-weight: normal;
  font-size: 14.4px;
  letter-spacing: 0.01em;

  color: #111517;
}

.NavigationFooter_infoBox__3xfj- a {
  text-decoration: none;
  color: #111517;
}

@media (hover: hover) {
  .NavigationFooter_infoBox__3xfj-:last-child li:hover,
  .NavigationFooter_bottomItems__fME7Z a:hover,
  .NavigationFooter_imgLinks__3rKpq img:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition-duration: 150ms;
  }

  .NavigationFooter_imgLinks__3rKpq img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

.NavigationFooter_imgLinks__3rKpq {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.NavigationFooter_imgLinks__3rKpq a {
  margin: 0 3%;
}

.NavigationFooter_imgLinks__3rKpq a:nth-last-child(1) {
  margin-left: 3%;
  margin-right: 0;
}

.NavigationFooter_hrGrey__3Ahoc {
  background: #ebedf2;
  height: 1px;
  border: none;
  width: 100%;
}

.NavigationFooter_bottomItems__fME7Z {
  padding-top: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;

  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #111517;
}

.NavigationFooter_bottomItems__fME7Z span {
  white-space: nowrap;
  text-decoration: none;
  padding-right: 18px;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 146%;

  padding-right: 10%;

  color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .NavigationFooter_footerContainer__27aYX {
    padding: 44px 5% 56px;
  }

  .NavigationFooter_container__3hn4L {
    padding-bottom: 2%;
    width: 94%;
    margin: auto;
  }

  .NavigationFooter_footer__IyyoR {
    padding: 2% 0;
  }

  .NavigationFooter_footerWrap__3T-s5 {
    width: 94%;
  }

  .NavigationFooter_title__3qyKj {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2%;
  }

  .NavigationFooter_hrGray__1M7X2 {
    width: 94%;
    margin: 0 auto 3%;
  }

  .NavigationFooter_info__1QcMk {
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .NavigationFooter_infoBoxes__3kHBA {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .NavigationFooter_infoBox__3xfj- {
    width: 50%;
    white-space: normal;
  }

  .NavigationFooter_infoBox__3xfj-:last-child {
    width: 100%;
  }

  .NavigationFooter_infoTitle__nU4Ep .NavigationFooter_infoBox__3xfj-:nth-last-child(1) {
    width: 150px;
  }

  .NavigationFooter_hrGrayMobile__4-G-H {
    display: -webkit-inline-flex;
    display: inline-flex;
    background: #dddfe4;
    height: 1px;
    border: none;
    width: 94%;
    margin: 3% auto 5%;
  }

  .NavigationFooter_imgLinks__3rKpq {
    display: -webkit-flex;
    display: flex;
    padding-top: 10%;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .NavigationFooter_bottomItems__fME7Z {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
  }

  .NavigationFooter_bottomItems__fME7Z span {
    font-size: 9px;
    color: #111517;
    white-space: nowrap;
    padding-right: 0;
  }
}

@media (min-width: 600px) and (max-width: 699px) {
  .NavigationFooter_infoBox__3xfj- {
    width: 43%;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  .NavigationFooter_infoBox__3xfj- {
    width: 39%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .NavigationFooter_footerContainer__27aYX {
    padding: 44px 5% 56px;
  }

  .NavigationFooter_footerWrap__3T-s5 {
    width: 96%;
    margin: auto;
  }

  .NavigationFooter_title__3qyKj {
    display: -webkit-flex;
    display: flex;
  }

  .NavigationFooter_infoTitle__nU4Ep {
    font-size: 16px;
    font-weight: 600;
  }

  .NavigationFooter_hrGray__1M7X2 {
    width: 100%;
    margin: 0 auto 3%;
  }

  .NavigationFooter_info__1QcMk {
    width: 100%;
    padding-bottom: 5%;
  }

  .NavigationFooter_infoBoxes__3kHBA {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .NavigationFooter_infoBox__3xfj- {
    width: 260px;
    white-space: normal;
  }

  .NavigationFooter_imgLinks__3rKpq {
    width: 30%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .NavigationFooter_imgLinks__3rKpq a {
    padding: 0 10%;
  }

  .NavigationFooter_imgLinks__3rKpq a:nth-last-child(1) {
    padding-left: 10%;
    padding-right: 0;
  }

  .NavigationFooter_bottomItems__fME7Z {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 5%;
    padding-top: 3%;
  }

  .NavigationFooter_bottomItems__fME7Z span {
    font-size: 9px;
    color: #111517;
    white-space: nowrap;
    padding-right: 15%;
  }
}

.PrivacyPolicy_privacyPolicy__25pTK {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.PrivacyPolicy_container__3v_vr {
    width: 96%;
    max-width: 1400px;
    margin: 0 auto;
    /* border-left: 1px solid black;
    border-right: 1px solid black;    */
}

.PrivacyPolicy_footerContainer__13sGo {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.PrivacyPolicy_greySection__2fExi {
    background-color: #f4f4f6;
}

.PrivacyPolicy_titleContainer__3T7H1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 84px 0 68px 0;
}

.PrivacyPolicy_title__myD5v {
    max-width: 11ch;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    letter-spacing: -0.02em;
    line-height: 110%;

    color: #111517;
}

.PrivacyPolicy_points__oelw7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-top: 20px;
}

.PrivacyPolicy_point__1O3Aj {
    width: 20px;
    height: 20px;
    background: #111517;
    border-radius: 50%;
    margin-right: 12px;
}

.PrivacyPolicy_point__1O3Aj:nth-child(3) {
    background: #ff655c;
}

.PrivacyPolicy_textContainer__3C_4E {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 20px;
}

.PrivacyPolicy_rowWrap__3iJWt {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 52px 0;
    border-bottom: 1px solid #dddfe4;
}

.PrivacyPolicy_textWrap__2Ryz6 {
    width: 70%;
}

.PrivacyPolicy_textTitle__Dt1TG {
    width: 20%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 28.13px;

    color: #111517;
}

.PrivacyPolicy_text__1dnIi {
    width: 100%;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.PrivacyPolicy_textW600__1F_IG {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.PrivacyPolicy_column__2jU3j {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.PrivacyPolicy_row__1wSVU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.PrivacyPolicy_values__10--f {
    padding-top: 52px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.PrivacyPolicy_value__2ZSAz {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    /* white-space: nowrap; */
    padding-bottom: 12px;
}

.PrivacyPolicy_values__10--f .PrivacyPolicy_value__2ZSAz:nth-last-child(1) {
    padding-bottom: 0;
}

.PrivacyPolicy_valuePoint__3d7Am {
    width: 8px;
    height: 8px;
    background: #ff655c;
    border-radius: 50%;
    margin-right: 12px;
}

.PrivacyPolicy_interiorTitle__aTnbR {
    padding: 52px 0 20px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156%;

    color: #111517;
}

.PrivacyPolicy_interiorText__3RGx1 {
    width: 100%;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.PrivacyPolicy_redText__2L816 {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #ff655c;
}

.PrivacyPolicy_rowWrap__3iJWt:nth-last-child(1) {
    border: none;
}

.PrivacyPolicy_rowWrap__3iJWt:nth-last-child(1) .PrivacyPolicy_textW600__1F_IG {
    padding-top: 24px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .PrivacyPolicy_container__3v_vr {
        width: 94%;
        max-width: 100%;
    }

    .PrivacyPolicy_titleContainer__3T7H1 {
        padding: 24px 0 24px 0;
    }

    .PrivacyPolicy_title__myD5v {
        font-size: 52.2px;
        text-align: center;
        line-height: 105%;
    }

    .PrivacyPolicy_points__oelw7 {
        display: none;
    }

    .PrivacyPolicy_textContainer__3C_4E {
        padding: 0;
    }

    .PrivacyPolicy_rowWrap__3iJWt {
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 32px 0;
    }

    .PrivacyPolicy_textTitle__Dt1TG {
        width: 100%;
        margin-bottom: 20px;
        font-size: 25px;
    }

    .PrivacyPolicy_textWrap__2Ryz6 {
        width: 100%;
    }

    .PrivacyPolicy_text__1dnIi {
        font-size: 16px;
    }

    .PrivacyPolicy_textW600__1F_IG {
        color: rgba(17, 21, 23, 1);
    }

    .PrivacyPolicy_values__10--f {
        padding-top: 32px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .PrivacyPolicy_container__3v_vr {
        width: 92%;
        max-width: 100%;
    }

    .PrivacyPolicy_titleContainer__3T7H1 {
        padding: 24px 0 24px 0;
    }

    .PrivacyPolicy_title__myD5v {
        font-size: 52.2px;
        line-height: 105%;
    }

    .PrivacyPolicy_textContainer__3C_4E {
        padding: 0;
    }

    .PrivacyPolicy_rowWrap__3iJWt {
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 32px 0;
    }

    .PrivacyPolicy_textTitle__Dt1TG {
        width: 100%;
        margin-bottom: 20px;
        font-size: 25px;
    }

    .PrivacyPolicy_textWrap__2Ryz6 {
        width: 100%;
    }

    .PrivacyPolicy_text__1dnIi {
        font-size: 16px;
    }

    .PrivacyPolicy_textW600__1F_IG {
        color: rgba(17, 21, 23, 1);
    }

    .PrivacyPolicy_values__10--f {
        padding-top: 32px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .PrivacyPolicy_title__myD5v {
        font-size: 52px;
    }
}

.ProjectPage_container__22vAr {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}

.ProjectPage_footerContainer__1U5rg {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.ProjectPage_greySection__2u5_0 {
    background-color: #f4f4f6;
}

.ProjectPage_infoBlock__11I5X {
    margin-top: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #dddfe4;
    padding-bottom: 50px;
}

.ProjectPage_textWrap__3PDto {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.ProjectPage_title__GPoIn span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-size: 54.93px;

    color: #111517;
}

.ProjectPage_text__37GL4 p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.ProjectPage_circles__8Ja4R {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.ProjectPage_circleWrap__27s1H {
    padding-right: 20px;
}

.ProjectPage_circleWrap__27s1H:nth-child(2) {
    padding-right: 0;
}

.ProjectPage_circle__DZKBB {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2%;
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
    border: 2px solid #111517;
    box-sizing: border-box;
    border-radius: 50%;
}

.ProjectPage_circle__DZKBB span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    line-height: 156%;
    text-align: center;
    letter-spacing: 0.02em;
    width: 90%;
    margin: 0 auto;

    color: #111517;
}

.ProjectPage_filters__1zpw7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 20px 0 80px;
}

.ProjectPage_filterWrap__2MsYW {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    margin-right: 10px;
}

.ProjectPage_filters__1zpw7 .ProjectPage_filterWrap__2MsYW:nth-child(2) {
    margin-right: 0;
}

.ProjectPage_filter__1NjBE {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 146%;
    white-space: nowrap;
    text-align: center;

    -webkit-flex: none;

            flex: none;
    -webkit-order: 0;
            order: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    margin: 6px 10px;

    color: #494e5b;
}

.ProjectPage_images__3HEiQ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 40px;
}

.ProjectPage_images__3HEiQ img {
    margin-bottom: 60px;
}

.ProjectPage_images__3HEiQ img:nth-last-child(1) {
    margin-bottom: 90px;
}

.ProjectPage_buttons__38ynV {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.ProjectPage_btnWrap__3KoQ7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.ProjectPage_btnWrap__3KoQ7 span {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.ProjectPage_btnWrap__3KoQ7:nth-child(1) .ProjectPage_button__3tD_9 {
    margin-right: 32px;
}

.ProjectPage_btnWrap__3KoQ7:nth-child(2) span {
    margin-right: 32px;
}

.ProjectPage_button__3tD_9 {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    border-radius: 50%;
    height: 100px;
    width: 100px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
@media (hover: hover) {
    .ProjectPage_button__3tD_9:hover {
        background-color: #f4f4f6;
        transition-property: background-color;
        transition-duration: 150ms;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .ProjectPage_container__22vAr {
        min-width: 94%;
    }

    .ProjectPage_infoBlock__11I5X {
        margin-top: 28px;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .ProjectPage_textWrap__3PDto {
        width: 100%;
    }

    .ProjectPage_title__GPoIn {
        display: -webkit-flex;
        display: flex;
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .ProjectPage_title__GPoIn span {
        font-size: 48.83px;
    }

    .ProjectPage_text__37GL4 p {
        font-size: 16px;
    }

    .ProjectPage_circles__8Ja4R {
        padding-top: 30px;
    }

    .ProjectPage_circle__DZKBB {
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
    }

    .ProjectPage_filters__1zpw7 {
        margin-bottom: 40px;
    }

    .ProjectPage_images__3HEiQ img {
        margin-bottom: 20px;
    }

    .ProjectPage_images__3HEiQ img:nth-last-child(1) {
        margin-bottom: 32px;
    }

    .ProjectPage_buttons__38ynV {
        margin-bottom: 20px;
    }

    .ProjectPage_btnWrap__3KoQ7 span {
        display: none;
    }

    .ProjectPage_button__3tD_9 {
        width: 60px;
        height: 60px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ProjectPage_container__22vAr {
        min-width: 90%;
    }

    .ProjectPage_infoBlock__11I5X {
        margin-top: 40px;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-bottom: 40px;
    }

    .ProjectPage_textWrap__3PDto {
        width: 100%;
    }

    .ProjectPage_title__GPoIn {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

    .ProjectPage_title__GPoIn span {
        font-size: 48.83px;
    }

    .ProjectPage_text__37GL4 p {
        font-size: 16px;
    }

    .ProjectPage_circles__8Ja4R {
        padding-top: 40px;
    }

    .ProjectPage_circle__DZKBB {
        min-width: 120px;
        max-width: 120px;
        min-height: 120px;
        max-height: 120px;
    }

    .ProjectPage_images__3HEiQ img {
        margin-bottom: 32px;
    }

    .ProjectPage_images__3HEiQ img:nth-last-child(1) {
        margin-bottom: 40px;
    }

    .ProjectPage_buttons__38ynV {
        margin-bottom: 40px;
    }

    .ProjectPage_btnWrap__3KoQ7 span {
        font-size: 12.8px;
    }

    .ProjectPage_button__3tD_9 {
        width: 60px;
        height: 60px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
    .ProjectPage_title__GPoIn span {
        font-size: 45.93px;
    }

    .ProjectPage_text__37GL4 p {
        font-size: 16px;
    }
}

.Careers_footerContainer__tG8jS {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.Careers_greySection__354jp {
    background-color: #f4f4f6;
}

.Careers_topWrap__2cEfH {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(221, 223, 228, 1);
    padding-bottom: 20px;
}

.Careers_textWrap__2GWux {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 50px;
    /* padding-bottom: 100px; */
}

.Careers_titleText__3yKvJ {
    /* padding-top: 40px; */
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    line-height: 85%;
    letter-spacing: -0.02em;

    color: #111517;
}

.Careers_text__2MoxW {
    display: block;
    width: 44%;
}

.Careers_text__2MoxW span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.Careers_buttonsContainer__34Zl2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 30px;
}

.Careers_buttons__3SfVd {
    height: 46px;
    display: -webkit-flex;
    display: flex;
    width: 40%;
    height: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Careers_btn__1fVik {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;

    color: #111517;

    white-space: nowrap;
    border: 1px solid transparent;
    background-color: white;
    padding: 10px;
    transition: all 300ms;
}

.Careers_buttonWrap__32O6q {
    margin-right: 5px;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid transparent;
}

@media (hover: hover) {
    .Careers_btn__1fVik:hover {
        border: 1px solid #c6cad2;
    }

    .Careers_active__1JPPw:hover {
        border: 1px solid transparent;
    }

    .Careers_pageLink__1pfpJ:hover {
        border: 1px solid #c6cad2;
    }

    .Careers_pageLink__1pfpJ.Careers_active__1JPPw:hover {
        border: 1px solid transparent;
    }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
    .Careers_buttonsContainer__34Zl2 {
        max-width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 30px 0 30px 0;
    }

    .Careers_buttons__3SfVd {
        width: 98%;
        overflow-x: auto;
        margin-bottom: 30px;
    }

    .Careers_btn__1fVik {
        white-space: nowrap;
    }
}
@media screen and (min-width: 481px) and (max-width: 1000px) {
    .Careers_buttonsContainer__34Zl2 {
        max-width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 55px 0 30px 0;
    }

    .Careers_buttons__3SfVd {
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%;
        margin-bottom: 30px;
    }

    .Careers_btn__1fVik {
        white-space: nowrap;
    }
}

.Careers_pageLink__1pfpJ.Careers_active__1JPPw {
    background: #ff655c;
    color: #fdfdfd;
}

.Careers_pageLink__1pfpJ {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-left: 5px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    color: #111517;

    border: 1px solid transparent;
    background-color: white;
    padding: 18px 20px;
    transition: all 300ms;
}

.Careers_active__1JPPw {
    background: #ff655c;
    color: #fdfdfd;
}
.Careers_circles__3sMdK {
    margin: 80px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.Careers_circle__3foxS {
    background-color: white;
    text-decoration: none;
    color: #111517;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 5%;
    min-width: 21em;
    max-width: 21em;
    min-height: 21em;
    max-height: 21em;
    border: 2px solid #111517;
    box-sizing: border-box;
    border-radius: 50%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Careers_formContainer__1Zl7a {
    width: calc(100% - 80px);
    margin: 120px auto 0 auto;
    background: #FF655C;
}

@media (min-width: 501px) and (max-width: 768px) {
    .Careers_formContainer__1Zl7a {
        margin-top: 90px;
    }
}

@media (max-width: 500px) {
    .Careers_formContainer__1Zl7a {
        margin-top: 60px;
    }
}

@media (min-width: 1201px) and (max-width: 1300px) {
    .Careers_formContainer__1Zl7a {
        width: calc(100% - 60px);
    }
}
@media (min-width: 769px) and (max-width: 1200px) {
    .Careers_formContainer__1Zl7a {
        width: calc(100% - 40px);
    }
}
@media (min-width: 1px) and (max-width: 768px) {
    .Careers_formContainer__1Zl7a {
        width: calc(100% - 16px);
    }
}

@media (hover: hover) {
    .Careers_circle__3foxS:hover {
        background: #f4f4f6;
    }
}

.Careers_circle__3foxS span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;

    width: 90%;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Careers_textWrap__2GWux {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .Careers_titleText__3yKvJ {
        font-size: 66.2px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .Careers_text__2MoxW {
        width: 100%;
        padding-top: 30px;
    }

    .Careers_text__2MoxW span {
        font-size: 16px;
    }

    .Careers_circles__3sMdK {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 40px 0;
    }

    .Careers_circleWrap__Hee0y {
        width: 70%;
    }

    .Careers_circleWrap__Hee0y {
        width: 15em;
        padding-bottom: 2%;
    }

    .Careers_circleWrap__Hee0y:nth-child(1),
    .Careers_circleWrap__Hee0y:nth-child(3) {
        margin-left: 5%;
    }
    .Careers_circleWrap__Hee0y:nth-child(2),
    .Careers_circleWrap__Hee0y:nth-child(4) {
        margin-left: auto;
        margin-right: 5%;
    }

    .Careers_circle__3foxS {
        min-width: 15em;
        max-width: 15em;
        min-height: 15em;
        max-height: 15em;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0 3%;
    }

    .Careers_circle__3foxS span {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .Careers_circleWrap__Hee0y {
        width: 17em;
    }

    .Careers_circle__3foxS {
        min-width: 17em;
        max-width: 17em;
        min-height: 17em;
        max-height: 17em;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1px) and (max-width: 405px) {
    .Careers_textWrap__2GWux {
        padding-bottom: 150px;
    }
}

@media screen and (min-width: 406px) and (max-width: 550px) {
    .Careers_textWrap__2GWux {
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 551px) and (max-width: 767px) {
    .Careers_textWrap__2GWux {
        padding-bottom: 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .Careers_textWrap__2GWux {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-top: 40px;
        margin-bottom: 70px;
    }

    .Careers_titleText__3yKvJ {
        padding-bottom: 30px;
    }

    .Careers_text__2MoxW {
        width: 100%;
    }

    .Careers_circles__3sMdK {
        width: 94%;
        margin: 60px 3%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .Careers_circleWrap__Hee0y {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        width: 50%;
        padding-bottom: 3%;
    }
}

@media screen and (min-width: 830px) and (max-width: 940px) {
    .Careers_circle__3foxS {
        min-width: 23em;
        max-width: 23em;
        min-height: 23em;
        max-height: 23em;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 941px) and (max-width: 1100px) {
    .Careers_circle__3foxS {
        min-width: 25em;
        max-width: 25em;
        min-height: 25em;
        max-height: 25em;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .Careers_circles__3sMdK {
        margin-top: 60px;
    }

    .Careers_circle__3foxS {
        min-width: 27em;
        max-width: 27em;
        min-height: 27em;
        max-height: 27em;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .Careers_circles__3sMdK {
        width: 100%;
        padding-bottom: 0;
    }

    .Careers_circle__3foxS {
        min-width: 16em;
        max-width: 16em;
        min-height: 16em;
        max-height: 16em;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1550px) {
    .Careers_circles__3sMdK {
        width: 100%;
        padding-bottom: 0;
    }

    .Careers_circle__3foxS {
        min-width: 18em;
        max-width: 18em;
        min-height: 18em;
        max-height: 18em;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 0 3%;
    }
}

.Job_job__-wxJj {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Job_infoBlock__1Q5hf {
    margin-top: -15%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 50%;
}

.Job_title__2uUdi {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 43.95px;
    line-height: 118%;

    color: #000000;
    margin-bottom: 24px;
}

.Job_text__15jHI {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
    margin-bottom: 68px;
}

.Job_subtitle__3JE2Z {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;
    margin-bottom: 22px;

    color: #111517;
}

.Job_column___b6T6 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Job_row__2uVNv {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-bottom: 4px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #111517;
}

.Job_column___b6T6:nth-child(2) .Job_row__2uVNv {
    margin-bottom: 50px;
}

.Job_row__2uVNv:last-child {
    margin-bottom: 50px;
}

.Job_row__2uVNv img {
    margin: 6px 16px 0 0;
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
    .Job_row__2uVNv img {
        margin-top: 4px;
    }
}

.Job_actionWrap__1DYMp {
    top: 15%;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    width: 25%;
    border: 1px solid #dddfe4;
    padding: 32px;
    margin-left: auto;
}

.Job_actionBlock__81v2d {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Job_buttonWrap__wWspa {
    padding: 28px;
    background: #ff655c;
}

.Job_buttonWrap__wWspa {
    text-decoration: none;
}

.Job_button__WuCIx {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Job_buttonWrap__wWspa {
    color: #111517;
    background-image: linear-gradient(45deg, #111517 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 900ms ease-in-out;
}

@media (hover: hover) {
    .Job_buttonWrap__wWspa:hover {
        background-position: 0;
    }
}

.Job_buttonWrap__wWspa span {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #fdfdfd;
}

.Job_hrGray__3-be6 {
    margin: 32px 0;
    height: 1px;
    width: 100%;
    border: none;

    background: #dddfe4;
}

.Job_shareRow__1-sSd {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Job_shareRow__1-sSd span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    color: #111517;
}

.Job_links__2njIT .Job_img__2GHCA {
    margin-right: 15px;
    cursor: pointer;
}

@media (hover: hover) {
    .Job_links__2njIT .Job_img__2GHCA:hover {
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
        transition-duration: 150ms;
    }
}

.Job_links__2njIT .Job_img__2GHCA:last-child {
    margin-right: 0;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .Job_infoBlock__1Q5hf {
        width: 100%;
        margin-top: 12px;
    }

    .Job_title__2uUdi {
        font-size: 36.06px;
        line-height: 118%;
        margin-bottom: 12px;
    }

    .Job_text__15jHI {
        font-size: 16px;
        margin-bottom: 32px;
    }

    .Job_subtitle__3JE2Z {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .Job_column___b6T6:nth-child(2) .Job_row__2uVNv {
        margin-bottom: 32px;
    }

    .Job_row__2uVNv:last-child {
        margin-bottom: 32px;
    }

    .Job_row__2uVNv {
        font-size: 16px;
    }

    .Job_actionWrap__1DYMp {
        position: relative;
        width: 100%;
        right: 0;
        top: 0;
        padding: 0;
        margin-bottom: 48px;
    }

    .Job_actionBlock__81v2d {
        margin: 12px;
    }

    .Job_buttonWrap__wWspa {
        padding: 16px;
    }

    .Job_button__WuCIx span {
        font-size: 12.8px;
    }

    .Job_hrGray__3-be6 {
        margin: 16px 0;
    }

    .Job_shareRow__1-sSd span {
        font-size: 16px;
    }

    .Job_links__2njIT img {
        margin-right: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Job_infoBlock__1Q5hf {
        width: 100%;
        margin-top: 24px;
    }

    .Job_title__2uUdi {
        font-size: 39.06px;
        line-height: 118%;
        margin-bottom: 24px;
    }

    .Job_text__15jHI {
        font-size: 16px;
        margin-bottom: 48px;
    }

    .Job_subtitle__3JE2Z {
        margin-bottom: 16px;
    }

    .Job_row__2uVNv {
        font-size: 16px;
    }

    .Job_actionWrap__1DYMp {
        position: relative;
        width: 100%;
        right: 0;
        top: 0;
        padding: 0;
        margin-bottom: 48px;
    }

    .Job_actionBlock__81v2d {
        margin: 32px;
    }

    .Job_buttonWrap__wWspa {
        padding: 28px;
    }

    .Job_button__WuCIx span {
        font-size: 12.8px;
    }

    .Job_hrGray__3-be6 {
        margin: 35px 0;
    }

    .Job_shareRow__1-sSd span {
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1350px) {
    .Job_actionWrap__1DYMp {
        right: 5%;
        width: 30%;
    }
}

.JoinTheTeam_joinTheTeam__34c7R {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 80px;
    width: 60%;
}

.JoinTheTeam_btnCircle__bFg13 {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #111517;
}

.JoinTheTeam_btnCircle__bFg13 img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

@media (hover: hover) {
    .JoinTheTeam_btnCircle__bFg13:hover {
        background: #ff655c;
        transition-duration: 150ms;
    }
}

.JoinTheTeam_title__2EONH {
    margin-top: 70px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.JoinTheTeam_title__2EONH span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 43.95px;
    line-height: 118%;

    color: #000000;
}

.JoinTheTeam_points__GUB8u {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.JoinTheTeam_point__WuQqD {
    width: 28px;
    height: 28px;
    background: #111517;
    border-radius: 50%;
    margin-right: 8px;
}

.JoinTheTeam_point__WuQqD:last-child {
    background: #ff655c;
    margin-right: 12px;
}

.JoinTheTeam_form__2zuEW {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 80px;
}

.JoinTheTeam_field__g-OI1 {
    width: 49%;
    margin-bottom: 40px;
}

.JoinTheTeam_error__1HU-v {
    background-image: url(/static/media/Warning.dd51008b.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: bottom 0 right 16px;
    transition:0.3s;
}

.JoinTheTeam_form__2zuEW .JoinTheTeam_field__g-OI1:last-child {
    width: 100%;
    margin-bottom: 0;
}

.JoinTheTeam_fieldCouple__2zZJ7 .JoinTheTeam_field__g-OI1:last-child {
    width: 49%;
}

.JoinTheTeam_fieldCouple__2zZJ7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.JoinTheTeam_form__2zuEW input[type='text'] {
    background-color: white;
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
}

.JoinTheTeam_hrBlack__3zJk0 {
    height: 2px;
    left: 650px;
    top: 526px;
    border: none;

    background: #111517;
}

.JoinTheTeam_underMessage__2_Ml- {
    -webkit-user-select: none;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 146%;

    color: #828a9b;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #111517;
    -webkit-user-select: none;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #111517;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #111517;
}
:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #111517;
}

.JoinTheTeam_files__RDbAV {
    -webkit-user-select: none;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 30px 0;
}

.JoinTheTeam_fileWrap__1RL6e {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid #c6cad2;
    margin: 0 8px 8px 0;
    align-items: center;
    padding: 8px 8px 8px 12px;
    max-width: 150px;
}

.JoinTheTeam_fileWrap__1RL6e img {
    cursor: pointer;
}

.JoinTheTeam_file__2qVnJ {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    margin-right: 8px;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.JoinTheTeam_buttons__1Adf3 {
    position: relative;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.JoinTheTeam_buttonBorder__3D55L {
    -webkit-user-select: none;

    cursor: pointer;
    border: 2px solid #111517;
    width: 49%;
}

@media (hover: hover) {
    .JoinTheTeam_buttonBorder__3D55L:hover {
        background: rgba(17, 21, 23, 0.04);
        transition: all 100ms;
    }
}

.JoinTheTeam_button__1I-Yt {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /* padding: 30px; */

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    white-space: nowrap;

    color: #111517;
}

.JoinTheTeam_buttonBorder__3D55L input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .JoinTheTeam_joinTheTeam__34c7R {
        width: 100%;
        margin-top: 20px;
    }

    .JoinTheTeam_btnCircle__bFg13 {
        display: none;
    }

    .JoinTheTeam_title__2EONH {
        margin-top: 0;
    }

    .JoinTheTeam_title__2EONH span {
        font-size: 39.06px;
    }

    .JoinTheTeam_points__GUB8u {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-right: 9px;
        height: 100%;
    }

    .JoinTheTeam_point__WuQqD {
        width: 16px;
        height: 16px;
        margin: 0;
    }

    .JoinTheTeam_point__WuQqD:first-child {
        margin-bottom: 4px;
    }

    .JoinTheTeam_point__WuQqD:last-child {
        margin: 0;
    }

    .JoinTheTeam_form__2zuEW {
        margin-top: 20px;
    }

    .JoinTheTeam_field__g-OI1 {
        width: 100%;
    }

    .JoinTheTeam_fieldCouple__2zZJ7 {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .JoinTheTeam_fieldCouple__2zZJ7 .JoinTheTeam_field__g-OI1:last-child {
        width: 100%;
        margin-bottom: 40px;
    }

    .JoinTheTeam_buttons__1Adf3 {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .JoinTheTeam_buttonBorder__3D55L {
        width: 100%;
    }

    .JoinTheTeam_button__1I-Yt {
        padding: 19px 16px;
        font-size: 12.8px;
    }

    .JoinTheTeam_buttonBorder__3D55L:first-child {
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .JoinTheTeam_joinTheTeam__34c7R {
        margin-top: 112px;
        width: 100%;
    }

    .JoinTheTeam_btnCircle__bFg13 {
        display: none;
    }

    .JoinTheTeam_title__2EONH {
        margin-top: 0;
    }

    .JoinTheTeam_title__2EONH span {
        font-size: 39.06px;
    }

    .JoinTheTeam_form__2zuEW {
        margin-top: 60px;
    }
}

.JoinTheTeamDropdown_dropdown__3rADr {
    width: 100%;
    border: 2px solid #111517;
    position: relative;
    cursor: pointer;
}

.JoinTheTeamDropdown_dropdown__3rADr.JoinTheTeamDropdown_error__2-1EV {
    border: 2px solid #ff655c;
}

.JoinTheTeamDropdown_dropdown__3rADr.JoinTheTeamDropdown_open__1F_4J {
    border-bottom: none;
    background: #FCFCFD;
}

.JoinTheTeamDropdown_dropdownBody__1yg8G.JoinTheTeamDropdown_open__1F_4J {
    position: absolute;
    left: -2px;
    width: 100%;
    z-index: 5;
    background: #FCFCFD;
    border: 2px solid #111517;
    border-top: none;
}

.JoinTheTeamDropdown_dropdownHeader__T-bK9 {
    padding: 19px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.JoinTheTeamDropdown_error__2-1EV .JoinTheTeamDropdown_dropdownHeader__T-bK9 {
    color: #ff655c;
}

.JoinTheTeamDropdown_dropdownButton__18rJm {
    font-family: Manrope;
    font-size: 27px;

    transition: -webkit-transform 0.3s;

    transition: transform 0.3s;

    transition: transform 0.3s, -webkit-transform 0.3s;
}

.JoinTheTeamDropdown_dropdownButton__18rJm.JoinTheTeamDropdown_open__1F_4J {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.JoinTheTeamDropdown_dropdownItem__pcDEx {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px;
}

@media (hover: hover) {
    .JoinTheTeamDropdown_dropdownItem__pcDEx:hover {
        background: #ff655c;
    }

    .JoinTheTeamDropdown_dropdownItem__pcDEx:hover span {
        color: #fdfdfd;
    }
}

.JoinTheTeamDropdown_dropdownItemDot__3RoJu {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    color: #494e5b;
    transition: all 0.1s ease-in-out;
}

.JoinTheTeamDropdown_path__-A9LG {
    stroke: #111517;
  }

.FlashMessage_success__s5Il5 {
    position: fixed;
    width: 100vw;
    height: 76px;
    background: #29c269;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    z-index: 999;
}

.FlashMessage_failed__1OKDO {
    position: fixed;
    width: 100vw;
    height: 100px;
    background: #ff655c;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    z-index: 999;
}

.FlashMessage_wrap__34e1D {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
}

.FlashMessage_row__-kkih {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.FlashMessage_row__-kkih span {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #fdfdfd;
}

.UploadCV_button__1WVlr {
  -webkit-user-select: none;

  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  
  border: 2px solid #111517;
  width: 48%;
  padding: 20px 0;
  
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #111517;
}

@media (max-width: 500px) {
  .UploadCV_button__1WVlr {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .UploadCV_large__EZ39F {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (hover: hover) {
  .UploadCV_button__1WVlr:hover {
      background: rgba(17, 21, 23, 0.04);
      transition: all 100ms;
  }
}

.UploadCV_light__1KMGF {
  border-color: #FDFDFD;
  color: #FDFDFD;
}

.UploadCV_button__1WVlr input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.UploadCV_button__1WVlr span {
  margin-left: 20px;
}
.UploadCV_button__1WVlr img {
  margin-right: 20px;
}
.SendEmail_button__2ElBu {
  -webkit-user-select: none;
  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  
  background: transparent;
  border: 2px solid #111517;
  width: 48%;
  padding: 20px 0;
  
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #111517;
}

@media (max-width: 500px) {
  .SendEmail_button__2ElBu {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .SendEmail_large__1sK94 {
    width: 100%;
  }
}

@media (hover: hover) {
  .SendEmail_button__2ElBu:hover {
      background: rgba(17, 21, 23, 0.04);
      transition: all 100ms;
  }
}

.SendEmail_light__2LoMf {
  border-color: #FDFDFD;
  color: #FDFDFD;
}

.SendEmail_button__2ElBu input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.SendEmail_button__2ElBu span {
  margin-left: 20px;
}
.SendEmail_button__2ElBu img {
  margin-right: 20px;
}

.ScrollToTopButton_scrollToTop__3Ae0i {
  position: fixed;
  right: 40px;
  bottom: 10vh;
  background: rgba(252, 252, 253, 0.9);
  border: 1px solid #dddfe4;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  cursor: pointer;
  z-index: 5;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .ScrollToTopButton_scrollToTop__3Ae0i {
    right: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ScrollToTopButton_scrollToTop__3Ae0i {
    right: 20px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .ScrollToTopButton_scrollToTop__3Ae0i {
    right: 30px;
  }
}

.ScrollToTopButton_buttonContent__3tzQg {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 44px;
  height: 143px;
  margin: 5px 5px 0;
}

.ScrollToTopButton_btnImg__1u0q0 {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  max-width: 30px;
  max-height: 30px;
  margin: 5px auto 0;
}

.ScrollToTopButton_buttonContent__3tzQg span {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  line-height: 138%;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  margin-top: 65px;
  margin-right: 1px;

  color: #111517;

  -webkit-transform: rotate(-90deg);

          transform: rotate(-90deg);
}

@media (hover: hover) {
  .ScrollToTopButton_scrollToTop__3Ae0i:hover {
    background: #f4f4f6;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .ScrollToTopButton_scrollToTop__3Ae0i {
    bottom: 5vh;
  }

  .ScrollToTopButton_buttonContent__3tzQg {
    width: 38px;
    height: 119px;
  }

  .ScrollToTopButton_buttonContent__3tzQg span {
    font-size: 12.8px;
    margin-top: 53px;
  }
}

.UsefulInfo_container__2wM09 {
  width: 100%;
  height: calc(100vh - 100px);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.UsefulInfo_swiper__td-8h {
  width: 100%;
  max-width: 1050px;
  height: 600px;
  max-height: 600px;
}

.UsefulInfo_swiperSlide__as1Ct {
  font-size: 18px;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .UsefulInfo_container__2wM09 {
    height: 450px;
    margin-top: 100px;
  }
}

.Slides_container__Tr3EG {
  width: 100%;
  height: 100%;
  margin: 0 50px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(105.77deg, #FCFCFD 13%, #F4F4F6 99.58%);
  box-shadow: 0px -5px 52px rgba(0, 0, 0, 0.11);
}

.Slides_header__6qMzc {
  width: 100%;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Slides_header__6qMzc p {
  padding: 0 10px;
  font-size: 48px;
  font-family: "Archivo Black", sans-serif;
  font-weight: bold;

  background-color: red;
  background-image: linear-gradient(45deg, #539FF8, #FFEB64);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Slides_headerLine__3D5Mz {
  background: #ECECEF;
  border-radius: 6px;
  height: 6px;
}

.Slides_headerLine__3D5Mz:first-child {
  width: 75%;
  margin-left: 20px;
}
.Slides_headerLine__3D5Mz:last-child {
  width: 20%;
  margin-right: 20px;
}

.Slides_body__jb3U6 {
  position: relative;
  height: calc(100% - 50px);
  overflow: hidden;
  padding: 0 20px;
}

.Slides_heartIcon__2Q1eB {
  position: absolute;
  top: 30px;
  right: 70px;
  width: 100px;
  height: 100px;
}

.Slides_blueBlur__1mwsa {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 200px;
  height: 400px;
  background: #539FF8;
  -webkit-filter: blur(110px);
          filter: blur(110px);
  -webkit-transform: rotate(33.42deg);
          transform: rotate(33.42deg);

  z-index: 1;
}

.Slides_yellowBlur__33k6Q {
  position: absolute;
  bottom: -200px;
  left: 300px;
  width: 200px;
  height: 350px;
  background: #FFEB64;
  -webkit-filter: blur(110px);
          filter: blur(110px);
  -webkit-transform: rotate(75.38deg);
          transform: rotate(75.38deg);

  z-index: 1;
}

.Slides_slide1Title__w5O6o {
  position: absolute;
  bottom: 80px;
  left: 80px;
  text-align: left;
  
  font-style: normal;
  font-weight: 750;
  font-size: 85px;
  color: #111517;

  z-index: 10;
}

.Slides_title__359a1 {
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.02em;

  color: #111517;

  margin-bottom: 50px;
}

.Slides_subtitle__3dPnF {
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;

  color: #539FF8;
}

.Slides_table__2opbK {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Slides_column__Er1gf {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Slides_row__1md5I {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 15px;
}

.Slides_shortRow__1Ol05 {
  width: 85%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 15px;
}

.Slides_row__1md5I a {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  color: #338BF2;
}

.Slides_row__1md5I span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;

  color: grey;
}

.Slides_shortRow__1Ol05 a {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  color: #338BF2;
}

.Slides_shortRow__1Ol05 span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;

  color: grey;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .Slides_container__Tr3EG {
    margin: 0;
  }

  .Slides_headerLine__3D5Mz:first-child {
    width: 45%;
    margin-left: 8px;
  }
  .Slides_headerLine__3D5Mz:last-child {
    width: 45%;
    margin-right: 8px;
  }

  .Slides_heartIcon__2Q1eB {
    position: absolute;
    top: 30px;
    right: 3px;
    width: 60px;
    height: 60px;
  }

  .Slides_slide1Title__w5O6o {
    left: 40px;
    bottom: 180px;
    font-size: 40px;
  }

  .Slides_subtitle__3dPnF {
    font-size: 16px;
  }

  .Slides_title__359a1 {
    font-size: 32px;
  }

  .Slides_row__1md5I a {
    font-size: 16px;
  }
  
  .Slides_row__1md5I span {
    font-size: 16px;
  }
  
  .Slides_shortRow__1Ol05 a {
    font-size: 16px;
  }

  .Slides_shortRow__1Ol05 span {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .Slides_title__359a1 {
    font-size: 30px;
    width: 80%;
  }

  .Slides_row__1md5I a {
    font-size: 14px;
  }
  
  .Slides_row__1md5I span {
    font-size: 14px;
  }
}

.LetsTalk_container__1w_Rz {
  display: grid;
  grid-template-columns: 50% 50%;
}

.LetsTalk_title__18Tyf {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 43.95px;
  line-height: 118%;
  color: #111517;
}

.LetsTalk_leftside__3t0ps {
  padding: 100px 60px 100px 0;
  border-right: 1px solid rgba(17, 21, 23, 0.2);
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.LetsTalk_rightsideBottom__2p_pD img {
  pointer-events: none;
}

.LetsTalk_buttons__1VBRL {
  grid-gap: 20px;
  gap: 20px;
}
.LetsTalk_buttons__1VBRL button,
.LetsTalk_buttons__1VBRL div {
  width: 100%;
}

.LetsTalk_rightsideTop__1YmSs,
.LetsTalk_rightsideBottom__2p_pD {
  padding-left: 60px;
  grid-column-start: 2;
  grid-column-end: 3;
}

.LetsTalk_rightsideTop__1YmSs {
  padding-top: 100px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(17, 21, 23, 0.2);
  width: 100%;
}

.LetsTalk_rightsideBottom__2p_pD {
  padding-top: 60px;
  padding-bottom: 100px;
}

.LetsTalk_text__1vWQj {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #494e5b;
  width: 90%;
}

.LetsTalk_wrap__2kIce {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 48px;
}

.LetsTalk_left__20xoY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.LetsTalk_form__1ml_U {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.LetsTalk_fieldCouple__1K3po {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 48px;
}

.LetsTalk_field__2m8vL {
  width: 100%;
}

.LetsTalk_error__27RDz {
  background-image: url(/static/media/Warning.dd51008b.svg);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: bottom 0 right 16px;
  transition: 0.3s;
}

textarea {
  background-color: #f4f4f6;
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  resize: none;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

textarea::-webkit-input-placeholder {
  text-align: left;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

textarea:-ms-input-placeholder {
  text-align: left;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

textarea::placeholder {
  text-align: left;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

input[type='text'] {
  background-color: #f4f4f6;
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.LetsTalk_underMessage__3V98Z {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 146%;
  color: #828a9b;
}

.LetsTalk_contactTitle__2kgVe {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 22.5px;
  color: #111517;
}

.LetsTalk_contactInfos__R8t0J {
  padding-top: 3%;
}

@media (hover: hover) {
  .LetsTalk_link__2ED1c:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition-duration: 150ms;
  }

  .LetsTalk_link__2ED1c:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition-duration: 150ms;
  }
}

.LetsTalk_repBlock__29igX {
  display: -webkit-flex;
  display: flex;
}

.LetsTalk_repPhoto__1MP2m {
  width: 128px;
  height: 128px;
  border-radius: 256px;
  margin-right: 32px;
}

.LetsTalk_repName__3n3my,
.LetsTalk_repPosition__1HeJT,
.LetsTalk_repContact__8IZj9 {
  font-family: Manrope;
  padding: 0;
  margin: 0;
}

.LetsTalk_repName__3n3my {
  font-weight: 600;
  font-size: 22.5px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #111517;
}

.LetsTalk_repPosition__1HeJT {
  font-weight: 500;
  font-size: 14.4px;
  letter-spacing: 0.02em;
  color: #494e5b;
  padding-bottom: 22px;
}

.LetsTalk_repContact__8IZj9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-weight: 500;
  font-size: 14.4px;
  letter-spacing: 0.02em;
  color: #494e5b;
}

.LetsTalk_repContact__8IZj9:first-of-type {
  margin-bottom: 8px;
}

.LetsTalk_repContact__8IZj9 a {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #111517;
  margin-left: 8px;
}

.LetsTalk_repContact__8IZj9 img {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.LetsTalk_hrGray__xDpjY {
  background: #dddfe4;
  height: 1px;
  border: none;
  width: 100%;
}

.LetsTalk_hrGrayMobile__3opSS {
  display: none;
}

.LetsTalk_hrBlack__3699A {
  height: 2px;
  left: 650px;
  top: 526px;
  border: none;
  background: #111517;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #111517;
  -webkit-user-select: none;
  user-select: none;
}

input:-moz-placeholder,
input::-moz-placeholder {
  color: #111517;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #111517;
}

.LetsTalk_checkBoxes__5OOZp {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 40px;
}

.LetsTalk_checkBox__1EH52 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 24px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #494e5b;
}

.LetsTalk_checkBox__1EH52:last-child {
  margin-top: 18px;
}

.LetsTalk_errorCheckBox__2TsHZ .LetsTalk_checkBoxContainer__g3FmA .LetsTalk_checkmark__12IJX {
  border: 2px solid #ff655c;
  /* filter: drop-shadow(0px 0px 12px rgba(255, 101, 92, 0.5)); */
}

.LetsTalk_errorCheckBox__2TsHZ .LetsTalk_checkBoxContainer__g3FmA {
  color: #ff655c;
  /* text-shadow: 0px 0px 12px rgba(255, 101, 92, 0.5); */
}

.LetsTalk_vl__2tvIW {
  border-left: 1px solid #dddfe4;
  margin-left: -10px;
}

.LetsTalk_checkBoxContainer__g3FmA {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.LetsTalk_checkBoxContainer__g3FmA input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.LetsTalk_checkmark__12IJX {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f4f4f6;
  border: 2px solid #111517;
}

/* On mouse-over, add a grey background color */

.LetsTalk_checkBoxContainer__g3FmA:hover input ~ .LetsTalk_checkmark__12IJX {
  background-color: #f4f4f6;
}

/* When the checkbox is checked, add a blue background */

.LetsTalk_checkBoxContainer__g3FmA input:checked ~ .LetsTalk_checkmark__12IJX {
  background-color: #111517;
}

/* Create the checkmark/indicator (hidden when not checked) */

.LetsTalk_checkmark__12IJX:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.LetsTalk_checkBoxContainer__g3FmA input:checked ~ .LetsTalk_checkmark__12IJX:after {
  display: block;
}

/* Style the checkmark/indicator */

.LetsTalk_checkBoxContainer__g3FmA .LetsTalk_checkmark__12IJX:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #f4f4f6;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.LetsTalk_files__1GnpW {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 30px 0;
}

.LetsTalk_fileWrap__1hWbQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #c6cad2;
  margin: 0 8px 8px 0;
  align-items: center;
  padding: 8px 8px 8px 12px;
  max-width: 150px;
}

.LetsTalk_fileWrap__1hWbQ img {
  cursor: pointer;
}

.LetsTalk_file__SpLTm {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  margin-right: 8px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .LetsTalk_files__1GnpW {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .LetsTalk_fileWrap__1hWbQ {
    width: 36%;
    max-width: 80%;
    margin: 0 0 8px 0;
    margin-right: 16px;
  }
  .LetsTalk_file__SpLTm {
    max-width: 80%;
  }
}

.LetsTalk_buttons__1VBRL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.LetsTalk_lineStyle__eaYvb {
  height: 1px;
  width: 100%;
  border: none;
  background: #dddfe4;
  margin-bottom: 10%;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .LetsTalk_container__1w_Rz {
    grid-template-columns: 1fr;
  }

  .LetsTalk_leftside__3t0ps {
    border-right: none;
    border-bottom: 1px solid rgba(17, 21, 23, 0.2);
    width: 100%;
    padding: 0;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 20px;
  }

  .LetsTalk_rightsideTop__1YmSs,
  .LetsTalk_rightsideBottom__2p_pD {
    padding: 0;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .LetsTalk_rightsideBottom__2p_pD {
    padding-top: 20px;
  }

  .LetsTalk_repBlock__29igX {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 20px;
  }

  .LetsTalk_repPhoto__1MP2m {
    width: 100px;
    height: 100px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .LetsTalk_lineStyle__eaYvb {
    width: 100%;
    margin-bottom: 10%;
  }
  .LetsTalk_title__18Tyf {
    width: 100%;
    line-height: 110%;
    font-size: 48.83px;
    margin-bottom: 0;
  }
  .LetsTalk_text__1vWQj {
    width: 100%;
    margin-top: 10%;
  }
  .LetsTalk_wrap__2kIce {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .LetsTalk_form__1ml_U {
    width: 100%;
  }
  .LetsTalk_left__20xoY {
    width: 100%;
  }
  .LetsTalk_fieldCouple__1K3po {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 0;
  }
  .LetsTalk_field__2m8vL {
    padding-bottom: 5%;
  }
  .LetsTalk_vl__2tvIW {
    display: none;
  }
  .LetsTalk_checkBoxes__5OOZp {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .LetsTalk_checkBox__1EH52 {
    width: 100%;
    margin-bottom: 3%;
    font-size: 12.8px;
  }
  .LetsTalk_buttons__1VBRL {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .LetsTalk_buttons__1VBRL button,
  .LetsTalk_buttons__1VBRL div {
    width: auto;
  }
  .LetsTalk_contactTitle__2kgVe {
    width: 100%;
  }
  .LetsTalk_contactInfos__R8t0J {
    width: 100%;
    margin-bottom: 3%;
  }
  .LetsTalk_repContact__8IZj9 img {
    margin-right: 2%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .LetsTalk_container__1w_Rz {
    grid-template-columns: 40% 60%;
  }

  .LetsTalk_leftside__3t0ps {
    padding: 40px 0;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    border-right: none;
    border-bottom: 1px solid rgba(17, 21, 23, 0.2);
  }

  .LetsTalk_rightsideTop__1YmSs {
    padding: 44px 20px 44px 0;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 3;
    border-right: 1px solid rgba(17, 21, 23, 0.2);
    border-bottom: none;
  }

  .LetsTalk_rightsideBottom__2p_pD {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 44px 0 44px 44px;
  }

  .LetsTalk_repBlock__29igX {
    padding-bottom: 20px;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .LetsTalk_repName__3n3my {
    margin: 15px 0 10px;
  }

  .LetsTalk_lineStyle__eaYvb {
    width: 100%;
    margin-bottom: 10%;
  }
  .LetsTalk_title__18Tyf {
    line-height: 110%;
    font-size: 48.83px;
    margin: 0;
  }
  .LetsTalk_text__1vWQj {
    width: 100%;
    margin-top: 40px;
  }
  .LetsTalk_wrap__2kIce {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .LetsTalk_form__1ml_U {
    width: 100%;
  }
  .LetsTalk_left__20xoY {
    width: 100%;
  }
  .LetsTalk_fieldCouple__1K3po {
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-bottom: 0;
  }
  .LetsTalk_field__2m8vL {
    padding-bottom: 5%;
  }
  .LetsTalk_vl__2tvIW {
    display: none;
  }
  .LetsTalk_checkBoxes__5OOZp {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .LetsTalk_checkBox__1EH52 {
    width: 100%;
    margin-bottom: 28px;
    font-size: 12.8px;
  }
  .LetsTalk_buttons__1VBRL {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .LetsTalk_buttons__1VBRL button,
  .LetsTalk_buttons__1VBRL div {
    width: auto !important;
  }
  .LetsTalk_contactTitle__2kgVe {
    width: 100%;
  }
  .LetsTalk_contactInfos__R8t0J {
    width: 100%;
    margin-bottom: 54px;
  }
  .LetsTalk_contactInfos__R8t0J span {
    margin-left: 0;
  }
  .LetsTalk_repContact__8IZj9 {
    padding: 2% 0;
  }
  .LetsTalk_repContact__8IZj9 img {
    margin-right: 2%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .LetsTalk_container__1w_Rz {
    padding: 0 5%;
  }

  .LetsTalk_leftside__3t0ps {
    padding: 100px 60px 100px 0;
  }

  .LetsTalk_rightsideTop__1YmSs,
  .LetsTalk_rightsideBottom__2p_pD {
    padding-bottom: 60px;
    padding-left: 60px;
  }

  .LetsTalk_rightsideTop__1YmSs {
    padding-top: 100px;
  }

  .LetsTalk_rightsideBottom__2p_pD {
    padding-top: 60px;
  }

  .LetsTalk_repBlock__29igX {
    padding-bottom: 20px;
  }

  .LetsTalk_checkBox__1EH52 {
    font-size: 14.4px;
    line-height: 105%;
  }
  .LetsTalk_vl__2tvIW {
    display: none;
  }
}

.QuestionToHrForm_formContainer__ALMeI {
  font-family: Manrope;
  font-style: normal;
  color: #fdfdfd;
  --title-font: 43.95px;
  --title-laptop-font: 38px;
  --title-tablet-font: 39px;
  --title-mobile-font: 31.25px;
  --main-font: 14.4px;
  --main-tablet-font: 12.8px;
  --main-color: #fdfdfd;

  display: grid;
  grid-template-columns: 50% 50%;
}

.QuestionToHrForm_areaItem1__2Xz57 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  border-right: 1px solid rgba(253, 253, 253, 0.2);

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 100px 60px 100px 0;
}

.QuestionToHrForm_title__1LfFW {
  font-size: var(--title-font);
  font-weight: 500;
  line-height: 118%;
}

.QuestionToHrForm_form__t0I_2 {
  margin-top: 48px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.QuestionToHrForm_form__t0I_2 input[type='text'] {
  background-color: #ff655c;
  color: var(--main-color);
}

.QuestionToHrForm_form__t0I_2 input[type='text']::-webkit-input-placeholder {
  color: var(--main-color);
}

.QuestionToHrForm_form__t0I_2 input[type='text']:-ms-input-placeholder {
  color: var(--main-color);
}

.QuestionToHrForm_form__t0I_2 input[type='text']::placeholder {
  color: var(--main-color);
}

.QuestionToHrForm_itemsCouple__3gv8R {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.QuestionToHrForm_itemsCouple__3gv8R .QuestionToHrForm_field__1j010 {
  width: 49%;
}

.QuestionToHrForm_error__1jxMp {
  background-image: url(/static/media/WarningWhite.7b941dcd.svg);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: bottom 0 right 16px;
  transition: 0.3s;
}

.QuestionToHrForm_message__2h1TB {
  margin-top: 20px;
}

.QuestionToHrForm_field__1j010 hr {
  height: 2px;
  background: var(--main-color);
  border: none;
}

.QuestionToHrForm_areaItem2__B72cJ {
  grid-column-start: 2;
  grid-column-end: 3;
  border-bottom: 1px solid rgba(253, 253, 253, 0.2);

  padding: 100px 0 100px 60px;
  width: 100%;
}

.QuestionToHrForm_areaItem3__W7VUe {
  grid-column-start: 2;
  grid-column-end: 3;

  padding: 60px 0 100px 60px;
}

.QuestionToHrForm_row__9NyAN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.QuestionToHrForm_image__1jFvs {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  margin-right: 30px;
}

.QuestionToHrForm_column__1v-R2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.QuestionToHrForm_subtitle__8FCHp {
  font-size: 22.5px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 105%;
  margin: 15px 0 10px 0;
}

.QuestionToHrForm_text__3jb8b {
  font-size: var(--main-font);
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
}

.QuestionToHrForm_link__1mgfw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 9px 11px 9px 9px;

  border: 1px solid rgba(253, 253, 253, 0.2);
  box-sizing: border-box;
  border-radius: 24px;

  font-size: var(--main-font);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--main-color);
  text-decoration: none;
}

.QuestionToHrForm_paragraph__1Re5Z {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffeceb;
  max-width: 90%;
}

@media (hover: hover) {
  .QuestionToHrForm_link__1mgfw:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.3s;
  }
}

.QuestionToHrForm_link__1mgfw img {
  margin-right: 5px;
}

.QuestionToHrForm_link__1mgfw:first-child {
  margin-right: 15px;
}

.QuestionToHrForm_underMessage__RWWZy {
  font-size: 12px;
  line-height: 146%;
}

.QuestionToHrForm_files__2F6oy {
  -webkit-user-select: none;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 30px 0;
}

.QuestionToHrForm_fileWrap__2yBfH {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid rgba(253, 253, 253, 0.2);
  margin: 0 8px 8px 0;
  align-items: center;
  padding: 8px 8px 8px 12px;
  max-width: 150px;
}

.QuestionToHrForm_fileWrap__2yBfH img {
  cursor: pointer;
}

.QuestionToHrForm_file__9NfbO {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  margin-right: 8px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.QuestionToHrForm_buttons__2aM6G {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .QuestionToHrForm_areaItem1__2Xz57 {
    padding: 60px 30px 60px 0;
  }

  .QuestionToHrForm_title__1LfFW {
    font-size: var(--title-laptop-font);
  }

  .QuestionToHrForm_info__12ZsK {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .QuestionToHrForm_areaItem2__B72cJ {
    padding: 60px 0 60px 30px;
  }

  .QuestionToHrForm_areaItem3__W7VUe {
    padding: 60px 0 60px 30px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .QuestionToHrForm_formContainer__ALMeI {
    grid-template-columns: 60% 40%;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .QuestionToHrForm_formContainer__ALMeI {
    grid-template-columns: 40% 60%;
  }

  .QuestionToHrForm_areaItem1__2Xz57 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    border-right: none;
    border-bottom: 1px solid rgba(253, 253, 253, 0.2);
    padding: 40px 0;
  }

  .QuestionToHrForm_title__1LfFW {
    font-size: var(--title-tablet-font);
  }

  .QuestionToHrForm_areaItem2__B72cJ {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 3;

    border-right: 1px solid rgba(253, 253, 253, 0.2);
    padding: 44px 0;
  }

  .QuestionToHrForm_areaItem3__W7VUe {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    padding: 44px 0 44px 20px;
  }

  .QuestionToHrForm_image__1jFvs {
    width: 100px;
    height: 100px;
    margin-right: 15px;
  }

  .QuestionToHrForm_subtitle__8FCHp {
    font-size: 20px;
    margin: 10px 0 5px 0;
  }

  .QuestionToHrForm_text__3jb8b {
    font-size: var(--main-tablet-font);
    margin-bottom: 10px;
  }

  .QuestionToHrForm_link__1mgfw:first-child {
    margin-right: 10px;
    font-size: var(--main-tablet-font);
  }
}

@media (min-width: 501px) and (max-width: 650px) {
  .QuestionToHrForm_info__12ZsK {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .QuestionToHrForm_title__1LfFW:last-child {
    font-size: 30px;
  }

  .QuestionToHrForm_areaItem2__B72cJ {
    padding-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .QuestionToHrForm_areaItem3__W7VUe {
    padding-top: 20px;
  }
}

@media (min-width: 1px) and (max-width: 500px) {
  .QuestionToHrForm_formContainer__ALMeI {
    grid-template-columns: 1fr;
  }

  .QuestionToHrForm_areaItem1__2Xz57 {
    grid-column-start: 1;
    grid-column-end: 3;

    border-right: none;
    border-bottom: 1px solid rgba(253, 253, 253, 0.2);
    padding: 24px 0;
  }

  .QuestionToHrForm_areaItem2__B72cJ {
    grid-column-start: 1;
    grid-column-end: 3;

    padding: 20px 0;
    text-align: start;
  }

  .QuestionToHrForm_areaItem3__W7VUe {
    grid-column-start: 1;
    grid-column-end: 3;

    padding: 20px 0;
  }

  .QuestionToHrForm_title__1LfFW {
    font-size: var(--title-mobile-font);
  }

  .QuestionToHrForm_paragraph__1Re5Z {
    max-width: unset;
  }

  .QuestionToHrForm_image__1jFvs {
    width: 100px;
    height: 100px;
  }

  .QuestionToHrForm_subtitle__8FCHp {
    font-size: 20px;
    margin: 5px 0 10px 0;
  }

  .QuestionToHrForm_text__3jb8b {
    font-size: var(--main-tablet-font);
    margin-bottom: 10px;
  }

  .QuestionToHrForm_link__1mgfw:first-child {
    margin-right: 8px;
  }

  .QuestionToHrForm_buttons__2aM6G {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .QuestionToHrForm_buttons__2aM6G button,
  .QuestionToHrForm_buttons__2aM6G div {
    width: auto;
  }
}

@media (max-width: 400px) {
  .QuestionToHrForm_info__12ZsK {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .QuestionToHrForm_image__1jFvs {
    margin-bottom: 15px;
    margin-right: 0;
  }

  .QuestionToHrForm_areaItem3__W7VUe {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.QuestionToHrDropdown_dropdown__D9pNf {
  width: 100%;
  border: 2px solid #ff655c;
  position: relative;
  cursor: pointer;
  background: #FDFDFD;
  margin-top: 32px;
}

.QuestionToHrDropdown_dropdown__D9pNf.QuestionToHrDropdown_open__279Zi {
  border-bottom: none;
}

.QuestionToHrDropdown_dropdownBody__t0Cad.QuestionToHrDropdown_open__279Zi {
  position: absolute;
  left: -2px;
  width: 100%;
  z-index: 5;
  background: #FCFCFD;
  border: 2px solid #ff655c;
  border-top: none;
}

.QuestionToHrDropdown_dropdownHeader__2rH3h {
  padding: 19px 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;

  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  line-height: 138%;

  letter-spacing: 0.24em;
  text-transform: uppercase;

  color: #ff655c;
}

.QuestionToHrDropdown_error__26NiO .QuestionToHrDropdown_dropdownHeader__2rH3h {
  color: #ff655c;
}

.QuestionToHrDropdown_dropdownButton__1dxBX {
  font-family: Manrope;
  font-size: 27px;

  transition: -webkit-transform 0.3s;

  transition: transform 0.3s;

  transition: transform 0.3s, -webkit-transform 0.3s;
}

.QuestionToHrDropdown_dropdownButton__1dxBX.QuestionToHrDropdown_open__279Zi {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.QuestionToHrDropdown_dropdownItem__Afcd7 {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px;
}

@media (hover: hover) {
  .QuestionToHrDropdown_dropdownItem__Afcd7:hover {
      background: #EBEDF2;
  }
}

.QuestionToHrDropdown_dropdownItemDot__3zYdz {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  line-height: 156%;
  letter-spacing: 0.02em;

  color: #111517;
  transition: all 0.1s ease-in-out;
}

.QuestionToHrDropdown_path__2OVIx {
  stroke: #ff655c;
}

@media (min-width: 501px) and (max-width: 768px) {
  .QuestionToHrDropdown_dropdown__D9pNf {
    margin-top: 30px;
  }
}
