.container {
    overflow-x: visible;
    padding-top: 100px;
}

.title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.title span:first-child {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;

    color: #111517;
}

.title span:last-child {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.4em;
    text-transform: uppercase;

    color: #494e5b;
}

.buttonsLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 60px;
}

.buttons {
    height: 46px;
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: row;
}

.btn {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;
    margin-left: 16px;

    background-color: #f4f4f6;
    color: #111517;

    white-space: nowrap;
    border: none;
    border: 1px solid transparent;
    padding: 10px;
    transition: all 300ms;
}

.buttonWrap {
    justify-content: center;
    border: 1px solid transparent;
}

@media (hover: hover) {
    .btn:hover {
        border: 1px solid #c6cad2;
    }

    .active:hover {
        border: 1px solid transparent;
    }

    .pageLink:hover {
        border: 1px solid #c6cad2;
    }

    .pageLink.active:hover {
        border: 1px solid transparent;
    }
}

.active {
    background: #ff655c;
    color: #fdfdfd;
}

.pageLink.active {
    background: #ff655c;
    color: #fdfdfd;
}

.pageLink {
    cursor: pointer;
    margin-left: 16px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;
    height: 100%;
    display: flex;
    align-items: center;

    color: #111517;

    border: 1px solid transparent;
    background-color: #f4f4f6;
    padding: 18px 20px;
    transition: all 300ms;
}

.teammates {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.teammateWrap {
    border: 1px solid #c6cad2;
    box-sizing: border-box;
    width: 23%;
    margin-bottom: 40px;
    margin-right: 13px;
}

@media (hover: hover) {
    .teammateWrap:hover {
        border-bottom: 1px solid #ff655c;
    }
}

.teammate {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.teammateImg {
    width: 100%;
    height: 100%;
}

.teammate img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.teammate span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin: 0;

    color: #111517;
    padding-top: 50px;
}

.teammate p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    letter-spacing: 0.01em;
    margin: 0;

    color: #828a9b;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
    .container {
        display: flex;
        position: relative;
        flex-direction: column;
        padding-top: 2%;
        padding-bottom: 8%;
    }

    .title span:first-child {
        margin-top: 40px;
        font-size: 48.83px;
        line-height: 98%;
    }

    .title span:last-child {
        display: none;
    }

    .title {
        justify-content: center;
    }

    .buttonsLine {
        max-width: 100%;
        flex-direction: column;
        margin: 30px 0 30px 0;
    }

    .buttons {
        width: 98%;
        overflow-x: auto;
        margin-bottom: 30px;
    }

    .btn {
        white-space: nowrap;
    }

    .teammates {
        width: 100%;
        justify-content: space-between;
    }

    .teammateWrap {
        width: 48%;
        margin: 2% 0;
    }

    .teammate span {
        font-size: 16px;
        padding-top: 10%;
    }

    .teammate p {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 481px) and (max-width: 1000px) {
    .container {
        display: flex;
        position: relative;
        flex-direction: column;
        padding-top: 2%;
        padding-bottom: 8%;
    }

    .title span:first-child {
        font-size: 48.83px;
        line-height: 98%;
    }

    .title {
        margin-top: 60px;
        width: 100%;
        justify-content: space-between;
    }

    .buttonsLine {
        max-width: 100%;
        flex-direction: column;
        margin: 55px 0 30px 0;
    }

    .buttons {
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
    }

    .btn {
        white-space: nowrap;
    }

    .teammates {
        width: 100%;
        justify-content: space-between;
    }

    .teammateWrap {
        width: 48%;
        margin: 2% 0;
    }

    .teammate span {
        font-size: 16px;
        padding-top: 10%;
    }

    .teammate p {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
    .container {
        padding-top: 60px;
    }
}
