.promo {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 100px);
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .promo {
        min-height: calc(100vh - 68px);
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .promo {
        min-height: calc(100vh - 98px);
    }
}

.contentWrap {
    display: flex;
    flex-direction: column;
    /* margin-top: 5%; */
    margin-left: 20%;
}

.points {
    display: flex;
    flex-direction: row;
    margin-top: 10%;
}

.point {
    width: 20px;
    height: 20px;
    background: #111517;
    border-radius: 50%;
    margin-right: 12px;
}

.points div:nth-child(3) .point {
    background: #ff655c;
}

.text span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    letter-spacing: -0.02em;
    position: relative;
    line-height: 100%;
    z-index: 6;

    color: #111517;
}

.text p {
    width: 40%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    position: relative;
    z-index: 6;

    color: #111517;
}

.text2 {
    margin-left: 5%;
}

.promoImg1 {
    position: absolute;
    bottom: -45%;
    left: -15%;
    overflow: hidden;
    animation-duration: 1.3s;
    animation-name: picture1;
    z-index: 5;
}

.promoImg2 {
    position: absolute;
    left: 35%;
    bottom: 0%;
    animation-duration: 1.3s;
    animation-name: picture2;
    z-index: 5;
}

.promoImg3 {
    right: 0%;
    position: absolute;
    bottom: 0%;
    animation-duration: 1.3s;
    animation-name: picture3;
    z-index: 5;
}

@media (hover: hover) {
    .promoImg1:hover {
        background-image: url('../../../images/SmartphoneLeftHover.svg');
    }
    .promoImg2:hover {
        background-image: url('../../../images/SmartphoneCenterHover.svg');
    }
    .promoImg3:hover {
        background-image: url('../../../images/LaptopRightHover.svg');
    }
}

@media screen and (min-width: 1731px) {
    @keyframes picture1 {
        from {
            bottom: -60%;
            left: -25%;
        }

        to {
            bottom: -45%;
            left: -15%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            left: 35%;
            bottom: 0%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -20%;
            right: -9%;
        }

        to {
            bottom: 0%;
            right: 0%;
        }
    }
}

@media screen and (min-width: 1px) and (max-width: 374px) {
    .contentWrap {
        margin: 0 4%;
    }

    .text {
        margin-top: 5%;
    }

    .text span {
        line-height: 100%;
        font-size: 48px;
    }

    .text p {
        width: 100%;
        font-size: 24.96px;
    }

    .text2 {
        margin-left: 0%;
    }
}

@media screen and (min-width: 1px) and (max-width: 454px) {
    .promoImg1 {
        max-width: 190px;
        bottom: -12%;
    }
    .promoImg2 {
        left: 55%;
        max-width: 300px;
    }
    .promoImg3 {
        display: none;
    }
}

@media screen and (min-width: 455px) and (max-width: 530px) {
    .promoImg1 {
        max-width: 222px;
        bottom: -12%;
    }
    .promoImg2 {
        left: 50%;
        max-width: 390px;
    }
    .promoImg3 {
        display: none;
    }
}

@media screen and (min-width: 531px) and (max-width: 640px) {
    .promoImg1 {
        max-width: 250px;
        bottom: -12%;
        left: -5%;
    }
    .promoImg2 {
        left: 58%;
    }
    .promoImg3 {
        display: none;
    }
}

@media screen and (min-width: 641px) and (max-width: 767px) {
    .promoImg1 {
        bottom: -12%;
        left: 0;
    }
    .promoImg2 {
        left: 62%;
    }
    .promoImg3 {
        display: none;
    }
}

/* @media (max-width: 815px) and (orientation: landscape ) {
    .promoImg1 {
        display: none;
    }
    .promoImg2 {
        display: none;
    }
    .promoImg3 {
        display: none;
    }
} */

@media (max-height: 750px) {
    .promoImg1 {
        display: none;
    }
    .promoImg2 {
        display: none;
    }
    .promoImg3 {
        display: none;
    }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
    .contentWrap {
        margin: 0 4%;
    }

    .text {
        margin-top: 5%;
    }

    .text span {
        line-height: 100%;
        font-size: 52.2px;
    }

    .text p {
        width: 100%;
        font-size: 24.96px;
    }

    .text2 {
        margin-left: 0%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .text {
        width: 80%;
    }

    .text2 {
        margin-left: 10%;
    }

    .text span {
        font-size: 66.2px;
        line-height: 105%;
    }

    .text p {
        font-size: 16px;
        width: 100%;
    }

    .promoImg1 {
        bottom: -7%;
        left: -2%;
    }
    .promoImg2 {
        bottom: -9%;
    }
    .promoImg3 {
        bottom: -4%;
        right: -5%;
    }

    @keyframes picture1 {
        from {
            bottom: -20%;
            left: -20%;
        }

        to {
            bottom: -7%;
            left: -2%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            left: 35%;
            bottom: -9%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -20%;
            right: -9%;
        }

        to {
            bottom: -4%;
            right: -5%;
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .promoImg1 {
        bottom: 0%;
        left: 0%;
    }
    .promoImg2 {
        bottom: -9%;
        left: 35%;
    }
    .promoImg3 {
        bottom: -4%;
        right: 0%;
    }
    @keyframes picture1 {
        from {
            bottom: -20%;
            left: -35%;
        }

        to {
            bottom: 0%;
            left: 0%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -30%;
            left: 45%;
        }

        to {
            bottom: -9%;
            left: 35%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -20%;
            right: -20%;
        }

        to {
            bottom: -4%;
            right: 0%;
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) and (min-height: 1100px) {
    .promoImg1 {
        bottom: -20%;
        left: -30%;
    }
    .promoImg2 {
        bottom: 0%;
        left: 30%;
    }
    .promoImg3 {
        bottom: 15%;
        right: -18%;
    }
    @keyframes picture1 {
        from {
            bottom: -40%;
            left: -55%;
        }

        to {
            bottom: -20%;
            left: -30%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 50%;
        }

        to {
            bottom: 0%;
            left: 30%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -5%;
            right: -38%;
        }

        to {
            bottom: 15%;
            right: -18%;
        }
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .text2 p {
        width: 65%;
    }

    .promoImg1 {
        bottom: -55%;
        left: -40%;
    }
    .promoImg2 {
        bottom: 0%;
        left: 28%;
    }
    .promoImg3 {
        bottom: -4%;
        right: -27%;
    }
    @keyframes picture1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -55%;
            left: -40%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: 0%;
            left: 28%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -20%;
            right: -50%;
        }

        to {
            bottom: -4%;
            right: -23%;
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1380px) {
    .text2 p {
        width: 60%;
    }

    .promoImg1 {
        bottom: -55%;
        left: -35%;
    }
    .promoImg2 {
        bottom: 0%;
        left: 28%;
    }
    .promoImg3 {
        bottom: -5%;
        right: -19%;
    }
    @keyframes picture1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -55%;
            left: -35%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: 0%;
            left: 28%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -20%;
            right: -50%;
        }

        to {
            bottom: -5%;
            right: -19%;
        }
    }
}

@media screen and (min-width: 1381px) and (max-width: 1500px) {
    .text2 p {
        width: 55%;
    }

    .promoImg1 {
        bottom: -40%;
        left: -30%;
    }
    .promoImg2 {
        bottom: -3%;
        left: 32%;
    }
    .promoImg3 {
        bottom: -8%;
        right: -16%;
    }
    @keyframes picture1 {
        from {
            bottom: -70%;
            left: -60%;
        }

        to {
            bottom: -40%;
            left: -30%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: -3%;
            left: 32%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -28%;
            right: -20%;
        }

        to {
            bottom: -8%;
            right: -16%;
        }
    }
}

@media screen and (min-width: 1501px) and (max-width: 1730px) {
    .text2 p {
        width: 55%;
    }

    .promoImg1 {
        bottom: -40%;
        left: -20%;
    }
    .promoImg2 {
        bottom: -3%;
        left: 35%;
    }
    .promoImg3 {
        bottom: 0%;
        right: -5%;
    }
    @keyframes picture1 {
        from {
            bottom: -70%;
            left: -40%;
        }

        to {
            bottom: -40%;
            left: -20%;
        }
    }

    @keyframes picture2 {
        from {
            bottom: -20%;
            left: 45%;
        }

        to {
            bottom: -3%;
            left: 35%;
        }
    }

    @keyframes picture3 {
        from {
            bottom: -28%;
            right: -20%;
        }

        to {
            bottom: 0%;
            right: -5%;
        }
    }
}
