.industries {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 80px;
}

.industriesTitle {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    /* position: absolute; */
    top: 0;
    left: 0;

    color: #111517;
}

.content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-top: 80px;
}

.industry {
    display: flex;
    flex-direction: row;
}

.wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
    border-bottom: 1px solid #dddfe4;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.textWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
}

.industryTitle p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;
    line-height: 142%;

    color: #111517;
}

.text {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.01em;

    white-space: nowrap;
    color: #5b6271;
}

.point {
    min-width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgba(91, 98, 113, 1);
    margin-left: 16px;
}

.textWrap:last-child .point:last-child {
    display: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .industries {
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .industriesTitle {
        font-size: 48.83px;
        text-align: center;
    }

    .content {
        padding-top: 40px;
    }

    .textWrap {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .title {
        margin-top: 10px;
    }

    .industry:nth-child(1) .wrap:nth-child(1) {
        border-top: 1px solid #dddfe4;
    }

    .wrap {
        padding-bottom: 28px;
    }

    .row {
        flex-direction: column;
    }

    .point {
        margin-left: 0;
        margin-right: 16px;
    }

    .textWrap:last-child .point:last-child {
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .industries {
        flex-direction: column;
    }

    .content {
        width: 80%;
        margin-left: auto;
    }

    .row {
        flex-wrap: wrap;
    }

    .text {
        line-height: 228%;
    }
}
