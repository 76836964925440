.container {
    display: flex;
    flex-direction: column;
}

.topWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.buttons {
    display: flex;
    flex-direction: row;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.button {
    cursor: pointer;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    border-radius: 50%;
    height: 60px;
    width: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
}
@media (hover: hover) {
    .button:hover {
        background-color: #f4f4f6;
        transition-property: background-color;
        transition-duration: 150ms;
    }
}

.bottomWrapper {
    margin-top: 7%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10%;
    transition: all 0.5s;
    max-width: 100%;
    position: relative;
}
.bottomWrapper::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    right: 100%;
    top: 0;
    background: #fff;
}

.comment {
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    min-width: calc(100% / 3);
    box-sizing: border-box;
    padding-right: 7%;
}

.commentName {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.02em;

    color: #828a9b;
}

.commentLocation {
    margin-top: 5px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    letter-spacing: 0.02em;

    color: #828a9b;
}

.commentText {
    margin-top: 15px;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 434px) {
    .topWrapper {
        width: 80%;
        margin: 0 10%;
        flex-direction: column;
    }

    .title {
        text-align: center;
    }

    .buttons {
        display: none;
    }

    .bottomWrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .comment {
        min-width: calc(100% / 1.1);
    }
}

@media screen and (min-width: 435px) and (max-width: 550px) {
    .topWrapper {
        width: 70%;
        margin: 0 15%;
        flex-direction: column;
    }

    .title {
        text-align: center;
    }

    .buttons {
        display: none;
    }

    .bottomWrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .comment {
        min-width: calc(100% / 1.6);
    }
}

@media screen and (min-width: 551px) and (max-width: 700px) {
    .topWrapper {
        width: 70%;
        margin: 0 15%;
        flex-direction: column;
    }

    .title {
        text-align: center;
    }

    .buttons {
        display: none;
    }

    .bottomWrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .comment {
        min-width: calc(100% / 2.1);
    }
}

@media screen and (min-width: 701px) and (max-width: 767px) {
    .topWrapper {
        width: 70%;
        margin: 0 15%;
        flex-direction: column;
    }

    .title {
        text-align: center;
    }

    .buttons {
        display: none;
    }

    .bottomWrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-top: 7%;
    }

    .comment {
        min-width: calc(100% / 2.4);
    }
}
