.container {
    display: flex;
    flex-direction: column;
}

.topWrap {
    padding: 10% 0 0 20%;
    display: flex;
    flex-direction: column;
}

.topInfo {
    display: flex;
    flex-direction: row;
}

.titles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    line-height: 110%;
    letter-spacing: -0.02em;

    color: #111517;
}

.title1 {
    position: relative;
    left: -80px;
}

.bottomInfo {
    padding-top: 5%;
    width: 63%;
}

.text {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}

.orange {
    color: #ff655c;
}

.bottomWrap {
    padding: 10% 0 3% 20%;
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* .LinkWrap {
    width: 32%;
    min-height: 100px;
    display: flex;
} */

.links a {
    cursor: pointer;
    width: 32%;
    min-height: 100px;
    display: flex;
}

.link {
    position: relative;
    width: 100%;
    border: 1px solid #c6cad2;
    box-sizing: border-box;
}

.link p {
    margin: 0;
}

.linkText {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.img {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media (hover: hover) {
    .link:hover {
        background: rgba(17, 21, 23, 0.04);
        transition: all 100ms;
    }
}

.link p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    padding-left: 5%;
    display: flex;
    align-items: flex-end;
}

.link img {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: flex-end;
    padding-right: 8%;
    padding-top: 8%;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
    .container {
        width: 100%;
    }

    .titles span {
        font-size: 52.2px;
        line-height: 110%;
    }

    .topWrap {
        padding: 10% 3% 0 3%;
    }

    .title1 {
        left: 0;
    }

    .title2 {
        width: 50%;
        flex-wrap: wrap;
    }

    .bottomInfo {
        padding-top: 7%;
        width: 100%;
    }

    .bottomInfo span {
        font-size: 16px;
        line-height: 156%;
    }

    .bottomWrap {
        padding: 10% 3% 5% 3%;
    }

    .linkText {
        bottom: 5px;
        left: 5px;
    }

    .img {
        top: 5px;
        right: 5px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .container {
        width: 100%;
    }

    .titles span {
        font-size: 52.2px;
        line-height: 110%;
    }

    .topWrap {
        padding: 10% 3% 0 3%;
    }

    .title1 {
        left: 15%;
    }

    .title2 {
        padding-left: 20%;
        white-space: nowrap;
    }

    .bottomInfo {
        padding-top: 7%;
        width: 80%;
        margin: auto;
    }

    .bottomInfo span {
        font-size: 16px;
        line-height: 156%;
    }

    .bottomWrap {
        padding: 10% 3% 5% 3%;
    }

    .link img {
        padding-right: 2%;
    }

    .link p {
        margin-left: 3%;
        margin-bottom: 3%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1201px) {
    .container {
        width: 100%;
    }

    .titles span {
        font-size: 66.2px;
        line-height: 110%;
    }

    .topWrap {
        padding: 10% 11% 0 25%;
    }

    .title1 {
        left: -15%;
    }

    .title2 {
        width: 100%;
    }

    .bottomWrap {
        padding-left: 25%;
    }

    .bottomInfo {
        padding-top: 7%;
        width: 100%;
    }

    .bottomInfo span {
        font-size: 16px;
        line-height: 156%;
        flex-wrap: nowrap;
    }

    .link img {
        padding-right: 2%;
    }

    .link p {
        margin-left: 3%;
        margin-bottom: 3%;
    }
}

@media screen and (min-height: 1024px) and (max-height: 1400px) {
    .titles span {
        font-size: 64.2px;
    }

    .topWrap {
        padding-left: 12%;
    }

    .bottomWrap {
        padding-left: 0;
    }
}
