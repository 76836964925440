.container {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper {
  width: 100%;
  max-width: 1050px;
  height: 600px;
  max-height: 600px;
}

.swiperSlide {
  font-size: 18px;
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    height: 450px;
    margin-top: 100px;
  }
}
