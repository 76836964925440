.services {
    padding: 100px 0;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;

    padding-bottom: 80px;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    padding-bottom: 16px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .services {
        padding: 40px 0;
    }

    .card {
        width: 100%;
    }

    .title {
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .services {
        padding: 60px 0;
    }

    .title {
        width: 100%;
        text-align: center;
        padding-bottom: 40px;
    }

    .cards {
        width: 100%;
        flex-direction: column;
    }

    .card {
        width: 100%;
        padding-bottom: 2%;
    }
}

@media screen and (min-width: 680px) and (max-width: 1400px) {
    .title {
        text-align: start;
    }

    .cards {
        flex-direction: column;
    }

    /* .cards .card {
        width: 70%;
    } */

    .cards .card:nth-child(2),
    .cards .card:nth-child(4),
    .cards .card:nth-child(6) {
        margin-left: auto;
    }
}

/* @media screen and (min-width: 1025px) and (max-width: 1400px) {
    .services {
        padding: 1px 0;
    }
    
    .title {
        width: 100%;
        text-align: center;
        padding-bottom: 1px;
    }

    .cards {
        width: 100%;
        flex-direction: row;
    }

    .card {
        padding-bottom: 2%;
    }
} */
