.technologies {
    display: flex;
    flex-direction: column;
}

.technologiesTitle {
    padding-top: 70px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-top: 80px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 70px;
}

.area {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}

.wrap {
    padding-right: 10px;
}

.imgBorderWrap {
    padding: 12px;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    height: 48px;
    width: 48px;
}

.titleBorderWrap {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    text-align: left;
    padding: 10px 12px;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    width: 300px;
    height: 48px;

    color: #111517;
}

.technology {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 40%;
}

.borderWrap {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    width: 160px;
    height: 48px;
}

.technology .borderWrap {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    padding: 10px 16px;

    color: #828a9b;
}

.technology .wrap {
    padding-bottom: 10px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .technologiesTitle {
        padding-top: 72px;
        text-align: center;
        font-size: 45.83px;
    }

    .content {
        padding-top: 40px;
        flex-direction: column;
        margin: 0 auto;
    }

    .row {
        padding-bottom: 40px;
        flex-direction: column;
    }

    .area {
        padding-bottom: 10px;
        padding-right: 0;
        flex-direction: row;
        justify-content: space-between;
        height: 48px;
    }

    .wrap {
        padding-right: 0;
    }

    .area .wrap {
        width: 48px;
        height: 48px;
    }

    .technology {
        justify-content: space-between;
        width: 100%;
    }

    .technology .wrap {
        width: 49%;
    }

    .technology .borderWrap {
        font-size: 16px;
    }

    .technology .wrap .borderWrap {
        width: 100%;
    }
}

@media screen and (min-width: 1px) and (max-width: 320px) {
    .titleBorderWrap {
        width: 83%;
    }
}

@media screen and (min-width: 321px) and (max-width: 376px) {
    .titleBorderWrap {
        width: 84%;
    }
}

@media screen and (min-width: 377px) and (max-width: 420px) {
    .titleBorderWrap {
        width: 85%;
    }
}

@media screen and (min-width: 420px) and (max-width: 767px) {
    .titleBorderWrap {
        width: 86%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .technologiesTitle {
        padding-top: 5%;
    }

    .row {
        justify-content: center;
    }

    .titleBorderWrap {
        width: 290px;
    }

    .technology {
        justify-content: space-between;
    }

    .technology .wrap {
        width: 48%;
        padding-right: 0;
    }

    .borderWrap {
        width: 100%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1063px) {
    .technology {
        justify-content: space-between;
    }

    .technology .wrap {
        width: 48%;
        padding-right: 0;
    }

    .borderWrap {
        width: 100%;
    }
}
