.dropdown {
    width: 100%;
    border: 1px solid #dddfe4;
    position: relative;
    z-index: 3;
}

.dropdownHeader {
    padding: 5%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.dropdownHeader::after {
    border-bottom: none;
}

.dropdownBody {
    padding: 2%;
    border: 1px solid #e5e8ec;
    border-top: none;
    display: none;
    position: absolute;
    top: 71px;
    right: 0;
    left: -1px;
    width: 96%;
    max-height: 20vh;
    overflow-y: auto;
    background: white;
}

.dropdownBody::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
}

.dropdownBody::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

.dropdownBody::-webkit-scrollbar-thumb {
    background-color: rgba(255, 101, 92, 1); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
}

.dropdownBody.open {
    display: block;
}

.dropdownItem {
    display: flex;
    justify-content: space-between;
    padding: 4%;
    cursor: pointer;
}

@media (hover: hover) {
    .dropdownItem:hover {
        background: #ff655c;
    }

    .dropdownItem:hover span {
        color: #fdfdfd;
    }

    .dropdownItem:hover .checkmark {
        border: 3px solid #fdfdfd;
        background-color: #ff655c;
    }

    .dropdownItem:hover .checkBoxContainer input:checked ~ .checkmark {
        background-color: #fdfdfd;
    }
    .dropdownItem:hover .checkBoxContainer input:checked ~ .checkmark::after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #ff655c;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.dropdownItemDot {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    color: #494e5b;
    transition: all 0.1s ease-in-out;
}

.dropdownItemDot.selected {
    opacity: 1;
}

.dropdownButton {
    transition: transform 0.3s;
}

.dropdownButton.open {
    transform: rotate(45deg);
}

.checkBox {
    position: relative;
    display: flex;
    padding-right: 4.6%;

    color: #fdfdfd;
}

.checkBoxContainer {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: #fdfdfd;
    border: 3px solid #111517;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
    background-color: #111517;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #fdfdfd;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 767px) {
    .dropdownHeader {
        font-size: 12.8px;
    }

    .dropdownItemDot {
        font-size: 12.8px;
    }

    .checkBox {
        padding-right: 8%;
    }

    .dropdownBody {
        top: 53px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
    .dropdownBody {
        top: 55px;
    }
}
