.wrapper {
    padding-bottom: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.widgetWrap {
    display: flex;
    box-sizing: border-box;
    width: 340px;
    height: 150px;
    max-width: 340px;
    max-height: 150px;
    border: 1px solid #dddfe4;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.widget {
    display: flex;
    box-sizing: border-box;
}
@media (hover: hover) {
    .widgetWrap:hover {
        border-bottom: 1px solid #ff655c;
    }
}

@media screen and (min-width: 1px) and (max-width: 700px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .widgetWrap {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
    .wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .widgetWrap {
        width: 300px;
    }

    .widgetWrap:nth-child(1),
    .widgetWrap:nth-child(3) {
        margin-right: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1100px) {
    .wrapper {
        flex-wrap: wrap;
        width: 70%;
        margin: 0 auto;
        align-items: center;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
    .widgetWrap {
        max-width: 230px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
    .widgetWrap {
        max-width: 280px;
    }
}
