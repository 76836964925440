.ourApproach {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
    margin-top: 50px;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
    padding-bottom: 80px;
}

.infoBlocks {
    display: flex;
    flex-direction: column;
}

.infoBlockCouple {
    margin: 1% 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.block {
    display: flex;
    flex-direction: column;
    width: 87%;
    justify-content: flex-start;
    padding: 7%;
}

.infoBlock1 {
    background: #ff655c;
    width: 49%;
}
.infoBlock2 {
    background: #f4f4f6;
    width: 49%;
}
.infoBlock3 {
    background: #f4f4f6;
    width: 49%;
}
.infoBlock4 {
    background: #6d7588;
    width: 49%;
}
.infoBlock5 {
    background: #ffeceb;
    width: 49%;
}
.infoBlock6 {
    background: #f4f4f6;
    width: 49%;
}

.imgBlock {
    width: 100px;
    height: 100px;
    margin-bottom: 4%;
}

.titleBlockBlack {
    margin-bottom: 24px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}
.titleBlockWhite {
    margin-bottom: 24px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #fcfcfd;
}

.textBlockGray {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}
.textBlockWhite {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #fcfcfd;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .ourApproach {
        margin-top: 30px;
    }

    .title {
        text-align: center;
        font-size: 48px;
    }

    .infoBlockCouple {
        flex-direction: column;
        width: 100%;
        margin-bottom: 3%;
    }

    .infoBlock1 {
        background: #ff655c;
        width: 100%;
        margin-bottom: 3%;
    }
    .infoBlock2 {
        background: #f4f4f6;
        width: 100%;
    }
    .infoBlock3 {
        background: #f4f4f6;
        width: 100%;
        margin-bottom: 3%;
    }
    .infoBlock4 {
        background: #6d7588;
        width: 100%;
    }
    .infoBlock5 {
        background: #ffeceb;
        width: 100%;
        margin-bottom: 3%;
    }
    .infoBlock6 {
        background: #f4f4f6;
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .titleBlockWhite {
        font-size: 20px;
    }
    .titleBlockBlack {
        font-size: 20px;
    }

    .textBlockWhite {
        font-size: 16px;
    }
    .textBlockGray {
        font-size: 16px;
    }
}
