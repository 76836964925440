.dropdown {
    width: 100%;
    border: 2px solid #111517;
    position: relative;
    cursor: pointer;
}

.dropdown.error {
    border: 2px solid #ff655c;
}

.dropdown.open {
    border-bottom: none;
    background: #FCFCFD;
}

.dropdownBody.open {
    position: absolute;
    left: -2px;
    width: 100%;
    z-index: 5;
    background: #FCFCFD;
    border: 2px solid #111517;
    border-top: none;
}

.dropdownHeader {
    padding: 19px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.error .dropdownHeader {
    color: #ff655c;
}

.dropdownButton {
    font-family: Manrope;
    font-size: 27px;

    transition: transform 0.3s;
}

.dropdownButton.open {
    transform: rotate(45deg);
}

.dropdownItem {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

@media (hover: hover) {
    .dropdownItem:hover {
        background: #ff655c;
    }

    .dropdownItem:hover span {
        color: #fdfdfd;
    }
}

.dropdownItemDot {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    color: #494e5b;
    transition: all 0.1s ease-in-out;
}

.path {
    stroke: #111517;
  }
