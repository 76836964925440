.textContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10%;
}

.title {
    display: flex;
    flex-direction: column;
}

.textContainer p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    line-height: 0%;
    letter-spacing: -0.02em;

    color: #111517;
}

.points {
    display: flex;
    flex-direction: row;
}

.point {
    width: 20px;
    height: 20px;
    background: #111517;
    border-radius: 50%;
    margin-right: 12px;
}

.point:nth-child(3) {
    background: #ff655c;
}

.textContainer span {
    width: 47%;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}

.cards {
    margin-top: 7%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 240px;
}

.cards a {
    cursor: pointer;
    display: flex;
    width: 24%;
    height: 150px;
}

@media (hover: hover) {
    .card:hover {
        background: rgba(17, 21, 23, 0.04);
        transition: all 100ms;
    }
}

.card {
    position: relative;
    width: 100%;
    border: 1px solid #c6cad2;
    box-sizing: border-box;
}

.cardText {
    position: absolute;
    bottom: 10px;
    left: 10px;

    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;
    color: #111517;
}

.img {
    position: absolute;
    top: 10px;
    left: 10px;
}

.arrowImg {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    /* .whatWeDo {
        width: 98%;
        margin: auto;
    } */

    .textContainer {
        flex-direction: column;
        width: 100%;
        align-items: baseline;
    }

    .textContainer p {
        font-size: 45.2px;
        white-space: normal;
    }

    .textContainer span {
        font-size: 16px;
        width: 100%;
        align-items: unset;
    }

    .cards {
        margin-top: 20%;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    .cards a {
        width: 48%;
        height: 100px;
    }

    .cards a:nth-child(1),
    .cards a:nth-child(2) {
        margin-bottom: 3%;
    }

    .cardText {
        position: absolute;
        bottom: 5px;
        left: 5px;
    }

    .img {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .arrowImg {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .textContainer {
        flex-direction: column;
        width: 100%;
        align-items: baseline;
    }

    .textContainer p {
        font-size: 45.2px;
        white-space: normal;
    }

    .textContainer span {
        font-size: 16px;
        width: 100%;
        align-items: unset;
    }

    .cards {
        flex-wrap: wrap;
        margin-bottom: 135px;
    }

    .cardWrap {
        width: 48%;
        height: 150px;
    }

    .cardWrap:nth-child(1) {
        margin-bottom: 3%;
    }
    .cardWrap:nth-child(2) {
        margin-bottom: 3%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .cards {
        margin-bottom: 135px;
    }

    .textContainer p {
        font-size: 70px;
    }
    .textContainer span {
        font-size: 16px;
    }
}

@media screen and (min-height: 1024px) and (max-height: 1400px) {
    .textContainer span {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .cards a {
        width: 49%;
        margin-bottom: 20px;
    }
}
