.container {
  width: 100%;
  height: 100%;
  margin: 0 50px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(105.77deg, #FCFCFD 13%, #F4F4F6 99.58%);
  box-shadow: 0px -5px 52px rgba(0, 0, 0, 0.11);
}

.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.header p {
  padding: 0 10px;
  font-size: 48px;
  font-family: "Archivo Black", sans-serif;
  font-weight: bold;

  background-color: red;
  background-image: linear-gradient(45deg, #539FF8, #FFEB64);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.headerLine {
  background: #ECECEF;
  border-radius: 6px;
  height: 6px;
}

.headerLine:first-child {
  width: 75%;
  margin-left: 20px;
}
.headerLine:last-child {
  width: 20%;
  margin-right: 20px;
}

.body {
  position: relative;
  height: calc(100% - 50px);
  overflow: hidden;
  padding: 0 20px;
}

.heartIcon {
  position: absolute;
  top: 30px;
  right: 70px;
  width: 100px;
  height: 100px;
}

.blueBlur {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 200px;
  height: 400px;
  background: #539FF8;
  filter: blur(110px);
  transform: rotate(33.42deg);

  z-index: 1;
}

.yellowBlur {
  position: absolute;
  bottom: -200px;
  left: 300px;
  width: 200px;
  height: 350px;
  background: #FFEB64;
  filter: blur(110px);
  transform: rotate(75.38deg);

  z-index: 1;
}

.slide1Title {
  position: absolute;
  bottom: 80px;
  left: 80px;
  text-align: left;
  
  font-style: normal;
  font-weight: 750;
  font-size: 85px;
  color: #111517;

  z-index: 10;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.02em;

  color: #111517;

  margin-bottom: 50px;
}

.subtitle {
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;

  color: #539FF8;
}

.table {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.shortRow {
  width: 85%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.row a {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;

  color: #338BF2;
}

.row span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;

  color: grey;
}

.shortRow a {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;

  color: #338BF2;
}

.shortRow span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.02em;

  color: grey;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .container {
    margin: 0;
  }

  .headerLine:first-child {
    width: 45%;
    margin-left: 8px;
  }
  .headerLine:last-child {
    width: 45%;
    margin-right: 8px;
  }

  .heartIcon {
    position: absolute;
    top: 30px;
    right: 3px;
    width: 60px;
    height: 60px;
  }

  .slide1Title {
    left: 40px;
    bottom: 180px;
    font-size: 40px;
  }

  .subtitle {
    font-size: 16px;
  }

  .title {
    font-size: 32px;
  }

  .row a {
    font-size: 16px;
  }
  
  .row span {
    font-size: 16px;
  }
  
  .shortRow a {
    font-size: 16px;
  }

  .shortRow span {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 30px;
    width: 80%;
  }

  .row a {
    font-size: 14px;
  }
  
  .row span {
    font-size: 14px;
  }
}
