.card {
    border: 1px solid #c6cad2;
    /* box-sizing: border-box; */
    width: calc(100vw / 4.1);
    height: 380px;
}

.cardContent {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    flex-wrap: nowrap;
}

.content1,
.content2 {
    min-width: 100%;
    transition: all 0.3s;
}

.content1 {
    display: flex;
    flex-direction: column;
}

.active .content1 {
    margin-left: -100%;
}

.cardImage {
    display: flex;
    margin: 24px 0 0 24px;
    flex: 1;
    width: 156px;
    height: 156px;
}

.bottomContent {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 156%;

    display: flex;
    align-items: flex-end;
    margin: 0 0 40px 40px;

    color: #111517;
}

.buttonWrap {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    background-color: #f4f4f6;
    right: 24px;
    bottom: 24px;
}

@media (hover: hover) {
    .button:hover {
        background-color: rgba(17, 21, 23, 0.04);
    }

    .button:hover img {
        transform: scale(1.15);
        transition-duration: 150ms;
    }
}

.button {
    width: 72px;
    height: 72px;
    border: 1px solid #c6cad2;
    border-radius: 50%;
    transition: transform 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;
}

.button img {
    width: 24px;
    height: 24px;
}

.button.active {
    transform: rotate(45deg);
}

.content2 .title {
    margin: 24px 0 0 24px;
    margin-bottom: 36px;
}

.activeContent {
    position: relative;
    z-index: 1;
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.contentRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.contentRow:last-child {
    margin-bottom: 0;
}

.contentRow img {
    margin-right: 16px;
}

.contentRow span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 15.4px;
    line-height: 156%;
    letter-spacing: 0.01em;

    color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
    .card {
        height: 280px;
    }

    .card.active {
        height: 280px;
    }

    .card.active:nth-child(1) {
        height: 370px;
    }

    .card.active:nth-child(2),
    .card.active:nth-child(3),
    .card.active:nth-child(4) {
        height: 330px;
    }

    .cardImage {
        margin: 14px 0 0 14px;
        width: 80px;
        height: 80px;
    }

    .title {
        font-size: 16px;
        margin: 0 0 14px 14px;
        white-space: normal;
    }

    .buttonWrap {
        right: 14px;
        bottom: 14px;
    }

    .title.active {
        font-size: 16px;
    }

    .activeContent {
        margin-left: 14px;
    }

    .button {
        width: 60px;
        height: 60px;
    }

    .contentRow span {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 1px) and (max-width: 680px) {
    .card {
        max-width: 96%;
        width: 96%;
        margin: 0 auto;
        height: 200px;
    }
}

@media screen and (min-width: 681px) and (max-width: 1024px) {
    .card {
        max-width: 100%;
        width: 100%;
    }

    .cardImage {
        margin-left: 30px;
    }

    .title {
        margin: 0 0 30px 30px;
        font-size: 20px;
    }

    .title.active {
        font-size: 20px;
        margin: 30px 0 36px 30px;
    }

    .activeContent {
        margin-left: 30px;
    }

    .buttonWrap {
        right: 30px;
        bottom: 30px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
    .card {
        width: calc(100vw / 2.38);
        height: 320px;
    }

    .cardImage {
        margin: 24px 0 0 24px;
        width: 112px;
        height: 112px;
    }

    .title {
        font-size: 16px;
    }

    .title.active {
        margin-bottom: 28px;
    }

    .contentRow span {
        font-size: 14px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .card {
        width: 385px;
        height: 380px;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1700px) {
    .card {
        width: 450px;
        height: 380px;
    }
}

@media screen and (min-width: 1850px) {
    .card {
        width: 450px;
        height: 380px;
    }
}
