.burgerMenu {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  bottom: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  transition: all 1.4s;
  background: rgba(252, 252, 253);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                              supported by Chrome and Opera */
}

/* .burgerMenu.active {
    min-height: 800px;
    height: 800px;
} */

.imgCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 3%;
  border: 2px solid #111517;
}

.imgCircle:last-child {
  margin-right: 0;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .navItems {
    padding-top: 78px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .navItems {
    padding-top: 98px;
  }
}

.burgerMenu.active {
  transform: translateY(100%);
}

.navItems {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
  flex-grow: 1;
}

.navItemWrap {
  padding: 28px 0;
  border-bottom: 1px solid #f4f4f6;
}

.navItemWrap:first-child {
  padding-top: 0;
}

.navItem {
  text-decoration: none;
}

.navItem.disabled {
  cursor: not-allowed;
}

.navItem.disabled .navLink {
  position: relative;
  color: lightslategray;
}

.navItem.disabled .navLink:after {
  content: 'SOON';
  position: absolute;
  color: #ff655c;
  top: -5px;
  left: 40px;
  font-size: 10px;
}

.navLinkWrap {
  width: 100%;
}

.activeLink div {
  background-color: #ff655c !important;
}

.activeLink span {
  color: #fdfdfd !important;
}

.navItem span {
  padding-left: 3%;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;

  color: rgba(17, 21, 23, 1);
}

.navLink {
  display: block;
  width: 100%;
  padding: 10px 0;
}

.imgContainer {
  position: relative;
  width: 85%;
  margin: 10% auto 0;
}

.imgLinks {
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
}

@media screen and (max-width: 500px) {
  .imgLinks {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .imgCircle {
    margin-right: 0;
  }

  .navItemWrap {
    padding: 3% 0;
  }

  .imgContainer {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .imgContainer {
    margin-bottom: 70px;
  }
}

@media screen and (orientation: landscape) {
  .imgContainer {
    margin-top: 0;
  }
}

@media screen and (min-height: 1024px) and (max-height: 1400px) {
  .navItems {
    padding-top: 130px;
  }
}
