.container {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}

.footerContainer {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.greySection {
    background-color: #f4f4f6;
}

.infoBlock {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #dddfe4;
    padding-bottom: 50px;
}

.textWrap {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.title span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-size: 54.93px;

    color: #111517;
}

.text p {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.circles {
    display: flex;
    flex-direction: row;
}

.circleWrap {
    padding-right: 20px;
}

.circleWrap:nth-child(2) {
    padding-right: 0;
}

.circle {
    display: flex;
    align-items: center;
    padding: 2%;
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    max-height: 120px;
    border: 2px solid #111517;
    box-sizing: border-box;
    border-radius: 50%;
}

.circle span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    line-height: 156%;
    text-align: center;
    letter-spacing: 0.02em;
    width: 90%;
    margin: 0 auto;

    color: #111517;
}

.filters {
    display: flex;
    flex-direction: row;
    margin: 20px 0 80px;
}

.filterWrap {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    margin-right: 10px;
}

.filters .filterWrap:nth-child(2) {
    margin-right: 0;
}

.filter {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 146%;
    white-space: nowrap;
    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 6px 10px;

    color: #494e5b;
}

.images {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.images img {
    margin-bottom: 60px;
}

.images img:nth-last-child(1) {
    margin-bottom: 90px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.btnWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btnWrap span {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

.btnWrap:nth-child(1) .button {
    margin-right: 32px;
}

.btnWrap:nth-child(2) span {
    margin-right: 32px;
}

.button {
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    border-radius: 50%;
    height: 100px;
    width: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
}
@media (hover: hover) {
    .button:hover {
        background-color: #f4f4f6;
        transition-property: background-color;
        transition-duration: 150ms;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .container {
        min-width: 94%;
    }

    .infoBlock {
        margin-top: 28px;
        flex-direction: column;
    }

    .textWrap {
        width: 100%;
    }

    .title {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .title span {
        font-size: 48.83px;
    }

    .text p {
        font-size: 16px;
    }

    .circles {
        padding-top: 30px;
    }

    .circle {
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
    }

    .filters {
        margin-bottom: 40px;
    }

    .images img {
        margin-bottom: 20px;
    }

    .images img:nth-last-child(1) {
        margin-bottom: 32px;
    }

    .buttons {
        margin-bottom: 20px;
    }

    .btnWrap span {
        display: none;
    }

    .button {
        width: 60px;
        height: 60px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        min-width: 90%;
    }

    .infoBlock {
        margin-top: 40px;
        flex-direction: column;
        padding-bottom: 40px;
    }

    .textWrap {
        width: 100%;
    }

    .title {
        display: flex;
        justify-content: flex-end;
    }

    .title span {
        font-size: 48.83px;
    }

    .text p {
        font-size: 16px;
    }

    .circles {
        padding-top: 40px;
    }

    .circle {
        min-width: 120px;
        max-width: 120px;
        min-height: 120px;
        max-height: 120px;
    }

    .images img {
        margin-bottom: 32px;
    }

    .images img:nth-last-child(1) {
        margin-bottom: 40px;
    }

    .buttons {
        margin-bottom: 40px;
    }

    .btnWrap span {
        font-size: 12.8px;
    }

    .button {
        width: 60px;
        height: 60px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
    .title span {
        font-size: 45.93px;
    }

    .text p {
        font-size: 16px;
    }
}
