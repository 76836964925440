.notFoundPage {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
}

.greySection {
    background-color: #f4f4f6;
}

.container {
    width: 96%;
    max-width: 1400px;
    margin: 0 auto;
    /* border-left: 1px solid black;
    border-right: 1px solid black;    */
}

.footerContainer {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.message {
    width: 28%;
    margin: 10% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 142%;

    text-align: center;
    white-space: nowrap;

    color: #111517;
}

.message span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;

    text-align: center;
    letter-spacing: 0.02em;

    color: #5b6271;
}

.homeLink {
    margin-top: 3%;
    text-decoration: none;
    display: flex;
    flex-direction: row;
}

.homeLink p {
    padding-right: 7%;

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    white-space: nowrap;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #111517;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .message {
        width: 80%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .message {
        width: 100%;
    }

    .message span {
        width: 65%;
    }

    .homeLink {
        margin-top: 2%;
    }
}
