.button {
  -webkit-user-select: none;

  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  border: 2px solid #111517;
  width: 48%;
  padding: 20px 0;
  
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #111517;
}

@media (max-width: 500px) {
  .button {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .large {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (hover: hover) {
  .button:hover {
      background: rgba(17, 21, 23, 0.04);
      transition: all 100ms;
  }
}

.light {
  border-color: #FDFDFD;
  color: #FDFDFD;
}

.button input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.button span {
  margin-left: 20px;
}
.button img {
  margin-right: 20px;
}