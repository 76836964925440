.about {
    overflow: hidden;
}

.innerContainer {
    margin: 0 0 0 260px;
    /* border-left: 1px solid black; */
}

@media screen and (max-width: 1201px) {
    .innerContainer {
        margin: 0;
    }
}
