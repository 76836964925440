.dropdown {
  width: 100%;
  border: 2px solid #ff655c;
  position: relative;
  cursor: pointer;
  background: #FDFDFD;
  margin-top: 32px;
}

.dropdown.open {
  border-bottom: none;
}

.dropdownBody.open {
  position: absolute;
  left: -2px;
  width: 100%;
  z-index: 5;
  background: #FCFCFD;
  border: 2px solid #ff655c;
  border-top: none;
}

.dropdownHeader {
  padding: 19px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 14.4px;
  line-height: 138%;

  letter-spacing: 0.24em;
  text-transform: uppercase;

  color: #ff655c;
}

.error .dropdownHeader {
  color: #ff655c;
}

.dropdownButton {
  font-family: Manrope;
  font-size: 27px;

  transition: transform 0.3s;
}

.dropdownButton.open {
  transform: rotate(45deg);
}

.dropdownItem {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

@media (hover: hover) {
  .dropdownItem:hover {
      background: #EBEDF2;
  }
}

.dropdownItemDot {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  line-height: 156%;
  letter-spacing: 0.02em;

  color: #111517;
  transition: all 0.1s ease-in-out;
}

.path {
  stroke: #ff655c;
}

@media (min-width: 501px) and (max-width: 768px) {
  .dropdown {
    margin-top: 30px;
  }
}