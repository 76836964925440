.homeWrapper {
    overflow: hidden;
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    /* border-left: 1px solid black;
  border-right: 1px solid black;    */
}

.greySection {
    background-color: #f4f4f6;
}

.bottomContainer {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

@media screen and (max-width: 1201px) {
    .promoWrapper {
        padding: 0 2% 2%;
    }
}
