.tableWrapper {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    width: 100%;
    padding-top: 60px;
}

.row {
    border-bottom: 1px solid #dddfe4;
    display: flex;
    width: 100%;
}

.row:nth-child(3) {
    border: hidden;
}

.row > .column:nth-child(n + 2) {
    align-items: center;
}

.column {
    width: 50%;
    display: flex;
    padding-bottom: 52px;
}

.middleLine {
    display: flex;
    align-self: start;
}

.middleLine img {
    width: 50px;
    height: 30px;
}

.middleLine p {
    margin-block-start: 0;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;

    color: #111517;
}

.text {
    margin: 0 10px;

    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 110px;
}

.block {
    width: 49%;
    border: 1px solid #dddfe4;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.blockContent {
    display: flex;
    flex-direction: column;
    margin: 3%;
}

.blockContent p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #111517;
}

.blockContent span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    letter-spacing: 0.01em;

    color: #494e5b;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .ourBenefits {
        margin-top: 40px;
    }

    .tableWrapper {
        width: 100%;
        flex-direction: column;
        padding-top: 25px;
    }

    .row {
        width: 94%;
        flex-direction: column;
        margin: auto;
    }

    .column {
        width: 100%;
        padding-left: 3%;
    }

    .blocks {
        flex-direction: column;
        margin-bottom: 65px;
    }

    .block {
        width: 94%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .ourBenefits {
        margin-top: 80px;
    }

    .tableWrapper {
        padding-top: 10px;
    }

    .ourBenefits {
        width: 80%;
        padding-left: 17%;
    }

    .blocks {
        margin-bottom: 70px;
    }
}
