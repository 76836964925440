.footerContainer {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.greySection {
    background-color: #f4f4f6;
}

.topWrap {
    display: flex;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(221, 223, 228, 1);
    padding-bottom: 20px;
}

.textWrap {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    /* padding-bottom: 100px; */
}

.titleText {
    /* padding-top: 40px; */
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    line-height: 85%;
    letter-spacing: -0.02em;

    color: #111517;
}

.text {
    display: block;
    width: 44%;
}

.text span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
}

.buttons {
    height: 46px;
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: row;
}

.btn {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;

    color: #111517;

    white-space: nowrap;
    border: 1px solid transparent;
    background-color: white;
    padding: 10px;
    transition: all 300ms;
}

.buttonWrap {
    margin-right: 5px;
    justify-content: center;
    border: 1px solid transparent;
}

@media (hover: hover) {
    .btn:hover {
        border: 1px solid #c6cad2;
    }

    .active:hover {
        border: 1px solid transparent;
    }

    .pageLink:hover {
        border: 1px solid #c6cad2;
    }

    .pageLink.active:hover {
        border: 1px solid transparent;
    }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
    .buttonsContainer {
        max-width: 100%;
        flex-direction: column;
        margin: 30px 0 30px 0;
    }

    .buttons {
        width: 98%;
        overflow-x: auto;
        margin-bottom: 30px;
    }

    .btn {
        white-space: nowrap;
    }
}
@media screen and (min-width: 481px) and (max-width: 1000px) {
    .buttonsContainer {
        max-width: 100%;
        flex-direction: column;
        margin: 55px 0 30px 0;
    }

    .buttons {
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
    }

    .btn {
        white-space: nowrap;
    }
}

.pageLink.active {
    background: #ff655c;
    color: #fdfdfd;
}

.pageLink {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;

    color: #111517;

    border: 1px solid transparent;
    background-color: white;
    padding: 18px 20px;
    transition: all 300ms;
}

.active {
    background: #ff655c;
    color: #fdfdfd;
}
.circles {
    margin: 80px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.circle {
    background-color: white;
    text-decoration: none;
    color: #111517;
    display: flex;
    align-items: center;
    padding: 5%;
    min-width: 21em;
    max-width: 21em;
    min-height: 21em;
    max-height: 21em;
    border: 2px solid #111517;
    box-sizing: border-box;
    border-radius: 50%;
    justify-content: space-between;
}

.formContainer {
    width: calc(100% - 80px);
    margin: 120px auto 0 auto;
    background: #FF655C;
}

@media (min-width: 501px) and (max-width: 768px) {
    .formContainer {
        margin-top: 90px;
    }
}

@media (max-width: 500px) {
    .formContainer {
        margin-top: 60px;
    }
}

@media (min-width: 1201px) and (max-width: 1300px) {
    .formContainer {
        width: calc(100% - 60px);
    }
}
@media (min-width: 769px) and (max-width: 1200px) {
    .formContainer {
        width: calc(100% - 40px);
    }
}
@media (min-width: 1px) and (max-width: 768px) {
    .formContainer {
        width: calc(100% - 16px);
    }
}

@media (hover: hover) {
    .circle:hover {
        background: #f4f4f6;
    }
}

.circle span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;

    width: 90%;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .textWrap {
        flex-direction: column;
    }

    .titleText {
        font-size: 66.2px;
        display: flex;
        justify-content: center;
    }

    .text {
        width: 100%;
        padding-top: 30px;
    }

    .text span {
        font-size: 16px;
    }

    .circles {
        flex-direction: column;
        margin: 40px 0;
    }

    .circleWrap {
        width: 70%;
    }

    .circleWrap {
        width: 15em;
        padding-bottom: 2%;
    }

    .circleWrap:nth-child(1),
    .circleWrap:nth-child(3) {
        margin-left: 5%;
    }
    .circleWrap:nth-child(2),
    .circleWrap:nth-child(4) {
        margin-left: auto;
        margin-right: 5%;
    }

    .circle {
        min-width: 15em;
        max-width: 15em;
        min-height: 15em;
        max-height: 15em;
        justify-content: center;
        padding: 0 3%;
    }

    .circle span {
        font-size: 12.8px;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .circleWrap {
        width: 17em;
    }

    .circle {
        min-width: 17em;
        max-width: 17em;
        min-height: 17em;
        max-height: 17em;
        justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1px) and (max-width: 405px) {
    .textWrap {
        padding-bottom: 150px;
    }
}

@media screen and (min-width: 406px) and (max-width: 550px) {
    .textWrap {
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 551px) and (max-width: 767px) {
    .textWrap {
        padding-bottom: 50px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .textWrap {
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 70px;
    }

    .titleText {
        padding-bottom: 30px;
    }

    .text {
        width: 100%;
    }

    .circles {
        width: 94%;
        margin: 60px 3%;
        flex-wrap: wrap;
    }

    .circleWrap {
        display: flex;
        justify-content: center;
        width: 50%;
        padding-bottom: 3%;
    }
}

@media screen and (min-width: 830px) and (max-width: 940px) {
    .circle {
        min-width: 23em;
        max-width: 23em;
        min-height: 23em;
        max-height: 23em;
        justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 941px) and (max-width: 1100px) {
    .circle {
        min-width: 25em;
        max-width: 25em;
        min-height: 25em;
        max-height: 25em;
        justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .circles {
        margin-top: 60px;
    }

    .circle {
        min-width: 27em;
        max-width: 27em;
        min-height: 27em;
        max-height: 27em;
        justify-content: center;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .circles {
        width: 100%;
        padding-bottom: 0;
    }

    .circle {
        min-width: 16em;
        max-width: 16em;
        min-height: 16em;
        max-height: 16em;
        justify-content: space-between;
        padding: 0 3%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1550px) {
    .circles {
        width: 100%;
        padding-bottom: 0;
    }

    .circle {
        min-width: 18em;
        max-width: 18em;
        min-height: 18em;
        max-height: 18em;
        justify-content: space-between;
        padding: 0 3%;
    }
}
