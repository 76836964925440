.expertise {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 70px;
    width: 100%;
}

.title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    width: 32%;
    padding-bottom: 5%;
    white-space: nowrap;
}

.columnTitleWrap {
    width: 100%;
    height: 50px;
    border-bottom: 2px solid #111517;
}

.columnTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.columnTitle span {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;
    line-height: 142%;

    display: flex;
    align-items: center;

    color: #111517;
}

.columnTitle p {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.02em;

    margin-right: 3%;

    color: #111517;
}

.textWrap {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddfe4;
}

.textWrap span {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 156%;
    letter-spacing: 0.01em;

    color: #5b6271;
}

.textWrap:nth-last-child(1) {
    border: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .expertise {
        padding-top: 30px;
        padding-bottom: 20px;
        width: 100%;
    }

    .title {
        display: flex;
        justify-content: center;
        font-size: 48.83px;
        line-height: 0%;
    }

    .content {
        overflow-x: auto;
    }

    .column {
        width: calc(100vw / 1.2);
        margin-right: 5%;
    }

    .content::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }

    .content::-webkit-scrollbar-track {
        background: white; /* color of the tracking area */
    }

    .content::-webkit-scrollbar-thumb {
        background-color: #111517; /* color of the scroll thumb */
        height: 5px;
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 6px solid white; /* creates padding around scroll thumb */
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .expertise {
        padding-top: 48px;
        padding-bottom: 52px;
        width: 100%;
    }

    .title {
        display: block;
        font-size: 48.83px;
        line-height: 0%;
    }

    .content {
        overflow-x: auto;
    }

    .column {
        width: calc(100vw / 2.2);
        margin-right: 3%;
    }

    .content::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
    }

    .content::-webkit-scrollbar-track {
        background: white; /* color of the tracking area */
    }

    .content::-webkit-scrollbar-thumb {
        background-color: #111517; /* color of the scroll thumb */
        height: 5px;
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 6px solid white; /* creates padding around scroll thumb */
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .expertise {
        padding-top: 40px;
    }
}
