@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap');

html {
  font-size: 16px;
}

.headerWrapper {
  background-color: transparent;
  padding-bottom: 100px;
}

.header {
  position: fixed;
  z-index: 10;
  height: 100px;
  width: 100vw;
}

.container {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background: rgba(252, 252, 253, 0.9);
  backdrop-filter: blur(32px);
  z-index: 2;
}

.logoWrap {
  margin-left: 3%;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 236px;
  height: 60px;
}

.logo {
  width: 60px;
  height: 60px;
  margin: auto;
}

.large {
  width: 172px;
  height: 44px;
  margin: auto;
}

.navItems {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-right: 40px;
}

.navItem {
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  padding: 0px 28px;
}

.navItem.disabled {
  cursor: not-allowed;
}

.navItem.disabled .navLink {
  position: relative;
  color: lightslategray;
}

.navItem.disabled .navLink:after {
  content: 'SOON';
  position: absolute;
  color: #ff655c;
  top: -20px;
  left: 20px;
  font-size: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .navItems {
    margin-right: 20px;
  }

  .navItem {
    padding: 0 18px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .navItems {
    margin-right: 30px;
  }

  .navItem {
    padding: 0 24px;
  }
}

@media (hover: hover) {
  .navItem:hover span {
    color: #ff655c;
    transition-property: color;
    transition-duration: 100ms;
  }
}

.navLink {
  text-align: center;

  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 138%;
  letter-spacing: 0.24em;
  text-transform: uppercase;

  color: #111517;
}

.active {
  background-color: #ff655c !important;
}

.active span {
  color: #fdfdfd !important;
}

.brgButton {
  padding-right: 4%;
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .headerWrapper {
    padding-bottom: 68px;
  }

  .header {
    height: 68px;
  }

  .logo {
    height: 44px;
    width: 44px;
  }

  .logo img {
    height: 44px;
    width: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .headerWrapper {
    padding-bottom: 98px;
  }

  .header {
    height: 98px;
  }

  .logo {
    height: 56px;
    width: 56px;
  }

  .logo img {
    height: 56px;
    width: 56px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
  .logos {
    width: 60px;
  }

  .large {
    display: none;
  }

  .navItems {
    display: none;
  }

  .brgButton {
    display: inline-block;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1800px) {
  .navItems {
    width: 60%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .navLink {
    font-size: 0.71rem;
  }
}
