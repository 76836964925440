.formContainer {
  font-family: Manrope;
  font-style: normal;
  color: #fdfdfd;
  --title-font: 43.95px;
  --title-laptop-font: 38px;
  --title-tablet-font: 39px;
  --title-mobile-font: 31.25px;
  --main-font: 14.4px;
  --main-tablet-font: 12.8px;
  --main-color: #fdfdfd;

  display: grid;
  grid-template-columns: 50% 50%;
}

.areaItem1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  border-right: 1px solid rgba(253, 253, 253, 0.2);

  display: flex;
  flex-direction: column;
  padding: 100px 60px 100px 0;
}

.title {
  font-size: var(--title-font);
  font-weight: 500;
  line-height: 118%;
}

.form {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
}

.form input[type='text'] {
  background-color: #ff655c;
  color: var(--main-color);
}

.form input[type='text']::placeholder {
  color: var(--main-color);
}

.itemsCouple {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemsCouple .field {
  width: 49%;
}

.error {
  background-image: url(../../../images/Careers/WarningWhite.svg);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: bottom 0 right 16px;
  transition: 0.3s;
}

.message {
  margin-top: 20px;
}

.field hr {
  height: 2px;
  background: var(--main-color);
  border: none;
}

.areaItem2 {
  grid-column-start: 2;
  grid-column-end: 3;
  border-bottom: 1px solid rgba(253, 253, 253, 0.2);

  padding: 100px 0 100px 60px;
  width: 100%;
}

.areaItem3 {
  grid-column-start: 2;
  grid-column-end: 3;

  padding: 60px 0 100px 60px;
}

.row {
  display: flex;
  flex-direction: row;
}

.image {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  margin-right: 30px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.subtitle {
  font-size: 22.5px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 105%;
  margin: 15px 0 10px 0;
}

.text {
  font-size: var(--main-font);
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 11px 9px 9px;

  border: 1px solid rgba(253, 253, 253, 0.2);
  box-sizing: border-box;
  border-radius: 24px;

  font-size: var(--main-font);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--main-color);
  text-decoration: none;
}

.paragraph {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffeceb;
  max-width: 90%;
}

@media (hover: hover) {
  .link:hover img {
    transform: scale(1.1);
    transition: all 0.3s;
  }
}

.link img {
  margin-right: 5px;
}

.link:first-child {
  margin-right: 15px;
}

.underMessage {
  font-size: 12px;
  line-height: 146%;
}

.files {
  -webkit-user-select: none;

  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 30px 0;
}

.fileWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(253, 253, 253, 0.2);
  margin: 0 8px 8px 0;
  align-items: center;
  padding: 8px 8px 8px 12px;
  max-width: 150px;
}

.fileWrap img {
  cursor: pointer;
}

.file {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14.4px;
  margin-right: 8px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .areaItem1 {
    padding: 60px 30px 60px 0;
  }

  .title {
    font-size: var(--title-laptop-font);
  }

  .info {
    flex-direction: column;
  }

  .areaItem2 {
    padding: 60px 0 60px 30px;
  }

  .areaItem3 {
    padding: 60px 0 60px 30px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .formContainer {
    grid-template-columns: 60% 40%;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .formContainer {
    grid-template-columns: 40% 60%;
  }

  .areaItem1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    border-right: none;
    border-bottom: 1px solid rgba(253, 253, 253, 0.2);
    padding: 40px 0;
  }

  .title {
    font-size: var(--title-tablet-font);
  }

  .areaItem2 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 3;

    border-right: 1px solid rgba(253, 253, 253, 0.2);
    padding: 44px 0;
  }

  .areaItem3 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    padding: 44px 0 44px 20px;
  }

  .image {
    width: 100px;
    height: 100px;
    margin-right: 15px;
  }

  .subtitle {
    font-size: 20px;
    margin: 10px 0 5px 0;
  }

  .text {
    font-size: var(--main-tablet-font);
    margin-bottom: 10px;
  }

  .link:first-child {
    margin-right: 10px;
    font-size: var(--main-tablet-font);
  }
}

@media (min-width: 501px) and (max-width: 650px) {
  .info {
    flex-direction: column;
  }

  .title:last-child {
    font-size: 30px;
  }

  .areaItem2 {
    padding-top: 20px;
    display: flex;
    align-items: center;
  }

  .areaItem3 {
    padding-top: 20px;
  }
}

@media (min-width: 1px) and (max-width: 500px) {
  .formContainer {
    grid-template-columns: 1fr;
  }

  .areaItem1 {
    grid-column-start: 1;
    grid-column-end: 3;

    border-right: none;
    border-bottom: 1px solid rgba(253, 253, 253, 0.2);
    padding: 24px 0;
  }

  .areaItem2 {
    grid-column-start: 1;
    grid-column-end: 3;

    padding: 20px 0;
    text-align: start;
  }

  .areaItem3 {
    grid-column-start: 1;
    grid-column-end: 3;

    padding: 20px 0;
  }

  .title {
    font-size: var(--title-mobile-font);
  }

  .paragraph {
    max-width: unset;
  }

  .image {
    width: 100px;
    height: 100px;
  }

  .subtitle {
    font-size: 20px;
    margin: 5px 0 10px 0;
  }

  .text {
    font-size: var(--main-tablet-font);
    margin-bottom: 10px;
  }

  .link:first-child {
    margin-right: 8px;
  }

  .buttons {
    flex-direction: column;
  }

  .buttons button,
  .buttons div {
    width: auto;
  }
}

@media (max-width: 400px) {
  .info {
    flex-direction: column;
    align-items: center;
  }

  .image {
    margin-bottom: 15px;
    margin-right: 0;
  }

  .areaItem3 {
    display: flex;
    justify-content: center;
  }
}
