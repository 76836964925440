.success {
    position: fixed;
    width: 100vw;
    height: 76px;
    background: #29c269;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 999;
}

.failed {
    position: fixed;
    width: 100vw;
    height: 100px;
    background: #ff655c;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 999;
}

.wrap {
    width: 100%;
    height: 100%;
    display: flex;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row span {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 138%;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #fdfdfd;
}
