.pagination {
    height: 46px;
}

.buttons {
    display: flex;
    width: 40%;
    flex-direction: row;
    height: 100%;
    padding: 0;
}

.pageItem {
    justify-content: center;
    border: 1px solid transparent;
}

.pageLink {
    cursor: pointer;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    letter-spacing: 0.02em;
    height: 100%;
    display: flex;
    align-items: center;

    color: #111517;

    border: none;
    background-color: #f4f4f6;
    padding: 18px 20px;
    transition: all 300ms;
}

.pageLink.active {
    background: #ff655c;
    color: #fdfdfd;
}

@media (hover: hover) {
    button:hover .number {
        transform: scale(1.1);
        transition-duration: 100ms;
    }
}

@media screen and (min-width: 1px) and (max-width: 400px) {
    .pagination {
        width: 98%;
        overflow-x: auto;
        justify-content: center;
    }
}
