.privacyPolicy {
    display: flex;
    flex-direction: column;
}

.container {
    width: 96%;
    max-width: 1400px;
    margin: 0 auto;
    /* border-left: 1px solid black;
    border-right: 1px solid black;    */
}

.footerContainer {
    padding: 5% 2% 2%;
    box-sizing: border-box;
    width: 100%;
}

.greySection {
    background-color: #f4f4f6;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 84px 0 68px 0;
}

.title {
    max-width: 11ch;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 85.83px;
    letter-spacing: -0.02em;
    line-height: 110%;

    color: #111517;
}

.points {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.point {
    width: 20px;
    height: 20px;
    background: #111517;
    border-radius: 50%;
    margin-right: 12px;
}

.point:nth-child(3) {
    background: #ff655c;
}

.textContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.rowWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 52px 0;
    border-bottom: 1px solid #dddfe4;
}

.textWrap {
    width: 70%;
}

.textTitle {
    width: 20%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 28.13px;

    color: #111517;
}

.text {
    width: 100%;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.textW600 {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.values {
    padding-top: 52px;
    display: flex;
    flex-direction: column;
}

.value {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    /* white-space: nowrap; */
    padding-bottom: 12px;
}

.values .value:nth-last-child(1) {
    padding-bottom: 0;
}

.valuePoint {
    width: 8px;
    height: 8px;
    background: #ff655c;
    border-radius: 50%;
    margin-right: 12px;
}

.interiorTitle {
    padding: 52px 0 20px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 156%;

    color: #111517;
}

.interiorText {
    width: 100%;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.redText {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #ff655c;
}

.rowWrap:nth-last-child(1) {
    border: none;
}

.rowWrap:nth-last-child(1) .textW600 {
    padding-top: 24px;
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .container {
        width: 94%;
        max-width: 100%;
    }

    .titleContainer {
        padding: 24px 0 24px 0;
    }

    .title {
        font-size: 52.2px;
        text-align: center;
        line-height: 105%;
    }

    .points {
        display: none;
    }

    .textContainer {
        padding: 0;
    }

    .rowWrap {
        flex-direction: column;
        padding: 32px 0;
    }

    .textTitle {
        width: 100%;
        margin-bottom: 20px;
        font-size: 25px;
    }

    .textWrap {
        width: 100%;
    }

    .text {
        font-size: 16px;
    }

    .textW600 {
        color: rgba(17, 21, 23, 1);
    }

    .values {
        padding-top: 32px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .container {
        width: 92%;
        max-width: 100%;
    }

    .titleContainer {
        padding: 24px 0 24px 0;
    }

    .title {
        font-size: 52.2px;
        line-height: 105%;
    }

    .textContainer {
        padding: 0;
    }

    .rowWrap {
        flex-direction: column;
        padding: 32px 0;
    }

    .textTitle {
        width: 100%;
        margin-bottom: 20px;
        font-size: 25px;
    }

    .textWrap {
        width: 100%;
    }

    .text {
        font-size: 16px;
    }

    .textW600 {
        color: rgba(17, 21, 23, 1);
    }

    .values {
        padding-top: 32px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .title {
        font-size: 52px;
    }
}
