@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap');

.fromConcept {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 110px;
    padding-bottom: 10%;
}

.textWrapper {
    display: flex;
}

.titleText {
    justify-content: flex-start;
    display: flex;
    width: 66%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 54.93px;
    line-height: 98%;

    color: #111517;
}

.paragraphs {
    justify-content: flex-end;
    width: 58%;
    display: flex;
    flex-direction: column;
    font-family: Manrope;
    font-style: normal;
    font-weight: 400;
    font-weight: normal;
    font-size: 18px;
    line-height: 156%;

    color: #494e5b;
}

.infographics {
    padding-top: 13%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.textWrap {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.block1 {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.block2 {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.block3 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.largeFont {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 300;
    font-size: 43.95px;

    color: #111517;
}

.smallFont {
    padding-left: 44px;
    padding-top: 10px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    letter-spacing: 0.02em;

    color: #494e5b;
}

.circle {
    border-radius: 50%;
    margin-right: 16px;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;
    border: none;
}

.textBlock {
    display: flex;
    flex-direction: column;
}

.vl {
    border-left: 1px solid #dddfe4;
    padding-right: 95px;
}

@media screen and (min-width: 1px) and (max-width: 400px) {
    .titleText {
        font-size: 40px;
    }

    .paragraphs {
        font-size: 16px;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .fromConcept {
        margin-top: 30px;
    }

    .textWrapper {
        flex-direction: column;
    }

    .title {
        word-spacing: 9999999px;
    }

    .titleText {
        width: 100%;
        text-align: center;
        line-height: 110%;
    }

    .paragraphs {
        width: 100%;
        padding-top: 8%;
        justify-content: center;
    }

    .paragraph:nth-child(2) {
        padding-top: 4%;
    }

    .infographics {
        flex-direction: column;
    }

    .vl {
        display: none;
    }

    .textWrap {
        margin-top: 2%;
        width: 100%;
        border-bottom: 1px solid #dddfe4;
    }

    .textWrap:last-child {
        border: none;
    }

    .block2 {
        justify-content: flex-end;
        padding-right: 6%;
    }

    .textBlock {
        margin-bottom: 3%;
    }

    .largeFont {
        white-space: nowrap;
        font-size: 39.06px;
        line-height: 118%;
        font-weight: 300;
    }

    .smallFont {
        width: 50%;
        font-size: 12.8px;
        color: #111517;
        padding-top: 0%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1201px) {
    .fromConcept {
        margin-top: 72px;
    }

    .textWrapper {
        flex-direction: column;
    }

    .title {
        display: flex;
        justify-content: flex-start;
        width: 90%;
    }

    .paragraphs {
        padding-top: 8%;
        padding-left: 29%;
        width: 64%;
    }

    .paragraph:nth-child(2) {
        padding-top: 4%;
    }

    .vl {
        display: none;
    }

    .infographics {
        justify-content: space-between;
    }

    .textWrap {
        width: 31%;
    }

    .block {
        justify-content: center;
    }

    .textBlock {
        padding: 0;
    }

    .largeFont {
        font-size: 39.06px;
    }

    .smallFont {
        color: #111517;
    }
}

@media screen and (min-width: 1202px) and (max-width: 1500px) {
    .titleText {
        padding-left: 10%;
    }

    .paragraphs {
        padding-right: 7%;
    }

    .paragraph:nth-child(2) {
        padding-top: 4%;
    }
}

@media screen and (min-width: 1501px) {
    .paragraph:nth-child(2) {
        padding-top: 4%;
    }
}
